<template>
  <v-data-table
    v-model="_selectedItems"
    must-sort
    hide-default-footer
    item-key="key"
    :headers="_headers"
    :items="_items"
    :loading="loading"
    :dense="dense"
    :showSelect="showSelect"
    :items-per-page="-1"
    :mobile-breakpoint="-1"
    :sort-by="sort"
    :sort-desc="dir === 'DESC'"
    class="cr-approval-document-table"
    @click:row="item => $emit('click:row', item)"
    @update:options="onUpdateOptions"
  >
    <!-- 문서번호 -->
    <template v-slot:item.docNum="{ item }">
      <DocNumCell v-bind="item" :disableContextMenu="disableContextMenu" />
    </template>
    <!-- 기안양식 -->
    <template v-slot:item.formName="{ item }">
      <FormCell
        v-bind="item"
        :disableContextMenu="disableContextMenu"
        @search:form="val => $emit('search:form', val)"
        @draft:form="routeDraftPage(item.formId)"
      />
    </template>
    <!-- 제목 -->
    <template v-slot:item.title="{ value, item }">
      <TitleCell :title="value" @click:title="$emit('click:title', item)" />
    </template>
    <!-- 기안자 -->
    <template v-slot:item.draftUser="{ value }">
      <ApproverCell
        v-if="value"
        :disableContextMenu="disableContextMenu"
        :user-id="value.id"
        :user-email="value.email"
        :user-name="value.name"
        @search:draftUser="val => $emit('search:draftUser', val)"
        @search:draftOrgan="val => $emit('search:draftOrgan', val)"
      />
    </template>
    <!-- 결재대기자 -->
    <template v-slot:item.nextApprover="{ value }">
      <ApproverCell v-bind="value" disableContextMenu />
    </template>
    <!-- 반송자 -->
    <template v-slot:item.returnApprover="{ value }">
      <ApproverCell v-bind="value" disableContextMenu />
    </template>
    <!-- 반려자 -->
    <template v-slot:item.rejectApprover="{ value }">
      <ApproverCell v-bind="value" disableContextMenu />
    </template>
    <!-- 담당자 -->
    <template v-slot:item.receiptUser="{ value }">
      <ApproverCell
        disableContextMenu
        :user-id="value.id"
        :user-email="value.email"
        :user-name="value.name"
      />
    </template>
    <!-- 결재구분 -->
    <template v-slot:item.approveType="{ value }">
      <span>
        {{ getApproveTypeText(value) }}
      </span>
    </template>
    <!-- 진행상태 -->
    <template v-slot:item.status="{ value }">
      <span>
        {{ getStatusText(value) }}
      </span>
    </template>
    <!-- 진행상태 (부서협조함) -->
    <template v-slot:item.receiptStatus="{ value }">
      <span :style="getReceiptStatusStyle(value)">
        {{ getReceiptStatusText(value) }}
      </span>
    </template>
    <!-- 진행상태 (부서수신함) -->
    <template v-slot:item.receiveStatus="{ value }">
      <span :style="getReceiveStatusStyle(value)">
        {{ getReceiveStatusText(value) }}
      </span>
    </template>
    <!-- 시간변환 -->
    <template
      v-for="column in timeColumns"
      v-slot:[`item.${column}`]="{ value }"
    >
      {{ getFullDate(value) }}
    </template>
  </v-data-table>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import ApproverCell from "@/approval/views/components/common/cell/ApproverCell";
import FormCell from "@/approval/views/components/common/cell/FormCell";
import DocNumCell from "@/approval/views/components/common/cell/DocNumCell";
import TitleCell from "@/approval/views/components/common/cell/TitleCell";
import { getFullDate } from "@/commons/utils/moment";

export default {
  components: { TitleCell, DocNumCell, FormCell, ApproverCell },
  emits: [
    "click:row",
    "click:title",
    "search:form",
    "search:draftUser",
    "update:selectedItems",
    "update:options"
  ],
  props: {
    headers: {
      type: Array,
      default: () => [],
      description: "헤더 목록"
    },
    items: {
      type: Array,
      default: () => [],
      description: "문서 목록"
    },
    selectedItems: {
      type: Array,
      default: () => [],
      description: "선택된 문서 목록"
    },
    sort: {
      required: true,
      description: "정렬할 컬럼"
    },
    dir: {
      required: true,
      description: "정렬 방향 (ASC/DESC)"
    },
    loading: {
      type: Boolean,
      default: () => false,
      description: "로딩중 여부"
    },
    dense: {
      type: Boolean,
      default: () => false
    },
    showSelect: {
      type: Boolean,
      default: () => false
    },
    disableContextMenu: {
      type: Boolean,
      default: () => false,
      description: "컨텍스트 옵션 비활성화 여부"
    }
  },
  data: () => ({
    timeColumns: [
      "draftedTimeMillis",
      "completedTimeMillis",
      "rejectedTimeMillis",
      "approvalChangedTimeMillis",
      "savedTimeMillis",
      "returnedTimeMillis",
      "retrievedTimeMillis",
      "approvalRequestTimeMillis",
      "receivedTimeMillis"
    ]
  }),
  computed: {
    // docId 중복을 허용하기 떄문에 uuid 고유키 발급
    // (showSelect 모드일 경우 id를 key로 지정)
    _items() {
      return this.items.map(item => ({
        ...item,
        key: this.showSelect ? item.id : uuidv4()
      }));
    },
    _headers() {
      return this.headers.map(h => {
        const header = { ...h };
        if (h.value === "title") {
          header["cellClass"] = "cr-cell-title";
        }
        if (h.value === "docNum") {
          header["cellClass"] = "cr-cell-width-200";
        }
        if (h.value === "formName") {
          header["cellClass"] = "cr-cell-width-150";
        }
        if (
          h.value === "draftUser" ||
          h.value === "nextApprover" ||
          h.value === "returnApprover" ||
          h.value === "rejectApprover" ||
          h.value === "receiptUser"
        ) {
          header["cellClass"] = "cr-cell-width-100";
        }
        return header;
      });
    },
    _selectedItems: {
      get() {
        return this.selectedItems.map(item => ({ ...item, key: item.id }));
      },
      set(val) {
        this.$emit("update:selectedItems", val);
      }
    }
  },
  methods: {
    onUpdateOptions({ sortBy, sortDesc }) {
      this.$emit("update:options", {
        sortBy: sortBy?.[0],
        sortDesc: sortDesc?.[0]
      });
    },
    getFullDate(millis) {
      const fullDate = getFullDate(millis);
      return fullDate === "Invalid date" ? "-" : fullDate;
    },
    getApproveTypeText(approveType) {
      switch (approveType) {
        case "APPROVAL":
          return "결재";
        case "HELP":
          return "협조";
        default:
          return "-";
      }
    },
    getStatusText(status) {
      return status === "COMPLETE" ? "결재완료" : "진행중";
    },
    getReceiptStatusText(receiptStatus) {
      switch (receiptStatus) {
        case "AUTO_APPROVED":
          return "승인(자동)";
        case "PRE_APPROVED":
        case "APPROVED":
          return "승인";
        case "PRE_REJECTED":
        case "REJECTED":
          return "반려";
        case "RECEIPT":
          return "접수";
        case "PENDING_APPROVAL":
          return "결재대기";
        default:
          return "대기";
      }
    },
    getReceiptStatusStyle(receiptStatus) {
      const buildColorCss = color => `color: ${color};`;
      switch (receiptStatus) {
        case "AUTO_APPROVED":
        case "PRE_APPROVED":
        case "APPROVED":
          return buildColorCss("rgb(51, 119, 255)");
        case "PRE_REJECTED":
        case "REJECTED":
          return buildColorCss("rgb(255, 128, 128)");
        case "PENDING_APPROVAL":
          return buildColorCss("rgb(117, 117, 117)");
        case "RECEIPT":
        default:
          return buildColorCss("black");
      }
    },
    getReceiveStatusText(receiveStatus) {
      switch (receiveStatus) {
        case "RECEIVED":
          return "수신";
        case "RETURNED":
          return "반송";
        case "PENDING_RECEIVE":
          return "수신대기";
        case "RECEIPT":
          return "접수";
        default:
          return "대기";
      }
    },
    getReceiveStatusStyle(receiveStatus) {
      const buildColorCss = color => `color: ${color};`;
      switch (receiveStatus) {
        case "RECEIVED":
          return buildColorCss("rgb(51, 119, 255)");
        case "RETURNED":
          return buildColorCss("rgb(255, 128, 128)");
        case "PENDING_RECEIVE":
          return buildColorCss("rgb(117, 117, 117)");
        case "RECEIPT":
        default:
          return buildColorCss("black");
      }
    },
    routeDraftPage(formId) {
      this.$router.push({
        name: "approval_draft",
        params: { formId }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.v-data-table.cr-approval-document-table::v-deep {
  > .v-data-table__wrapper {
    > table {
      // 테이블 위아래 border
      border-top: thin solid rgba(0, 0, 0, 0.12);
      border-bottom: thin solid rgba(0, 0, 0, 0.12);
      // 헤더 색상
      > thead > tr {
        color: #666;
        background-color: #fafafa;
        white-space: nowrap !important;
      }
      // 테이블 격자 border
      > thead > tr,
      > tbody > tr {
        border-bottom: thin solid rgba(0, 0, 0, 0.12);
        th:not(:last-child),
        td:not(:last-child) {
          border-right: thin solid rgba(0, 0, 0, 0.12);
        }
        // 셀 서식
        td {
          white-space: nowrap !important;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
          &.cr-cell-title {
            max-width: 0;
          }
          &.cr-cell-width-50 {
            width: 50px;
            min-width: 50px;
            max-width: 50px;
          }
          &.cr-cell-width-100 {
            width: 100px;
            min-width: 100px;
            max-width: 100px;
          }
          &.cr-cell-width-150 {
            width: 150px;
            min-width: 150px;
            max-width: 150px;
          }
          &.cr-cell-width-200 {
            width: 200px;
            min-width: 200px;
            max-width: 200px;
          }
          &.cr-cell-width-250 {
            width: 250px;
            min-width: 250px;
            max-width: 250px;
          }
          &.cr-cell-width-300 {
            width: 300px;
            min-width: 300px;
            max-width: 300px;
          }
        }
      }
    }
  }
  .cr-row {
    color: red;
    background: red;
  }
}
.cr-link {
  cursor: pointer;
  width: 100%;
  height: 100%;
  &:hover {
    text-decoration: underline;
  }
}
</style>
