var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column"},[_c('v-tabs',{staticClass:"mb-4",staticStyle:{"border-bottom":"thin solid rgba(0, 0, 0, 0.08)"},model:{value:(_vm.activeTabIndex),callback:function ($$v) {_vm.activeTabIndex=$$v},expression:"activeTabIndex"}},_vm._l((_vm.tabs),function(tab){return _c('v-tab',{key:tab},[_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.getTabName(tab)))])])}),1),_c('v-data-table',{staticClass:"cr-approval-opinion-table",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":-1,"mobile-breakpoint":-1,"disable-sort":"","hide-default-footer":""},on:{"click:row":function (item) { return _vm.$emit('click:row', item); }},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(_vm._s(_vm.getTypeName(value)))])]}},{key:"item.approver",fn:function(ref){
var value = ref.value;
return [_c('v-tooltip',{attrs:{"disabled":value.isOrgan,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(value.isUser ? value.userName : value.organName)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(value.userEmail))]),_c('span',[_vm._v(" | ")]),_c('span',[_vm._v(_vm._s(value.organName))])])]}},{key:"item.status",fn:function(ref){
var value = ref.value;
return [_c('span',{style:(_vm.getStatusStyle(value))},[_vm._v(" "+_vm._s(_vm.getStatusName(value))+" ")])]}},{key:"item.createdTimeMillis",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(_vm._s(_vm.convertDateFormat(value)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-icon',{attrs:{"small":"","color":"accent","disabled":index !== 0 || !_vm.hasEditPermission(item)},on:{"click":function($event){return _vm.openEditDialog(item)}}},[_vm._v(" mdi-pencil ")])]}}])}),(_vm.showEditDialog)?_c('OpinionEditDialog',_vm._g({attrs:{"opinion-id":_vm.editOpinionId,"opinion-comment":_vm.editOpinionComment},model:{value:(_vm.showEditDialog),callback:function ($$v) {_vm.showEditDialog=$$v},expression:"showEditDialog"}},_vm.$listeners)):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }