<template>
  <div class="black--text">
    <div v-if="approvalGroupLines.length > 0" class="cr-line-group">
      <div class="text-subtitle-2 font-weight-bold mb-1">[기안부서]</div>
      <div class="pl-5" style="white-space: pre-line">
        {{ draftOrgan.name }}
      </div>
    </div>
    <div v-if="approvalGroupLines.length > 0" class="cr-line-group">
      <div class="text-subtitle-2 font-weight-bold mb-1">[결재/협조]</div>
      <div class="pl-5" style="white-space: pre-line">
        {{ convertDisplayText(approvalGroupLines) }}
      </div>
    </div>
    <div v-if="ccGroupLines.length > 0" class="cr-line-group">
      <div class="text-subtitle-2 font-weight-bold mb-1">[참조]</div>
      <div class="pl-5" style="white-space: pre-line">
        {{ convertDisplayText(ccGroupLines) }}
      </div>
    </div>
    <div v-if="receiveGroupLines.length > 0" class="cr-line-group">
      <div class="text-subtitle-2 font-weight-bold mb-1">[수신]</div>
      <div class="pl-5" style="white-space: pre-line">
        {{ convertDisplayText(receiveGroupLines) }}
      </div>
    </div>
    <div v-if="shareGroupLines.length > 0" class="cr-line-group">
      <div class="text-subtitle-2 font-weight-bold mb-1">[공람]</div>
      <div class="pl-5" style="white-space: pre-line">
        {{ convertDisplayText(shareGroupLines) }}
      </div>
    </div>
  </div>
</template>

<script>
import { ApprovalLines } from "@/approval/utils/ApprovalLines";
import { LINE_GROUP } from "@/approval/constant/approvalLine";

export default {
  props: {
    draftOrgan: {
      type: Object,
      required: true,
      description: "기안 부서"
    },
    approvalLines: {
      type: Array,
      default: () => [],
      required: true,
      description: "표시할 결재선"
    }
  },
  computed: {
    approvalGroupLines() {
      return new ApprovalLines(this.approvalLines)
        .filterByGroup(LINE_GROUP.APPROVAL)
        .sortAsc()
        .get();
    },
    ccGroupLines() {
      return new ApprovalLines(this.approvalLines)
        .filterByGroup(LINE_GROUP.CC)
        .sortAsc()
        .get();
    },
    receiveGroupLines() {
      return new ApprovalLines(this.approvalLines)
        .filterByGroup(LINE_GROUP.RECEIVE)
        .sortAsc()
        .get();
    },
    shareGroupLines() {
      return new ApprovalLines(this.approvalLines)
        .filterByGroup(LINE_GROUP.SHARE)
        .sortAsc()
        .get();
    }
  },
  methods: {
    convertDisplayText(approvalLines = []) {
      let result = "";
      for (const line of approvalLines) {
        result += this.extractApproverText(line) + "\n";
      }
      return result;
    },
    extractApproverText(line = {}) {
      return line.approver.userId
        ? `${line.approver.userName} | ${line.approver.organName}`
        : line.approver.organName;
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-line-group {
  margin-bottom: 12px;
  &:last-child {
    margin-bottom: 0;
  }
}
</style>
