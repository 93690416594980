var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"cr-approval-document-table",attrs:{"must-sort":"","hide-default-footer":"","item-key":"key","headers":_vm._headers,"items":_vm._items,"loading":_vm.loading,"dense":_vm.dense,"showSelect":_vm.showSelect,"items-per-page":-1,"mobile-breakpoint":-1,"sort-by":_vm.sort,"sort-desc":_vm.dir === 'DESC'},on:{"click:row":function (item) { return _vm.$emit('click:row', item); },"update:options":_vm.onUpdateOptions},scopedSlots:_vm._u([{key:"item.docNum",fn:function(ref){
var item = ref.item;
return [_c('DocNumCell',_vm._b({attrs:{"disableContextMenu":_vm.disableContextMenu}},'DocNumCell',item,false))]}},{key:"item.formName",fn:function(ref){
var item = ref.item;
return [_c('FormCell',_vm._b({attrs:{"disableContextMenu":_vm.disableContextMenu},on:{"search:form":function (val) { return _vm.$emit('search:form', val); },"draft:form":function($event){return _vm.routeDraftPage(item.formId)}}},'FormCell',item,false))]}},{key:"item.title",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('TitleCell',{attrs:{"title":value},on:{"click:title":function($event){return _vm.$emit('click:title', item)}}})]}},{key:"item.draftUser",fn:function(ref){
var value = ref.value;
return [(value)?_c('ApproverCell',{attrs:{"disableContextMenu":_vm.disableContextMenu,"user-id":value.id,"user-email":value.email,"user-name":value.name},on:{"search:draftUser":function (val) { return _vm.$emit('search:draftUser', val); },"search:draftOrgan":function (val) { return _vm.$emit('search:draftOrgan', val); }}}):_vm._e()]}},{key:"item.nextApprover",fn:function(ref){
var value = ref.value;
return [_c('ApproverCell',_vm._b({attrs:{"disableContextMenu":""}},'ApproverCell',value,false))]}},{key:"item.returnApprover",fn:function(ref){
var value = ref.value;
return [_c('ApproverCell',_vm._b({attrs:{"disableContextMenu":""}},'ApproverCell',value,false))]}},{key:"item.rejectApprover",fn:function(ref){
var value = ref.value;
return [_c('ApproverCell',_vm._b({attrs:{"disableContextMenu":""}},'ApproverCell',value,false))]}},{key:"item.receiptUser",fn:function(ref){
var value = ref.value;
return [_c('ApproverCell',{attrs:{"disableContextMenu":"","user-id":value.id,"user-email":value.email,"user-name":value.name}})]}},{key:"item.approveType",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getApproveTypeText(value))+" ")])]}},{key:"item.status",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getStatusText(value))+" ")])]}},{key:"item.receiptStatus",fn:function(ref){
var value = ref.value;
return [_c('span',{style:(_vm.getReceiptStatusStyle(value))},[_vm._v(" "+_vm._s(_vm.getReceiptStatusText(value))+" ")])]}},{key:"item.receiveStatus",fn:function(ref){
var value = ref.value;
return [_c('span',{style:(_vm.getReceiveStatusStyle(value))},[_vm._v(" "+_vm._s(_vm.getReceiveStatusText(value))+" ")])]}},_vm._l((_vm.timeColumns),function(column){return {key:("item." + column),fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.getFullDate(value))+" ")]}}})],null,true),model:{value:(_vm._selectedItems),callback:function ($$v) {_vm._selectedItems=$$v},expression:"_selectedItems"}})}
var staticRenderFns = []

export { render, staticRenderFns }