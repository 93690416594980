<template>
  <div class="text-center">
    <v-tooltip top color="black" :disabled="isEmpty">
      <template v-slot:activator="{ attr, on }">
        <div
          v-bind="attr"
          v-on="on"
          class="text-truncate"
          :class="isEmpty || disableContextMenu ? '' : 'cr-link'"
          @click="isEmpty || disableContextMenu ? null : copyToClipboard()"
        >
          {{ docNum }}
        </div>
      </template>
      <!-- tooltip 메시지 -->
      <span>{{ docNum }}</span>
    </v-tooltip>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  props: {
    disableContextMenu: {
      type: Boolean,
      default: false,
      description: "컨텍스트 메뉴 비활성화"
    },
    docNum: { description: "문서번호" }
  },
  computed: {
    isEmpty() {
      return (
        !this.docNum || this.docNum.trim() === "" || this.docNum.trim() === "-"
      );
    }
  },
  methods: {
    ...mapActions("snackbar", ["openSnackbar"]),
    async copyToClipboard() {
      await navigator.clipboard.writeText(this.docNum);
      await this.openSnackbar({
        type: "SUCCESS",
        message: "문서번호가 복사 되었습니다."
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-link {
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
