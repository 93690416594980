<template>
  <div class="d-flex text-subtitle-2 d-flex align-center">
    <!-- 업로드 여부 -->
    <v-tooltip v-if="editable" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          v-on="on"
          v-bind="attrs"
          size="19"
          class="mr-1"
          :color="isUploadCompleted ? 'green' : 'grey'"
        >
          {{
            isUploadCompleted ? "mdi-check-circle" : "mdi-check-circle-outline"
          }}
        </v-icon>
      </template>
      <span>{{ isUploadCompleted ? "전송완료" : "전송전" }}</span>
    </v-tooltip>
    <!-- 파일 아이콘 -->
    <v-icon class="file_icon mr-1" :size="19" :color="fileIconColors">
      {{ fileIcon }}
    </v-icon>
    <!-- 파일명 -->
    <div
      class="cr-file-name"
      :class="!editable || isUploadCompleted ? 'cr-downloadable' : ''"
      @click="!editable || isUploadCompleted ? $emit('click') : null"
    >
      <span class="text-truncate mr-1">{{ name }}</span>
      <span class="font-weight-regular grey--text text-no-wrap">
        ({{ byteCalculation(size) }})
      </span>
    </div>
    <!-- 관리버튼 -->
    <v-btn
      v-if="editable"
      icon
      x-small
      class="ml-2"
      @click.stop="$emit('click:remove')"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { fileIcons, fileIconColors } from "@/commons/utils/icons";
import { byteCalculation } from "@/commons/utils/byteCalculation";

export default {
  name: "SimpleFileItem",
  emits: ["click", "click:remove"],
  props: {
    name: {
      type: String,
      required: true,
      description: "파일명"
    },
    size: {
      type: Number,
      required: true,
      description: "파일 크기"
    },
    status: {
      type: String,
      description: "업로드 상태"
    },
    editable: {
      type: Boolean,
      default: false,
      description: "편집모드 여부"
    }
  },
  methods: {
    byteCalculation(size) {
      return byteCalculation(size);
    }
  },
  computed: {
    // 파일 확장자
    fileExt() {
      const index = this.name.lastIndexOf(".");
      return this.name.substring(index + 1, this.name.length).toLowerCase();
    },
    // 파일 아이콘
    fileIcon() {
      return fileIcons[this.fileExt] || fileIcons["file"];
    },
    // 파일 아이콘 컬러
    fileIconColors() {
      return fileIconColors[this.fileExt] || fileIconColors["file"];
    },
    isUploadCompleted() {
      return this.status === "COMPLETE";
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-file-name {
  &.cr-downloadable:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
