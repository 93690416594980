<template>
  <v-container fluid class="py-5 px-5">
    <v-row>
      <v-col cols="12" lg="4">
        <v-row>
          <v-col cols="12">
            <CountSection class="cr-section" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <ManagedOrganSection class="cr-section" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <RecentlyFormSection class="cr-section" />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" lg="8">
        <v-row>
          <v-col cols="12">
            <ToBeApproveSection class="cr-section" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <RecentlyDraftSection class="cr-section" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import CountSection from "@/approval/views/pages/DashboardPage/section/CountSection";
import ToBeApproveSection from "@/approval/views/pages/DashboardPage/section/ToBeApproveSection";
import RecentlyFormSection from "@/approval/views/pages/DashboardPage/section/RecentlyFormSection";
import RecentlyDraftSection from "@/approval/views/pages/DashboardPage/section/RecentlyDraftSection";
import ManagedOrganSection from "@/approval/views/pages/DashboardPage/section/ManagedOrganSection";
export default {
  name: "ApprovalHomePage",
  components: {
    ManagedOrganSection,
    RecentlyDraftSection,
    RecentlyFormSection,
    ToBeApproveSection,
    CountSection
  },
  computed: {},
  methods: {
    ...mapActions("approvalDashboard", ["init"]),
    ...mapMutations("approvalDashboard", ["RESET"])
  },
  async mounted() {
    await this.init();
  },
  destroyed() {
    this.RESET();
  }
};
</script>

<style lang="scss" scoped>
.cr-section::v-deep {
  padding: 16px;
  background: white;
  border: thin solid rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  overflow: hidden;

  .cr-section-title {
    font-size: 1.25rem !important;
    line-height: 2rem;
    letter-spacing: 0.0125em !important;
    font-family: "Roboto", sans-serif !important;
    font-weight: 700 !important;
  }
}
</style>
