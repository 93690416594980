<template>
  <v-dialog
    :value="value"
    :scrollable="scrollable"
    :max-width="maxWidth"
    v-on="$listeners"
    persistent
    retain-focus
    no-click-animation
  >
    <v-card>
      <!-- 타이틀 -->
      <v-card-title>
        <span class="text-h6 font-weight-bold">수신확인</span>
      </v-card-title>
      <!-- 본문 -->
      <v-card-text>
        <p>수신 확인할 결재선을 선택해 주세요</p>
        <!-- 수신확인할 결재선 선택 -->
        <v-select
          v-model="selectedLineId"
          :items="availableLines"
          :item-text="convertItemText"
          item-value="id"
          outlined
          hide-details
        />
      </v-card-text>
      <!-- 확인/취소 버튼-->
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text outlined @click="$emit('input', false)">
          취소
        </v-btn>
        <v-btn
          color="accent"
          text
          outlined
          @click="$emit('click:confirm', selectedLineId)"
        >
          확인
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { LINE_GROUP, LINE_TYPE } from "@/approval/constant/approvalLine";
import { mapGetters, mapState } from "vuex";
import { ApprovalLines } from "@/approval/utils/ApprovalLines";

export default {
  name: "ReceiveDialog",
  emits: ["click:confirm"],
  props: {
    value: {
      type: Boolean,
      default: false,
      description: "다이얼로그 표시 여부 (v-model)"
    },
    document: {
      required: true,
      description: "대상 문서"
    },
    approvalLines: {
      required: true,
      description: "문서의 전체 결재선 목록"
    },
    persistent: {
      type: Boolean,
      default: false,
      description: "by vuetify dialog prop"
    },
    scrollable: {
      type: Boolean,
      default: false,
      description: "by vuetify dialog prop"
    },
    maxWidth: {
      type: Number,
      default: 600,
      description: "by vuetify dialog prop"
    }
  },
  data: () => ({
    selectedLineId: null
  }),
  computed: {
    ...mapState("auth", ["userInfo"]),
    ...mapGetters("approvalConfig", ["getManagedOrganIds"]),
    availableLines() {
      return new ApprovalLines(this.approvalLines)
        .filterByGroup(LINE_GROUP.RECEIVE)
        .filterByStatus("PENDING_RECEIVE")
        .filter(this.checkLineOwner)
        .get();
    }
  },
  methods: {
    convertItemText(item) {
      const { type, approver } = item;
      const typeName =
        type === LINE_TYPE.RECEIVE
          ? "수신"
          : type === LINE_TYPE.RECEIVE_ORGAN
          ? "부서수신"
          : "";
      const approverName =
        type === LINE_TYPE.RECEIVE
          ? approver?.userName
          : type === LINE_TYPE.RECEIVE_ORGAN
          ? approver?.organName
          : "";
      return `[${typeName}] ${approverName}`;
    },
    // 로그인 유저가 {line}의 결재권자 인지 체크
    checkLineOwner(line = {}) {
      const { approver } = line;
      if (approver?.isUser) {
        return this.userInfo.id === approver?.userId;
      }
      if (approver?.isOrgan) {
        return this.getManagedOrganIds.includes(approver?.organId);
      }
      return false;
    },
    // 선택창 기본값 바인딩
    selectDefault() {
      const [{ id }] = this.availableLines;
      this.selectedLineId = id;
    }
  },
  watch: {
    value(show) {
      // 다이얼로그 열때 셀렉트 기본값 바인딩
      if (show) this.selectDefault();
    }
  },
  mounted() {}
};
</script>

<style scoped></style>
