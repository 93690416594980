<template>
  <div>
    <!--양식 아이템 목록-->
    <v-row v-if="!isEmpty" no-gutters class="pa-4">
      <v-col
        v-for="form in formList"
        :key="form.id"
        cols="4"
        md="3"
        xl="2"
        no-gutters
      >
        <div class="pa-2">
          <FormListItem :form="form" outlined v-on="$listeners" />
        </div>
      </v-col>
    </v-row>
    <!-- 로딩 또는 no-item -->
    <div
      v-if="loading || isEmpty || !last"
      class="d-flex justify-center align-center"
      style="height: 50px"
    >
      <v-progress-circular
        v-if="loading"
        :width="3"
        color="primary"
        indeterminate
      />
      <span v-else-if="isEmpty" class="black--text">표시할 정보가 없음.</span>
      <v-btn v-else-if="!last" plain @click="loadNextPage">더보기</v-btn>
    </div>
  </div>
</template>

<script>
import FormListItem from "@/approval/views/components/form/FormItem";
import { getFormList } from "@/approval/api/approval.api";

export default {
  name: "FormList",
  components: { FormListItem },
  props: {
    formBox: {
      type: Object,
      required: true,
      default: () => {},
      description: "양식함"
    }
  },
  data() {
    return {
      loading: false,
      formList: [],
      page: 0,
      totalElements: 0,
      totalPages: 0,
      last: true
    };
  },
  methods: {
    // 양식 목록 불러오기
    async loadFormBoxList() {
      this.loading = true;
      const { status, data } = await getFormList(true, {
        boxId: this.boxId,
        page: this.page
      });
      if (status !== 200) {
        // 실패시
      }
      // 응답 결과 바인딩
      const {
        content: list,
        number: page,
        totalElements,
        totalPages,
        last
      } = data;
      this.formList = [...this.formList, ...list];
      this.page = page;
      this.totalElements = totalElements;
      this.totalPages = totalPages;
      this.last = last;
      this.loading = false;
    },
    // 다음 페이지 양식 목록 불러오기
    loadNextPage() {
      if (this.last) return;
      this.page++;
      this.loadFormBoxList();
    }
  },
  computed: {
    boxId() {
      return this.formBox?.id;
    },
    isEmpty() {
      return !this.formList || this.formList.length < 1;
    }
  },
  async mounted() {
    await this.loadFormBoxList();
  },
  destroyed() {
    this.loading = false;
    this.formList = [];
    this.page = 0;
    this.totalElements = 0;
    this.totalPages = 0;
    this.last = true;
  }
};
</script>

<style lang="scss" scoped></style>
