<template>
  <div class="d-flex align-center">
    <v-menu
      ref="startDateMenu"
      v-model="showFromDateMenu"
      :close-on-content-click="false"
      :return-value.sync="localizedFromDate"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <!-- Input -->
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="localizedFromDate"
          :prepend-inner-icon="showIcon ? 'mdi-calendar' : null"
          placeholder="시작일"
          clearable
          hide-details
          readonly
          dense
          outlined
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <!-- Date Picker-->
      <v-date-picker
        v-model="localizedFromDate"
        no-title
        scrollable
        :day-format="formatDay"
        @input="picked => $refs.startDateMenu.save(picked)"
      />
    </v-menu>
    <span class="px-1">-</span>
    <v-menu
      ref="endDateMenu"
      v-model="showToDateMenu"
      :close-on-content-click="false"
      :return-value.sync="localizedToDate"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <!-- Input -->
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="localizedToDate"
          :prepend-inner-icon="showIcon ? 'mdi-calendar' : null"
          placeholder="종료일"
          clearable
          hide-details
          readonly
          dense
          outlined
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <!-- Date Picker-->
      <v-date-picker
        v-model="localizedToDate"
        no-title
        scrollable
        :day-format="formatDay"
        @input="picked => $refs.endDateMenu.save(picked)"
      />
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "DateRangeSelector",
  emits: ["update:fromDate", "update:toDate"],
  props: {
    fromDate: {
      type: String,
      description: "시작일"
    },
    toDate: {
      type: String,
      description: "종료일"
    },
    showIcon: {
      type: Boolean,
      default: false,
      description: "prependIcon 표시 여부"
    }
  },
  data: () => ({
    localizedFromDate: undefined,
    localizedToDate: undefined,
    showFromDateMenu: false,
    showToDateMenu: false
  }),
  watch: {
    localizedFromDate(n) {
      this.$emit("update:fromDate", n);
    },
    localizedToDate(n) {
      this.$emit("update:toDate", n);
    },
    fromDate(n) {
      this.localizedFromDate = n;
    },
    toDate(n) {
      this.localizedToDate = n;
    }
  },
  methods: {
    // 달력 '일' 표시 포맷팅
    formatDay(val) {
      return Number(val.substr(val.lastIndexOf("-") + 1));
    }
  }
};
</script>

<style scoped></style>
