<template>
  <div class="d-flex flex-column justify-start flex-fill mb-4">
    <div class="d-flex align-center mb-2">
      <div class="d-flex align-center mr-auto">
        <span
          v-text="title"
          class="font-weight-bold"
          :class="small ? 'text-h6' : 'text-h5'"
        />
        <slot name="append">
          <!-- 제목 바로 옆 공간 사용할 경우 해당 슬롯 확장 -->
          <!-- EX) <template v-slot:append></template> -->
        </slot>
      </div>
      <slot name="tail">
        <!-- 우측 끝 공간 확장할 경우 해당 슬롯 확장 -->
        <!-- EX) <template v-slot:tail></template> -->
      </slot>
    </div>
    <v-divider />
  </div>
</template>

<script>
export default {
  name: "SectionTitle",
  props: {
    title: {
      type: String,
      default: ""
    },
    small: {
      type: Boolean,
      default: () => false
    }
  }
};
</script>

<style scoped></style>
