<template>
  <v-dialog
    :value="value"
    :scrollable="scrollable"
    :max-width="maxWidth"
    v-on="$listeners"
    persistent
    retain-focus
    no-click-animation
  >
    <v-card v-if="value" style="height: 100%">
      <!-- 타이틀 -->
      <v-card-title>
        <span class="text-h6 font-weight-bold">문서 선택</span>
        <v-spacer></v-spacer>
        <v-icon @click="$emit('input', false)">mdi-close</v-icon>
      </v-card-title>
      <!-- 본문 -->
      <v-card-text style="height: calc(100% - 114px); overflow-y: auto">
        <DocumentSearchDetail
          :headers="headers"
          :draft-from-date.sync="searchDetail.draftFromDate"
          :draft-to-date.sync="searchDetail.draftToDate"
          :form-id.sync="searchDetail.formId"
          :keyword-flag.sync="searchDetail.keywordFlag"
          :keyword.sync="searchDetail.keyword"
          class="mb-4"
          @submit="fetchDocumentList()"
        >
          <template v-slot:action>
            <div class="d-flex align-center">
              <v-btn
                tile
                depressed
                color="primary"
                class="ml-auto"
                @click="fetchDocumentList()"
              >
                검색
              </v-btn>
            </div>
          </template>
        </DocumentSearchDetail>
        <DocumentList
          dense
          show-select
          disable-context-menu
          style="min-height: 360px"
          :headers="headers"
          :loading="loading"
          :sort="sort"
          :dir="dir"
          :items="documents"
          :selected-items.sync="selectedDocs"
          @click:row="toggle"
          @click:title="openDocument"
          @update:options="onUpdateOptions"
        />
        <Pagination
          v-if="totalElements > 0"
          type="list"
          :total-elements="totalElements"
          :page="page + 1"
          :page-size="size"
          :block-size="5"
          class="mt-2"
          @pageClick="pageClick"
        />
        <div class="d-flex align-start mt-5">
          <div class="mt-2 mr-2 text-no-wrap">
            <span>선택된 문서</span>
            <span class="ml-1 font-weight-bold">
              {{ selectedDocs.length }}개
            </span>
          </div>
          <div style="max-height: 80px; overflow-y: auto">
            <v-chip
              v-for="doc in selectedDocs"
              :key="doc.id"
              close
              small
              color="primary"
              class="ma-2"
              @click="openDocument(doc)"
              @click:close="deselect(doc)"
            >
              {{ doc.title }}
            </v-chip>
          </div>
        </div>
      </v-card-text>
      <!-- 확인/취소 버튼-->
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text outlined @click="$emit('input', false)">
          취소
        </v-btn>
        <v-btn
          :disabled="selectedDocs < 1"
          color="accent"
          text
          outlined
          @click="$emit('click:confirm', selectedDocs)"
        >
          확인
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import DocumentList from "@/approval/views/components/document/DocumentList";
import B0X_HEADERS from "@/approval/constant/boxHeaders";
import API from "@/approval/api/approval.api";
import { getBoxByKey } from "@/approval/constant/box";
import Pagination from "@/commons/views/Pagination";
import DocumentSearchDetail from "@/approval/views/components/document/DocumentSearchDetail";

export default {
  components: { DocumentSearchDetail, Pagination, DocumentList },
  emits: ["click:confirm"],
  props: {
    value: {
      type: Boolean,
      default: false,
      description: "다이얼로그 표시 여부 (v-model)"
    },
    boxKey: {
      default: () => "ledger",
      description: "불러올 양식함 key"
    },
    initData: {
      type: Array,
      default: () => [],
      description: "초기값 (이미 선택된 문서 목록)"
    },
    persistent: {
      type: Boolean,
      default: false,
      description: "by vuetify dialog prop"
    },
    scrollable: {
      type: Boolean,
      default: false,
      description: "by vuetify dialog prop"
    },
    maxWidth: {
      type: Number,
      default: 1200,
      description: "by vuetify dialog prop"
    }
  },
  data: () => ({
    selectedDocs: [],
    searchDetail: {},
    documents: [],
    page: 0,
    size: 10,
    sort: "completedTimeMillis",
    dir: "DESC",
    totalElements: 0,
    loading: false
  }),
  computed: {
    ...mapState("auth", ["userInfo"]),
    ...mapGetters("approvalConfig", ["getManagedOrganIds"]),
    headers() {
      return B0X_HEADERS.ledger;
    },
    path() {
      return getBoxByKey(this.boxKey)?.path;
    }
  },
  methods: {
    ...mapActions("snackbar", ["openSnackbar"]),
    init() {
      this.selectedDocs = [...this.initData];
      this.searchDetail = {};
      this.documents = [];
      this.page = 0;
      this.size = 10;
      this.sort = "completedTimeMillis";
      this.dir = "DESC";
      this.totalElements = 0;
      this.loading = false;
      this.fetchDocumentList(this.page);
    },
    async fetchDocumentList(page = 0) {
      this.loading = true;
      const { status, data } = await API.getDocumentList(
        this.path,
        { page, size: this.size, sort: this.sort, dir: this.dir },
        { ...this.getSearchDetailPayload(this.searchDetail) }
      );
      if (status !== 200) {
        await this.openSnackbar({
          message: "기안 목록을 불러오는데 실패했습니다.",
          type: "ERROR"
        });
        this.loading = false;
        return;
      }
      this.documents = data?.content;
      this.page = data?.number;
      this.size = data?.size;
      this.totalElements = data?.totalElements;
      this.loading = false;
    },
    onUpdateOptions({ sortBy, sortDesc }) {
      this.sort = sortBy;
      this.dir = sortDesc ? "DESC" : "ASC";
      this.fetchDocumentList(0);
    },
    toggle(doc) {
      if (this.selectedDocs.some(({ id }) => doc.id === id)) {
        this.deselect(doc);
      } else {
        this.select(doc);
      }
    },
    select(doc) {
      if (this.selectedDocs.some(({ id }) => doc.id === id)) return;
      this.selectedDocs.push(doc);
    },
    deselect(doc) {
      this.selectedDocs = this.selectedDocs.filter(({ id }) => id !== doc?.id);
    },
    openDocument(doc) {
      window.open(`#/approval/doc/list/${this.boxKey}/${doc?.id}`);
    },
    pageClick(page) {
      this.fetchDocumentList(page - 1);
    },
    getSearchDetailPayload(searchDetail = {}) {
      return {
        draftFromDate: searchDetail.draftFromDate,
        draftToDate: searchDetail.draftToDate,
        formId: searchDetail.formId,
        keywordFlag: searchDetail.keywordFlag,
        keyword:
          searchDetail.keywordFlag === "TITLE"
            ? searchDetail.keyword?.title
            : searchDetail.keywordFlag === "DOC_NUM"
            ? searchDetail.keyword?.docNum
            : searchDetail.keywordFlag === "DRAFT_USER_ID"
            ? searchDetail.keyword?.userId
            : searchDetail.keywordFlag === "DRAFT_ORGAN_ID"
            ? searchDetail.keyword?.organId
            : undefined
      };
    }
  },
  watch: {
    value(show) {
      // 다이얼로그 열때 셀렉트 초기값 바인딩
      if (show) this.init();
    }
  },
  mounted() {}
};
</script>

<style scoped></style>
