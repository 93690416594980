<template>
  <v-dialog
    :value="value"
    :scrollable="scrollable"
    :max-width="maxWidth"
    v-on="$listeners"
    persistent
    retain-focus
    no-click-animation
  >
    <v-form v-model="valid" ref="form">
      <v-card>
        <!-- 타이틀 -->
        <v-card-title>
          <span class="text-h6 font-weight-bold">결재하기</span>
        </v-card-title>
        <!-- 본문 -->
        <v-card-text class="pb-0">
          <!-- 결재 유형 선택 -->
          <v-radio-group v-model="approveType" row mandatory class="mt-0">
            <v-radio label="승인" value="approve" />
            <v-radio label="반려" value="reject" />
            <v-radio
              v-if="preApprovalAllowed"
              label="전결"
              value="preApprove"
            />
            <v-radio
              v-if="prevRejectAllowed"
              label="전단계 반려"
              value="preReject"
            />
          </v-radio-group>
          <!-- 결재의견 입력 -->
          <v-textarea
            v-model="comment"
            outlined
            autofocus
            ref="textArea"
            rows="5"
            counter="400"
            placeholder="결재 의견을 입력해 주세요"
            :rules="rules"
          />
        </v-card-text>
        <!-- 확인/취소 버튼-->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text outlined @click="$emit('input', false)">
            취소
          </v-btn>
          <v-btn color="accent" text outlined @click="confirm">
            확인
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { LINE_GROUP } from "@/approval/constant/approvalLine";
import { ApprovalLines } from "@/approval/utils/ApprovalLines";

export default {
  name: "ApproveDialog",
  emits: ["click:confirm"],
  props: {
    value: {
      type: Boolean,
      default: false,
      description: "다이얼로그 표시 여부 (v-model)"
    },
    approvalLines: {
      required: true,
      description: "문서의 전체 결재선 목록"
    },
    form: {
      required: true,
      description: "문서의 기안 양식"
    },
    persistent: {
      type: Boolean,
      default: false,
      description: "by vuetify dialog prop"
    },
    scrollable: {
      type: Boolean,
      default: false,
      description: "by vuetify dialog prop"
    },
    maxWidth: {
      type: Number,
      default: 600,
      description: "by vuetify dialog prop"
    }
  },
  data: () => ({
    valid: false,
    approveType: "",
    comment: "",
    rules: [
      v => !!v || "필수 입력 사항입니다.",
      v => v?.length <= 400 || "최대 400 글자까지 입력 가능합니다."
    ]
  }),
  computed: {
    ...mapGetters("serviceConfig", ["getApprovalServiceConfig"]),
    // [전단계반려] 옵션 표시 여부
    prevRejectAllowed() {
      // 조건 : 공통설정에서 [전단계 반려 옵션] 활성화 상태
      // 조건 : 결재그룹 중 [전단계 결재선이 존재] 해야함
      return (
        this.getApprovalServiceConfig.prevRejectUse && !!this.prevApproveLine
      );
    },
    // [전결] 옵션 표시 여부
    preApprovalAllowed() {
      // 조건 : 기안양식에서 [전결] 허용된 상태
      // 조건 : 결재그룹의 [마지막 순번]이 아니여야함
      if (!this.form.preApprovalAllowed) return false;
      const lastLineInApprovalGroup = new ApprovalLines(this.approvalLines)
        .filterByGroup(LINE_GROUP.APPROVAL)
        .sortAsc()
        .getLast();
      return lastLineInApprovalGroup?.id !== this.nextApproveLine?.id;
    },
    // 결재 대기중인 결재선
    nextApproveLine() {
      return new ApprovalLines(this.approvalLines)
        .filterByGroup(LINE_GROUP.APPROVAL)
        .filterByStatus("PENDING_APPROVAL")
        .getLast();
    },
    // 전단계 결재선(승인자)
    prevApproveLine() {
      return new ApprovalLines(this.approvalLines)
        .filterByGroup(LINE_GROUP.APPROVAL)
        .filter(({ sortOrder }) => sortOrder < this.nextApproveLine?.sortOrder)
        .sortAsc()
        .getLast();
    }
  },
  methods: {
    confirm() {
      // 폼검증
      this.$refs.form.validate();
      if (!this.valid) {
        this.$refs.textArea.focus();
        return;
      }
      this.$emit("click:confirm", {
        lineId: this.nextApproveLine?.id,
        approveType: this.approveType,
        comment: this.comment
      });
    },
    reset() {
      this.valid = false;
      this.approveType = "";
      this.comment = "";
      this.$refs.form.reset();
    }
  },
  watch: {
    value(show) {
      if (!show) this.reset();
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .v-input {
  &.v-text-field--outlined {
    fieldset {
      border-width: 1px;
    }
    &.error--text fieldset {
      border-color: var(--v-error-base);
    }
  }
}
</style>
