<template>
  <draggable
    v-if="list.length"
    :value="list"
    v-bind="dragOptions"
    class="cr-list-main"
    tag="ul"
    handle=".cr-list-handle"
    :move="checkMove"
    @start="onStart"
    @end="onEnd"
  >
    <transition-group type="transition" :name="!drag ? 'flip-list' : null">
      <li v-for="line in list" :key="line.sortOrder">
        <DraggableListItem
          :approvalLine="line"
          :editable="lineEditable(line)"
          @click:remove="removeLine(line)"
        />
      </li>
    </transition-group>
  </draggable>
  <div v-else class="cr-no-item">
    선택된 결재선이 없습니다.
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { LINE_GROUP } from "@/approval/constant/approvalLine";
import { mapActions, mapGetters, mapState } from "vuex";
import { ApprovalLines } from "@/approval/utils/ApprovalLines";
import DraggableListItem from "@/approval/views/components/selector/ApprovalLineSelector/SelectedSection/listItem/DraggableListItem";

export default {
  components: { DraggableListItem, draggable },
  props: {
    group: {
      description: "표시할 결재선 그룹",
      default: LINE_GROUP.APPROVAL
    }
  },
  data: () => ({
    drag: false
  }),
  computed: {
    ...mapGetters("approvalLineSelector", ["getNextApproveLine"]),
    ...mapState("approvalLineSelector", ["approvalLines", "selectType"]),
    disabled() {
      switch (this.selectType) {
        case "CHANGE_SHARE":
        case "CHANGE_RECEIVE":
          return true;
        default:
          return false;
      }
    },
    list() {
      return new ApprovalLines(this.approvalLines)
        .filterByGroup(this.group)
        .sortAsc()
        .get()
        .map(line => ({
          ...line,
          fixed: !this.lineEditable(line)
        }));
    },
    dragOptions() {
      return {
        animation: 200,
        disabled: this.disabled,
        ghostClass: "ghost"
      };
    }
  },
  methods: {
    ...mapActions("approvalLineSelector", ["removeLine", "changeSortOrder"]),
    onStart() {
      this.drag = true;
    },
    onEnd(e) {
      this.drag = false;
      const { oldIndex, newIndex } = e;
      const targetLine = this.list[oldIndex];
      const arrivalLine = this.list[newIndex];
      this.changeSortOrder({
        targetLine,
        newSortOrder: arrivalLine.sortOrder
      });
    },
    checkMove(e) {
      // 이동하려는 목적지가 고정된 아이템인지 검사
      const {
        draggedContext: { element, futureIndex }
      } = e;
      return !(element?.fixed || this.list[futureIndex]?.fixed);
    },
    // 결재선 편집 가능 여부
    lineEditable(line) {
      if (this.selectType === "CHANGE_SHARE") return false;
      if (this.selectType === "CHANGE_RECEIVE") return false;
      if (this.selectType === "CHANGE_UPPER") {
        // 상위 결재선 변경은 본인 이후의 결재선만 가능
        return line.sortOrder > this.getNextApproveLine?.sortOrder;
      }
      return true;
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-list-main {
  margin: 0;
  padding: 0;
}
.cr-no-item {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
</style>
