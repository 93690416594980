<template>
  <div style="position: relative">
    <v-progress-linear v-if="loading" absolute indeterminate top />
    <!-- header -->
    <div class="d-flex align-center">
      <span class="cr-section-title">최근 기안한 문서</span>
      <span class="cr-count ml-2">{{ totalElements }}</span>
      <v-spacer></v-spacer>
      <PagingHandle
        :page="page"
        :total-pages="totalPage"
        @loadData="loadList"
      />
    </div>
    <v-divider class="my-2"></v-divider>
    <!-- body -->
    <div v-if="isEmpty" class="cr-no-item-wrap">
      최근 기안한 문서가 없습니다.
    </div>
    <v-row class="mt-1 no-gutters">
      <v-col
        v-for="(document, idx) in recentlyDraftList.documents"
        :key="idx"
        cols="6"
        sm="4"
        xl="3"
        class="pa-2"
      >
        <RecentlyDraftItem
          v-bind="document"
          @click="routeViewPage(document)"
          @click:reuse="onClickReuseBtn"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PagingHandle from "@/home/views/components/widget/commons/PagingHandle";
import RecentlyDraftItem from "@/approval/views/pages/DashboardPage/item/RecentlyDraftItem";

export default {
  components: { RecentlyDraftItem, PagingHandle },
  computed: {
    ...mapState("approvalDashboard", ["recentlyDraftList"]),
    loading() {
      return this.recentlyDraftList?.loading ?? false;
    },
    isEmpty() {
      return this.recentlyDraftList?.documents?.length < 1 ?? true;
    },
    page() {
      return this.recentlyDraftList?.page ?? 0;
    },
    size() {
      return this.recentlyDraftList?.size ?? 5;
    },
    totalElements() {
      return this.recentlyDraftList?.totalElements ?? 0;
    },
    totalPage() {
      const { totalElements, size } = this.recentlyDraftList;
      return Math.ceil(totalElements / size);
    }
  },
  methods: {
    ...mapActions("confirm", ["confirm"]),
    ...mapActions("approvalDashboard", ["fetchRecentlyDraftList"]),
    loadList(page) {
      this.fetchRecentlyDraftList({ page, size: this.size });
    },
    routeViewPage(item) {
      let boxKey;
      switch (item?.status) {
        case "DRAFT":
        case "PROGRESS":
          boxKey = "dd";
          break;
        case "REJECT":
          boxKey = "dr";
          break;
        case "COMPLETE":
          boxKey = "dc";
          break;
        default:
          return;
      }
      window.open(`#/approval/doc/list/${boxKey}/${item.id}`);
    },
    onClickReuseBtn(docId) {
      this.confirm({
        headline: "재기안",
        message:
          "해당 문서의 내용을 재사용 하여 " +
          "<br/> " +
          "새로운 기안을 작성 하시겠습니까?",
        callback: () => window.open(`#/approval/draft/reuse?docId=${docId}`)
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-count {
  height: 20px;
  min-width: 20px;
  line-height: 20px;
  background-color: var(--v-primary-base);
  border-radius: 5px;
  padding: 0 3px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  white-space: nowrap;
}
.cr-no-item-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.38);
}
</style>
