<template>
  <v-overlay :value="value" :absolute="absolute" :z-index="zIndex">
    <div class="d-flex flex-column align-center justify-center">
      <v-progress-linear
        v-if="linear"
        rounded
        indeterminate
        color="white"
        class="mb-5"
        style="width: 100px;"
      />
      <v-progress-circular v-else indeterminate class="mb-5" size="64" />
      <span>{{ text }}</span>
    </div>
  </v-overlay>
</template>

<script>
export default {
  name: "LoadingOverlay",
  props: {
    value: {
      type: Boolean,
      default: false,
      description: "오버레이 표시 여부"
    },
    zIndex: {
      type: Number,
      description: "오버레이 z-index 옵션"
    },
    absolute: {
      type: Boolean,
      default: false,
      description: "오버레이 absolute 옵션"
    },
    linear: {
      type: Boolean,
      default: false,
      description: "로딩 linear 아이콘으로 표시 여부"
    },
    text: {
      type: String,
      default: "로딩중",
      description: "설명 텍스트"
    }
  }
};
</script>

<style scoped></style>
