<template>
  <v-dialog
    :value="value"
    :scrollable="scrollable"
    :max-width="maxWidth"
    v-on="$listeners"
    persistent
    retain-focus
    no-click-animation
  >
    <v-card :loading="loading">
      <!-- 타이틀 -->
      <v-card-title>
        <span class="text-h6 font-weight-bold">내보내기</span>
      </v-card-title>
      <!-- 본문 -->
      <v-card-text class="pb-4">
        <p>
          <strong>현재 검색 결과</strong>의 모든 목록을 엑셀(xlsx) 파일로
          내보냅니다.
        </p>
        <span class="text-caption">목록에 포함할 항목</span>
        <div class="d-flex flex-wrap">
          <v-checkbox
            v-for="column in fixedColumns"
            :key="column.label"
            :label="column.label"
            input-value="true"
            class="mr-4"
            dense
            disabled
            hide-details
          />
        </div>
        <div class="d-flex flex-wrap">
          <v-checkbox
            v-for="column in selectableColumns"
            v-model="selected"
            :key="column.label"
            :value="column.value"
            :disabled="column.disabled"
            :label="column.label"
            class="mr-4"
            dense
            hide-details
          />
        </div>
      </v-card-text>
      <!-- 확인/취소 버튼-->
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text outlined @click="$emit('input', false)" :disabled="loading">
          취소
        </v-btn>
        <v-btn color="accent" text outlined @click="confirm" :loading="loading">
          확인
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { exportDocumentList } from "@/approval/api/approval.api";
import { mapActions } from "vuex";

export default {
  emits: ["click:confirm"],
  props: {
    value: {
      type: Boolean,
      default: false,
      description: "다이얼로그 표시 여부 (v-model)"
    },
    searchDetail: {
      type: Object,
      default: () => {},
      description: "내보내기할 검색조건"
    },
    sort: {
      type: String,
      description: "내보내기할 정렬 컬럼"
    },
    dir: {
      type: String,
      description: "내보내기할 정렬 방향 (ASC/DESC)"
    },
    persistent: {
      type: Boolean,
      default: false,
      description: "by vuetify dialog prop"
    },
    scrollable: {
      type: Boolean,
      default: false,
      description: "by vuetify dialog prop"
    },
    maxWidth: {
      type: Number,
      default: 600,
      description: "by vuetify dialog prop"
    }
  },
  data: () => ({
    fixedColumns: [
      { label: "문서번호" },
      { label: "기안양식" },
      { label: "기안제목" },
      { label: "기안자" },
      { label: "기안부서" }
    ],
    selectableColumns: [
      { label: "공개여부", value: "OPEN_TYPE", disabled: false },
      { label: "보존연한", value: "KEEP_YEAR", disabled: false },
      { label: "상신일자", value: "DRAFTED_DATE", disabled: false },
      { label: "완료일자", value: "COMPLETED_DATE", disabled: false },
      { label: "주소", value: "URL", disabled: false }
    ],
    selected: [
      "OPEN_TYPE",
      "KEEP_YEAR",
      "DRAFTED_DATE",
      "COMPLETED_DATE",
      "URL"
    ],
    loading: false
  }),
  computed: {},
  methods: {
    ...mapActions("snackbar", ["openSnackbar"]),
    confirm() {
      this.download();
    },
    reset() {
      this.selected = [
        "OPEN_TYPE",
        "KEEP_YEAR",
        "DRAFTED_DATE",
        "COMPLETED_DATE",
        "URL"
      ];
      this.loading = false;
    },
    async download() {
      this.loading = true;
      const { status, headers, data } = await exportDocumentList({
        includeHeaders: this.selected,
        sort: this.sort,
        dir: this.dir,
        ...this.getSearchDetailPayload(this.searchDetail)
      });
      if (status !== 200) {
        this.openSnackbar({
          message: "문서목록 내보내기에 실패했습니다.",
          type: "ERROR"
        });
        this.loading = false;
        return;
      }
      // blob URL 임의로 만들어 다운로드 처리
      const url = window.URL.createObjectURL(
        new Blob([data], { type: headers["content-type"] })
      );
      const link = document.createElement("a");
      link.href = url;
      let fileName = this.getFileName(headers["content-disposition"]);
      link.download = decodeURI(fileName);
      link.click();
      window.URL.revokeObjectURL(url);
      // 다운로드 후 다이얼로그 닫기
      this.$emit("input", false);
    },
    getFileName(contentDisposition) {
      let fileName = contentDisposition
        .split(";")
        .filter(ele => {
          return ele.indexOf("filename") > -1;
        })
        .map(ele => {
          return ele.replace(/"/g, "").split("=")[1];
        });
      return fileName[0] ? fileName[0] : null;
    },
    getSearchDetailPayload(detail = {}) {
      const {
        draftFromDate,
        draftToDate,
        formId,
        keywordFlag,
        keyword
      } = detail;
      return {
        draftFromDate,
        draftToDate,
        formId,
        keywordFlag,
        keyword:
          keywordFlag === "TITLE"
            ? keyword?.title
            : keywordFlag === "DOC_NUM"
            ? keyword?.docNum
            : keywordFlag === "DRAFT_USER_ID"
            ? keyword?.userId
            : keywordFlag === "DRAFT_ORGAN_ID"
            ? keyword?.organId
            : undefined
      };
    }
  },
  watch: {
    value(show) {
      if (!show) this.reset();
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .v-input {
  &.v-text-field--outlined {
    fieldset {
      border-width: 1px;
    }

    &.error--text fieldset {
      border-color: var(--v-error-base);
    }
  }
}
</style>
