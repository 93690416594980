<template>
  <div>
    <v-btn
      v-if="showApproveBtn"
      :disabled="disabledApproveBtn"
      :class="$vuetify.breakpoint.mdAndUp ? 'mb-2' : 'mr-2'"
      small
      depressed
      color="primary"
      @click="clickBtn('approve')"
    >
      결재
    </v-btn>
    <v-btn
      v-if="showHelpBtn"
      :disabled="disabledHelpBtn"
      :class="$vuetify.breakpoint.mdAndUp ? 'mb-2' : 'mr-2'"
      small
      depressed
      color="primary"
      @click="clickBtn('help')"
    >
      협조
    </v-btn>
    <v-btn
      v-if="showReceiveBtn"
      :disabled="disabledReceiveBtn"
      :class="$vuetify.breakpoint.mdAndUp ? 'mb-2' : 'mr-2'"
      small
      depressed
      color="primary"
      @click="clickBtn('receive')"
    >
      수신
    </v-btn>
    <v-btn
      v-if="showCcBtn"
      :disabled="disabledCcBtn"
      :class="$vuetify.breakpoint.mdAndUp ? 'mb-2' : 'mr-2'"
      small
      depressed
      color="primary"
      @click="clickBtn('cc')"
    >
      참조
    </v-btn>
    <v-btn
      v-if="showShareBtn"
      :disabled="disabledShareBtn"
      small
      depressed
      color="primary"
      @click="clickBtn('share')"
    >
      공람
    </v-btn>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { LINE_TYPE } from "@/approval/constant/approvalLine";

export default {
  name: "BtnGroup",
  props: {},
  computed: {
    ...mapState("approvalLineSelector", ["selectType", "organTreeActiveItem"]),
    showApproveBtn() {
      if (this.selectType === "CHANGE_SHARE") return false;
      if (this.selectType === "CHANGE_RECEIVE") return false;
      return true;
    },
    showHelpBtn() {
      if (this.selectType === "INNER_DRAFT") return false;
      if (this.selectType === "CHANGE_SHARE") return false;
      if (this.selectType === "CHANGE_RECEIVE") return false;
      return true;
    },
    showReceiveBtn() {
      if (this.selectType === "INNER_DRAFT") return false;
      if (this.selectType === "CHANGE_UPPER") return false;
      if (this.selectType === "CHANGE_SHARE") return false;
      return true;
    },
    showCcBtn() {
      if (this.selectType === "INNER_DRAFT") return false;
      if (this.selectType === "CHANGE_UPPER") return false;
      if (this.selectType === "CHANGE_SHARE") return false;
      if (this.selectType === "CHANGE_RECEIVE") return false;
      return true;
    },
    showShareBtn() {
      if (this.selectType === "INNER_DRAFT") return false;
      if (this.selectType === "CHANGE_UPPER") return false;
      if (this.selectType === "CHANGE_RECEIVE") return false;
      return true;
    },
    disabledApproveBtn() {
      if (!this.organTreeActiveItem) return true;
      return this.organTreeActiveItem?.type !== "USER";
    },
    disabledHelpBtn() {
      return !this.organTreeActiveItem;
    },
    disabledReceiveBtn() {
      return !this.organTreeActiveItem;
    },
    disabledCcBtn() {
      if (!this.organTreeActiveItem) return true;
      return this.organTreeActiveItem?.type !== "USER";
    },
    disabledShareBtn() {
      return !this.organTreeActiveItem;
    }
  },
  methods: {
    ...mapActions("approvalLineSelector", ["addLine"]),
    // 버튼 클릭시
    clickBtn(btnType) {
      let lineType;
      switch (btnType) {
        case "approve":
          lineType = LINE_TYPE.APPROVAL;
          break;
        case "help":
          lineType =
            this.organTreeActiveItem.type === "USER"
              ? LINE_TYPE.HELP
              : LINE_TYPE.HELP_ORGAN;
          break;
        case "receive":
          lineType =
            this.organTreeActiveItem.type === "USER"
              ? LINE_TYPE.RECEIVE
              : LINE_TYPE.RECEIVE_ORGAN;
          break;
        case "cc":
          lineType = LINE_TYPE.CC;
          break;
        case "share":
          lineType =
            this.organTreeActiveItem.type === "USER"
              ? LINE_TYPE.SHARE
              : LINE_TYPE.SHARE_ORGAN;
          break;
      }
      this.addLine(lineType);
    }
  }
};
</script>

<style scoped></style>
