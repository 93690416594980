<template>
  <v-tooltip bottom color="black" :disabled="!hasError">
    <template v-slot:activator="validationToolTip">
      <!-- chip 보기 모드 -->
      <v-tooltip
        v-if="chip"
        bottom
        color="black"
        min-width="160"
        max-width="200"
        :disabled="hasError"
      >
        <template v-slot:activator="approverTooltip">
          <v-chip
            v-on="{ ...validationToolTip.on, ...approverTooltip.on }"
            v-bind="{ ...validationToolTip.attrs, ...approverTooltip.attrs }"
            :close="editable"
            :color="mainColorLight"
            :style="`margin:8px; border:thin solid ${borderColor}`"
            @click:close="$emit('removeLine', approvalLine)"
          >
            <span v-text="getApproverNameWithOrgan" />
          </v-chip>
        </template>
        <!-- 툴팁 텍스트 -->
        <div class="d-flex flex-column align-center text-break text-center">
          <span>{{ approverName }}</span>
          <span v-if="approver.isUser" class="text-caption">
            {{ approver.userEmail }}
          </span>
          <span v-if="approver.isUser" class="text-caption">
            {{ approver.organName }}
          </span>
        </div>
      </v-tooltip>
      <!-- 카드 보기 모드 -->
      <div
        v-else
        v-on="validationToolTip.on"
        v-bind="validationToolTip.attrs"
        class="cr-line-item d-flex flex-column align-center justify-start"
        :class="getCardClass"
        :style="`border:thin solid ${borderColor}`"
      >
        <!-- 결재 유형 -->
        <div
          class="text-center subtitle-2 py-1"
          style="width:100%; border-bottom: thin solid rgba(0,0,0,0.12)"
          :style="`background: ${mainColorLight};`"
        >
          {{ getLineTypeName }}
        </div>
        <v-tooltip
          bottom
          color="black"
          min-width="160"
          max-width="200"
          :disabled="hasError"
        >
          <template v-slot:activator="{ on, attrs }">
            <!-- 결재자 정보 -->
            <div
              v-on="on"
              v-bind="attrs"
              class="fill-height d-flex flex-column align-center justify-center pa-2"
              style="width: 100%"
            >
              <span
                class="cr-item-text subtitle-2 font-weight-bold text-truncate"
              >
                {{ approverName }}
              </span>
              <span
                v-if="approver.isUser"
                class="cr-item-text text-caption text-truncate"
              >
                {{ `(${approver.userEmail})` }}
              </span>
              <span
                v-if="approver.isUser"
                class="cr-item-text text-caption text-truncate"
              >
                {{ approver.organName }}
              </span>
            </div>
          </template>
          <!-- 툴팁 텍스트 -->
          <div class="d-flex flex-column align-center text-break text-center">
            <span>{{ approverName }}</span>
            <span v-if="approver.isUser" class="text-caption">
              {{ approver.userEmail }}
            </span>
            <span v-if="approver.isUser" class="text-caption">
              {{ approver.organName }}
            </span>
          </div>
        </v-tooltip>
        <!-- 결재 상태 -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div
              v-if="!hideStatus && !editable"
              v-on="on"
              v-bind="attrs"
              class="text-center subtitle-2 py-1 text-caption"
              style="width:100%; border-top: thin solid rgba(0,0,0,0.12)"
              :style="getStatusStyle"
            >
              {{ getProcessInfo }}
            </div>
          </template>
          <!-- 결재 상태 마우스 오버시 처리자 정보 -->
          <span v-if="receiptUser">
            {{ `[${getStatus}] ${receiptUser.name}(${receiptUser.email})` }}
          </span>
          <span v-else>
            {{ `[${getStatus}] ${getApproverNameWithEmail}` }}
          </span>
        </v-tooltip>
        <!-- 삭제 버튼 (편집 모드) -->
        <v-btn
          v-if="editable"
          icon
          x-small
          class="cr-close-btn"
          @click="$emit('removeLine', approvalLine)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </template>
    <!-- 툴팁 텍스트-->
    <span style="white-space: pre-line">{{ errorText }}</span>
  </v-tooltip>
</template>

<script>
import { LINE_TYPE } from "@/approval/constant/approvalLine";
import { getDateWithTime } from "@/commons/utils/moment";
import { hexColorToRGBA, themeColorToHex } from "@/commons/utils/colorUtils";

export default {
  name: "ApprovalLineItem",
  props: {
    chip: {
      type: Boolean,
      default: false,
      description: "chip 형태로 보여줄지 여부"
    },
    editable: {
      type: Boolean,
      default: false,
      description: "편집 모드 여부"
    },
    approvalLine: {
      type: Object,
      required: true,
      description: "표현할 결재선 아이템"
    },
    color: {
      type: String,
      default: "primary",
      description: "색상 값 HEX 또는 Theme Color 이름"
    },
    hideStatus: {
      type: Boolean,
      default: false,
      description: "결재 상태 숨김 여부"
    }
  },
  computed: {
    getCardClass() {
      let classList = [];
      if (this.editable) classList.push("cr-edit-mode");
      if (this.hideStatus) classList.push("cr-hidden-status");
      return classList.join(" ");
    },
    lineErrors() {
      return this.approvalLine?.errors ?? [];
    },
    hasError() {
      return this.lineErrors?.length > 0;
    },
    errorText() {
      return this.lineErrors.map(err => "※ " + err).join("\n");
    },
    approver() {
      return this.approvalLine.approver;
    },
    approverName() {
      return this.approver?.isUser
        ? this.approver?.userName
        : this.approver?.organName;
    },
    receiptUser() {
      return this.approvalLine.receiptUser;
    },
    getApproverNameWithOrgan() {
      return this.approver?.isUser
        ? `${this.approver?.userName}(${this.approver?.organName})`
        : this.approver?.organName;
    },
    getApproverNameWithEmail() {
      return this.approver?.isUser
        ? `${this.approver?.userName}(${this.approver?.userEmail})`
        : this.approver?.organName;
    },
    getLineTypeName() {
      switch (this.approvalLine.type) {
        case LINE_TYPE.APPROVAL:
          return "결재";
        case LINE_TYPE.HELP:
        case LINE_TYPE.HELP_ORGAN:
          return "협조";
        case LINE_TYPE.RECEIVE:
        case LINE_TYPE.RECEIVE_ORGAN:
          return "수신";
        case LINE_TYPE.CC:
          return "참조";
        case LINE_TYPE.SHARE:
        case LINE_TYPE.SHARE_ORGAN:
          return "공람";
        default:
          return "";
      }
    },
    getStatus() {
      let status;
      switch (this.approvalLine.status) {
        case "RECEIPT":
          status = "접수";
          break;
        case "APPROVED":
          status = "승인";
          break;
        case "PRE_APPROVED":
          status = "전결";
          break;
        case "AUTO_APPROVED":
          status = "자동승인";
          break;
        case "REJECTED":
          status = "반려";
          break;
        case "RECEIVED":
          status = "수신";
          break;
        case "RETURNED":
          status = "반송";
          break;
        case "PENDING_APPROVAL":
          status = "결재대기";
          break;
        default:
          status = "대기";
      }
      return status;
    },
    getProcessInfo() {
      return this.getStatus === "대기"
        ? "-"
        : `${this.getStatus} ${this.updatedTimeMillis}`;
    },
    getStatusStyle() {
      switch (this.approvalLine.status) {
        case "APPROVED":
        case "PRE_APPROVED":
        case "AUTO_APPROVED":
        case "RECEIVED":
          return "color: #37f";
        case "REJECTED":
        case "RETURNED":
          return "color: #ff8080";
        default:
          return "";
      }
    },
    updatedTimeMillis() {
      return getDateWithTime(this.approvalLine?.updatedTimeMillis);
    },
    // {props.color}의 HEX 값
    mainColor() {
      return this.hasError
        ? themeColorToHex("error")
        : themeColorToHex(this.color);
    },
    // {mainColor}의 투명도를 0.03으로 낮춘 [RGBA] 문자열 반환
    mainColorLight() {
      return hexColorToRGBA(this.mainColor, 0.03);
    },
    // 테두리 색상
    borderColor() {
      return this.hasError ? this.mainColor : "rgba(0,0,0,0.12)";
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-line-item {
  margin: 8px;
  position: relative;
  width: 160px;
  height: 160px;
  border: thin solid rgba(0, 0, 0, 0.12);
  border-radius: 5px;

  &.cr-edit-mode {
    margin-top: 28px !important;
  }

  &.cr-hidden-status {
    height: 120px !important;
  }

  .cr-close-btn {
    position: absolute;
    top: -24px;
    right: 0;
  }

  .cr-item-text {
    max-width: 130px;
  }
}
</style>
