<template>
  <v-expansion-panels
    v-model="openItems"
    :readonly="readonly"
    multiple
    flat
    tile
    accordion
  >
    <v-expansion-panel v-for="(item, itemIdx) in items" :key="itemIdx">
      <!-- 폴딩 목록 헤더 -->
      <v-expansion-panel-header class="px-2" style="min-height: 0;">
        <div class="d-flex align-center">
          <span class="mr-2">{{ item.title }}</span>
          <span
            v-if="
              getLinesByGroup(item.group) &&
                getLinesByGroup(item.group).length > 0
            "
            class="cr-count"
          >
            {{ getLinesByGroup(item.group).length }}
          </span>
        </div>
      </v-expansion-panel-header>
      <!-- 결재선 목록 -->
      <v-expansion-panel-content class="pa-0">
        <v-row no-gutters>
          <ApprovalLineItem
            v-for="(line, lineIdx) in getLinesByGroup(item.group)"
            :key="lineIdx"
            :chip="
              (editable && itemIdx !== 0) ||
                (!editable && [2, 3].includes(itemIdx))
            "
            :approvalLine="line"
            :editable="editable"
            :hide-status="hideStatus"
            class="ma-2"
            @removeLine="removeLine"
          />
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import ApprovalLineItem from "@/approval/views/components/approvalline/ApprovalLineItem";
import { mapActions } from "vuex";
import { LINE_GROUP } from "@/approval/constant/approvalLine";
import { ApprovalLines } from "@/approval/utils/ApprovalLines";
export default {
  components: { ApprovalLineItem },
  emits: ["update:approvalLines"],
  props: {
    approvalLines: {
      type: Array,
      default: () => [],
      description: "결재선 목록"
    },
    editable: {
      type: Boolean,
      default: false,
      description: "편집 모드"
    },
    readonly: {
      type: Boolean,
      default: false,
      description: "읽기전용 모드 (vuetify props)"
    },
    hideStatus: {
      type: Boolean,
      default: false,
      description: "결재 상태 숨김 여부"
    },
    openAll: {
      type: Boolean,
      default: false,
      description: "모든 그룹 펼치기"
    }
  },
  data: () => ({
    openItems: [],
    items: [
      { title: "결재선", group: LINE_GROUP.APPROVAL },
      { title: "수신", group: LINE_GROUP.RECEIVE },
      { title: "참조", group: LINE_GROUP.CC },
      { title: "공람", group: LINE_GROUP.SHARE }
    ]
  }),
  methods: {
    ...mapActions("snackbar", ["openSnackbar"]),
    // [결재그룹]에 따른 결재선 목록
    getLinesByGroup(group) {
      return new ApprovalLines(this.approvalLines)
        .filterByGroup(group)
        .sortAsc()
        .get();
    },
    // 결재선 제거
    removeLine(line) {
      const removedLines = new ApprovalLines(this.approvalLines)
        .removeLine(line?.key)
        .get();
      this.$emit("update:approvalLines", removedLines);
    },
    openGroup(group) {
      const index = this.items.findIndex(item => item.group === group);
      if (this.openItems.includes(index)) return;
      this.openItems = [...this.openItems, index];
    },
    closeGroup(group) {
      const index = this.items.findIndex(item => item.group === group);
      this.openItems = this.openItems.filter(i => i !== index);
    }
  },
  computed: {
    approvalGroupLinesCount() {
      return new ApprovalLines(this.approvalLines)
        .filterByGroup(LINE_GROUP.APPROVAL)
        .getCount();
    },
    receiveGroupLinesCount() {
      return new ApprovalLines(this.approvalLines)
        .filterByGroup(LINE_GROUP.RECEIVE)
        .getCount();
    },
    ccGroupLinesCount() {
      return new ApprovalLines(this.approvalLines)
        .filterByGroup(LINE_GROUP.CC)
        .getCount();
    },
    shareGroupLinesCount() {
      return new ApprovalLines(this.approvalLines)
        .filterByGroup(LINE_GROUP.SHARE)
        .getCount();
    }
  },
  watch: {
    approvalGroupLinesCount(n, p) {
      if (p === 0 && n > 0) {
        this.openGroup(LINE_GROUP.APPROVAL);
      }
      if (n === 0 && p > 0) {
        this.closeGroup(LINE_GROUP.APPROVAL);
      }
    },
    receiveGroupLinesCount(n, p) {
      if (p === 0 && n > 0) {
        this.openGroup(LINE_GROUP.RECEIVE);
      }
      if (n === 0 && p > 0) {
        this.closeGroup(LINE_GROUP.RECEIVE);
      }
    },
    ccGroupLinesCount(n, p) {
      if (p === 0 && n > 0) {
        this.openGroup(LINE_GROUP.CC);
      }
      if (n === 0 && p > 0) {
        this.closeGroup(LINE_GROUP.CC);
      }
    },
    shareGroupLinesCount(n, p) {
      if (p === 0 && n > 0) {
        this.openGroup(LINE_GROUP.SHARE);
      }
      if (n === 0 && p > 0) {
        this.closeGroup(LINE_GROUP.SHARE);
      }
    }
  },
  mounted() {
    // 모든 그룹 열기
    if (this.openAll) {
      this.items.forEach(({ group }) => this.openGroup(group));
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-count {
  background-color: var(--v-accent-base);
  border-radius: 5px;
  color: #fff;
  font-size: 12px;
  height: 18px;
  letter-spacing: 0;
  min-width: 18px;
  padding: 3px 5px;
  pointer-events: auto;
  text-align: center;
  text-indent: 0;
  top: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  white-space: nowrap;
}
</style>
