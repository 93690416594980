<template>
  <div v-if="!!stagedPreset.id" class="cr-detail-section-main">
    <!-- 헤더-->
    <div class="cr-header">
      <!-- 프리셋 이름 편집 모드 일때-->
      <v-form
        v-if="nameEditMode"
        class="cr-name-edit-group"
        style="width: 100%"
        @submit.prevent="renamePreset(stagedPresetName)"
      >
        <v-text-field
          v-model="stagedPresetName"
          label="프리셋 이름"
          class="mr-1"
          hide-details
          autofocus
          outlined
          dense
        />
        <v-btn text small @click="nameEditMode = false">취소</v-btn>
      </v-form>
      <!-- 프리셋 이름 -->
      <div v-if="!nameEditMode" class="title mr-1 text-truncate">
        {{ presetName }}
      </div>
      <v-btn
        v-if="!nameEditMode"
        small
        icon
        :ripple="false"
        @click="nameEditMode = true"
      >
        <v-icon size="20">mdi-pencil</v-icon>
      </v-btn>
      <v-spacer class="mr-10"></v-spacer>
      <div class="cr-manage-group">
        <v-btn
          tile
          depressed
          class="mr-1"
          color="primary"
          @click.stop="showLineSelectDialog = true"
        >
          <v-icon small>mdi-pencil-outline</v-icon>
          <span>수정</span>
        </v-btn>
        <v-btn tile depressed color="error" @click="deletePreset">
          <v-icon small>mdi-trash-can-outline</v-icon>
          <span>삭제</span>
        </v-btn>
      </div>
    </div>
    <!-- 프리셋 내용 미리보기 -->
    <div class="cr-preview-wrap">
      <div class="cr-preview">
        <div class="cr-preview-block">
          <div class="cr-section-title">기안부서</div>
          <div class="cr-section-content">
            <span>{{ stagedPresetDraftOrgan.name }}</span>
          </div>
        </div>
        <div class="cr-preview-block">
          <div class="cr-section-title">결재선</div>
          <div class="cr-section-content">
            <ApprovalLineExpansionPanel
              hide-status
              open-all
              :approvalLines.sync="stagedPresetLines"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- 결재선 수정 다이얼로그-->
    <ApprovalLineSelectDialog
      v-model="showLineSelectDialog"
      :title="`[${presetName}] 결재선 편집`"
      :approval-lines="stagedPresetLines"
      :draft-organ="stagedPresetDraftOrgan"
      :draft-user="draftUser"
      persistent
      @submit="updatePreset"
    />
  </div>
  <div class="cr-no-item" v-else>
    <v-icon size="125" class="mb-2">mdi-text-box-search-outline</v-icon>
    <div class="cr-no-item-text">
      내 결재선을 클릭해주세요.<br />
      이곳에서 미리보기 및 편집이 가능합니다.
    </div>
  </div>
</template>

<script>
import ApprovalLineExpansionPanel from "@/approval/views/components/approvalline/ApprovalLineExpansionPanel";
import ApprovalLineSelectDialog from "@/approval/views/components/dialog/ApprovalLineSelectDialog";
import { mapActions, mapGetters } from "vuex";
import { ApprovalLines } from "@/approval/utils/ApprovalLines";
import { getGroupByType } from "@/approval/constant/approvalLine";

export default {
  components: {
    ApprovalLineSelectDialog,
    ApprovalLineExpansionPanel
  },
  props: {
    preset: {
      type: Object,
      default: () => {},
      description: "내 결재선"
    }
  },
  data: () => ({
    stagedPreset: {},
    nameEditMode: false,
    showLineSelectDialog: false
  }),
  computed: {
    ...mapGetters("auth", ["getUserInfo", "getRepresentativeOrgan"]),
    draftUser() {
      const { id, username: email, accountName: name } = this.getUserInfo;
      return { id, email, name };
    },
    stagedPresetName: {
      get() {
        return this.stagedPreset?.name ?? "";
      },
      set(val) {
        this.stagedPreset.name = val;
      }
    },
    stagedPresetDraftOrgan: {
      get() {
        return this.stagedPreset?.draftOrgan ?? {};
      },
      set(val) {
        this.stagedPreset.draftOrgan = val;
      }
    },
    stagedPresetLines: {
      get() {
        return this.stagedPreset?.approvalLines ?? [];
      },
      set(val) {
        this.stagedPreset.approvalLines = val;
      }
    },
    presetName() {
      return this.preset?.name ?? "";
    },
    hasChanged() {
      const originLines = this.preset?.approvalLines ?? [];
      const stagedLines = this.stagedPreset?.approvalLines ?? [];
      const originSignatures = this.convertForComparing(originLines);
      const stagedSignatures = this.convertForComparing(stagedLines);
      const sameDraftOrgan =
        this.preset?.draftOrgan?.organId ===
        this.stagedPreset?.draftOrgan?.organId;
      const sameLines =
        originSignatures.length === stagedSignatures.length &&
        originSignatures.every((sign, idx) => sign === stagedSignatures[idx]);
      return !sameDraftOrgan || !sameLines;
    }
  },
  methods: {
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapActions("confirm", ["confirm"]),
    ...mapActions("approvalConfig", [
      "renameApprLinePreset",
      "deleteApprLinePreset",
      "updateApprLinePreset"
    ]),
    renamePreset(newName = "") {
      const alert = message =>
        this.openSnackbar({ type: "ERROR", message: message });
      if (!newName.length) return alert("최소 한글자 이상 입력해 주세요.");
      if (newName.length > 40) return alert("최대 40글자 입력 가능합니다.");
      this.renameApprLinePreset({
        presetId: this.stagedPreset.id,
        name: newName
      });
    },
    updatePreset({ approvalLines = [], draftOrgan = {} }) {
      const callback = () => {
        this.stagedPresetLines = [...approvalLines];
        this.stagedPresetDraftOrgan = { ...draftOrgan };
        this.showLineSelectDialog = false;
        this.updateApprLinePreset(this.stagedPreset);
      };
      this.confirm({
        headline: "내 결재선 수정",
        message: "변경된 내용으로 내 결재선을 수정 하시겠습니까?",
        callback
      });
    },
    deletePreset() {
      this.confirm({
        headline: "내 결재선 삭제",
        message: `[${this.presetName}] 결재선을 삭제 하시겠습니까?`,
        callback: () => this.deleteApprLinePreset(this.stagedPreset.id)
      });
    },
    // 변경점 감지를 위해 정렬 및 시그니처 추출
    convertForComparing(approvalLines = []) {
      return new ApprovalLines(approvalLines)
        .sortAsc("sortOrder")
        .sort((a, b) => {
          const aGroup = getGroupByType(a.type);
          const bGroup = getGroupByType(b.type);
          return aGroup.localeCompare(bGroup);
        })
        .get()
        .map(({ approver }) => this.getApproverSignature(approver));
    },
    // userId, organId 모두 일치해야만 동일한 결재자로 인정
    getApproverSignature(approver = {}) {
      const { userId, organId } = approver;
      return `${userId}_${organId}`;
    }
  },
  watch: {
    preset(n) {
      // 수정을 위해 data 변수로 스테이징
      this.stagedPreset = Object.assign({}, n);
      this.nameEditMode = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-detail-section-main {
  position: relative;
  height: 100%;
  padding-top: 40px;

  .cr-header {
    position: absolute;
    width: 100%;
    height: 48px;
    padding-bottom: 4px;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;

    .cr-name-edit-group {
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 700px;
    }
    .cr-manage-group {
      display: flex;
    }
  }

  .cr-preview-wrap {
    overflow: auto;
    height: 100%;
    padding-bottom: 10px;
    .cr-preview {
      //border: 1px solid #1a1a1a;
      padding: 20px;
      margin-top: 10px;
      margin-bottom: 10px;

      .cr-preview-block {
        display: flex;
        flex-direction: column;
        .cr-section-title {
          font-weight: bold;
        }
        .cr-section-content {
          border: 1px solid #ccc;
          padding: 10px;
          margin-top: 10px;
          border-radius: 5px;
        }
        &:not(:last-child) {
          margin-bottom: 24px;
        }
      }
    }
  }
}
.cr-no-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  .cr-no-item-text {
    text-align: center;
  }
}
</style>
