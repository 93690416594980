<template>
  <div style="position: relative">
    <v-progress-linear v-if="loading" absolute indeterminate top />
    <span class="cr-section-title">나의 현황</span>
    <v-divider class="my-2"></v-divider>
    <v-container fluid class="ma-0 pa-0 ">
      <v-row no-gutters>
        <v-col v-for="(item, idx) in items" :key="idx" cols="6" md="3">
          <v-btn
            tile
            depressed
            color="white"
            max-width="100%"
            width="100%"
            height="100%"
            class="pa-5"
            :loading="loading"
            @click="routeList(item.boxKey)"
          >
            <div class="d-flex flex-column align-center justify-center">
              <span>
                {{ item.text }}
              </span>
              <span class="title">
                {{ getCountByBoxKey(item.boxKey) }}
              </span>
            </div>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {},
  emits: ["click"],
  data: () => ({
    items: [
      { boxKey: "dd", text: "상신한" },
      { boxKey: "dr", text: "반려된" },
      { boxKey: "ab", text: "결재전" },
      { boxKey: "rb", text: "수신전" }
    ]
  }),
  computed: {
    ...mapState("approvalDashboard", ["count"]),
    loading() {
      return this.count?.loading;
    }
  },
  methods: {
    getCountByBoxKey(boxKey) {
      switch (boxKey) {
        case "ab":
          return this.count?.beforeApproveCount;
        case "dd":
          return this.count?.draftedCount;
        case "dr":
          return this.count?.rejectedCount;
        case "rb":
          return this.count?.beforeReceiveCount;
      }
    },
    routeList(boxKey) {
      this.$router.push({
        name: "approval_document_list",
        params: { boxKey }
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
