import { render, staticRenderFns } from "./RecentlyFormSection.vue?vue&type=template&id=22c3b3df&scoped=true&"
import script from "./RecentlyFormSection.vue?vue&type=script&lang=js&"
export * from "./RecentlyFormSection.vue?vue&type=script&lang=js&"
import style0 from "./RecentlyFormSection.vue?vue&type=style&index=0&id=22c3b3df&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22c3b3df",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCol,VDivider,VProgressLinear,VRow,VSpacer})
