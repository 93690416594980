<template>
  <div
    class="cr-panel-main"
    :class="dense ? 'dense' : ''"
    :style="`background: ${mainColorLight}; border-color:${mainColor}`"
  >
    <div class="d-flex align-center mb-1">
      <v-icon v-if="icon" size="18" class="mr-1" :style="`color: ${mainColor}`">
        {{ icon }}
      </v-icon>
      <span class="cr-title" :style="`color: ${mainColor}`">{{ title }}</span>
    </div>
    <span
      class="body-2 text--secondary light-black--text"
      style="white-space: pre-line"
    >
      {{ content }}
    </span>
  </div>
</template>

<script>
import { hexColorToRGBA, themeColorToHex } from "@/commons/utils/colorUtils";

export default {
  name: "InfoPanel",
  props: {
    dense: {
      type: Boolean,
      default: false,
      description: "좁은 여백 여부"
    },
    icon: {
      type: String,
      default: "mdi-information-box-outline",
      description: "아이콘"
    },
    title: {
      type: String,
      default: "안내",
      description: "제목"
    },
    content: {
      type: String,
      default: "",
      description: "본문 내용"
    },
    color: {
      type: String,
      default: "primary",
      description: "색상 값 HEX 또는 Theme Color 이름"
    }
  },
  computed: {
    // {props.color}를 [HEX] 값으로 반환
    mainColor() {
      return themeColorToHex(this.color);
    },
    // {mainColor}의 투명도를 0.03으로 낮춘 [RGBA] 문자열 반환
    mainColorLight() {
      return hexColorToRGBA(this.mainColor, 0.03);
    }
  }
};
</script>
<style lang="scss" scoped>
.cr-panel-main {
  padding: 20px 15px;
  border: thin solid;
  border-radius: 3px;
  &.dense {
    padding: 10px 10px;
  }
}
</style>
