<template>
  <v-container fluid style="height: 100%">
    <v-row style="height: 100%">
      <v-col cols="4" xl="3" style="height: 100%" class="pa-0 pr-2">
        <ListSection
          :items="items"
          :selected-items.sync="selectedItems"
          :view-item="viewItem"
          @click:item="onClickItem"
          @click:add="showLineSelectDialog = true"
          @click:delete="deleteSelectedItems"
        />
      </v-col>
      <v-divider vertical></v-divider>
      <v-col cols="8" xl="9" style="height: 100%" class="pa-0">
        <DetailSection :preset="viewItem" class="mx-4" />
      </v-col>
    </v-row>
    <!-- 결재선 선택 다이얼로그 -->
    <ApprovalLineSelectDialog
      v-model="showLineSelectDialog"
      :draft-organ="draftOrgan"
      :draft-user="draftUser"
      title="결재선 선택"
      @submit="openPresetSaveDialog"
    />
    <!-- 프리셋 추가 다이얼로그 -->
    <ApprLinePresetSaveDialog
      v-model="showPresetSaveDialog"
      :loading="loadingSavePreset"
      :approval-lines="stagedApprovalLines"
      :draft-organ="stagedDraftOrgan"
      @click:apply="savePreset"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import ListSection from "@/approval/views/pages/ConfigPage/PresetConfigPage/ListSection";
import DetailSection from "@/approval/views/pages/ConfigPage/PresetConfigPage/DetailSection";
import ApprovalLineSelectDialog from "@/approval/views/components/dialog/ApprovalLineSelectDialog";
import ApprLinePresetSaveDialog from "@/approval/views/components/dialog/ApprLinePresetSaveDialog";

export default {
  components: {
    ApprLinePresetSaveDialog,
    ApprovalLineSelectDialog,
    DetailSection,
    ListSection
  },
  data: () => ({
    viewItemId: null, // 상세보기 아이템 아이디
    selectedItems: [], // 목록에서 선택된 아이템
    showLineSelectDialog: false,
    showPresetSaveDialog: false,
    stagedApprovalLines: [],
    stagedDraftOrgan: {},
    loadingSavePreset: false
  }),
  methods: {
    ...mapActions("confirm", ["confirm"]),
    ...mapActions("approvalConfig", [
      "fetchApprLinePresetList",
      "saveApprLinePreset",
      "deleteApprLinePresetInBulk"
    ]),
    /**
     * 목록에서 아이템 클릭시 (상세보기 열기)
     */
    onClickItem(item) {
      const toggle = item?.id === this.viewItem?.id;
      this.viewItemId = toggle ? null : item.id;
    },
    /**
     * 프리셋 추가 다이얼로그 열기
     */
    openPresetSaveDialog({ approvalLines = [], draftOrgan = {} }) {
      this.stagedApprovalLines = [...approvalLines];
      this.stagedDraftOrgan = { ...draftOrgan };
      this.showPresetSaveDialog = true;
    },
    /**
     * 새로운 프리셋 저장
     */
    async savePreset(param) {
      this.loadingSavePreset = true;
      const { name, draftOrgan, approvalLines } = param;
      const success = await this.saveApprLinePreset({
        name,
        draftOrgan,
        approvalLines
      });
      if (success) {
        this.showPresetSaveDialog = false;
        this.showLineSelectDialog = false;
      }
      this.loadingSavePreset = false;
      // 새로 추가된 내결재선 상세보기 open
      this.viewItemId = this.getApprLinePresets?.[0]?.id;
    },
    /**
     * 일괄 삭제
     */
    deleteSelectedItems(selectedItem = []) {
      const callback = () => {
        const ids = selectedItem.map(({ id }) => id);
        this.deleteApprLinePresetInBulk(ids);
      };
      this.confirm({
        headline: "내 결재선 삭제",
        message: `선택한 ${this.selectedItems.length}개의 프리셋을 모두 삭제 하시겠습니까?`,
        callback
      });
    }
  },
  computed: {
    ...mapGetters("auth", ["getRepresentativeOrgan", "getUserInfo"]),
    ...mapGetters("approvalConfig", ["getApprLinePresets"]),
    ...mapState("approvalConfig", ["presetConfig"]),
    items() {
      return this.presetConfig?.apprLinePresets ?? [];
    },
    viewItem() {
      return this.items.find(({ id }) => this.viewItemId === id);
    },
    draftOrgan() {
      const { organId, organizationName: name } = this.getRepresentativeOrgan;
      return { organId, name };
    },
    draftUser() {
      const { id, username: email, accountName: name } = this.getUserInfo;
      return { id, email, name };
    }
  },
  watch: {
    // items 목록에 없는 항목이 선택되어 있는 경우 제거 (sync)
    items(newItems) {
      this.selectedItems = this.selectedItems.filter(item =>
        newItems.some(newItem => newItem.id === item.id)
      );
      this.viewItemId = newItems.some(({ id }) => id === this.viewItemId)
        ? this.viewItemId
        : null;
    }
  },
  mounted() {
    this.fetchApprLinePresetList();
  }
};
</script>

<style scoped></style>
