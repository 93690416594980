<template>
  <div class="py-4 py-md-15">
    <LoadingOverlay :value="loading" :z-index="999" linear text="불러오는중" />
    <!-- 원본 문서 삭제되었을 경우 알림 베너-->
    <div v-if="invalid" class="cr-top-banner">
      <p style="text-align: center">
        <span class="text-body-1 font-weight-light">
          {{ invalidReason }}
        </span>
        <br />
      </p>
      <div class="mt-1">
        <v-btn v-if="isPreviewPage" outlined color="white" @click="routeToHome">
          <span class="font-weight-light">홈으로 이동</span>
        </v-btn>
        <v-btn v-else outlined color="white" @click="routeToList">
          <span class="font-weight-light">목록으로 이동</span>
        </v-btn>
        <v-btn
          v-if="isDraftUser"
          outlined
          color="white"
          class="ml-2"
          @click="onClickDeleteBtn"
        >
          <span class="font-weight-light">문서 삭제</span>
        </v-btn>
      </div>
    </div>
    <!-- 문서 제목 -->
    <SectionTitle :title="document.title">
      <template v-slot:append>
        <v-btn
          v-if="document.status === 'COMPLETE'"
          icon
          x-small
          color="grey darken-1"
          class="pa-4 ml-1 mr-12"
          @click="copyURL"
        >
          <v-icon>mdi-link-variant</v-icon>
        </v-btn>
      </template>
      <template v-slot:tail>
        <div>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <div
                v-on="on"
                v-bind="attrs"
                class="d-flex align-center text-truncate"
              >
                <v-icon x-small class="mr-1">mdi-pencil</v-icon>
                <span class="text-caption">{{ draftedTime }}</span>
              </div>
            </template>
            <span>상신일자</span>
          </v-tooltip>
          <v-tooltip v-if="completedTime" left>
            <template v-slot:activator="{ on, attrs }">
              <div
                v-on="on"
                v-bind="attrs"
                class="d-flex align-center  text-truncate"
              >
                <v-icon x-small class="mr-1">mdi-check-underline</v-icon>
                <span class="text-caption">{{ completedTime }}</span>
              </div>
            </template>
            <span>완료일자</span>
          </v-tooltip>
        </div>
      </template>
    </SectionTitle>
    <!-- 문서 기본 정보 -->
    <DocumentInfoSection v-bind="documentInfo" class="mb-12" />
    <!-- 결재선 -->
    <SectionTitle title="결재선">
      <!-- 결재선 관리 버튼 그룹-->
      <template v-slot:tail>
        <LineBtnGroup
          :document="document"
          :approval-lines="approvalLines"
          :form="form"
          @click:openTypeChange="openTypeDialog = true"
          @click:receiveChange="openLineSelectDialog('CHANGE_RECEIVE')"
          @click:shareChange="openLineSelectDialog('CHANGE_SHARE')"
          @click:upperLineChange="openLineSelectDialog('CHANGE_UPPER')"
        />
      </template>
    </SectionTitle>
    <ApprovalLineExpansionPanel :approval-lines="approvalLines" class="mb-12" />
    <!-- 기안 내용 -->
    <SectionTitle title="기안내용" />
    <!-- 문서 내용 -->
    <DocumentBasicSection
      :title="document.title"
      :files="document.files"
      :origin-doc="document.originDocPreview"
      :ref-docs="document.refDocs"
      class="mb-3"
    />
    <!-- 템플릿 내용 -->
    <TemplateMain
      v-if="templateFrames && templateFrames.length > 0"
      :value="document.templateBody"
      :templateFrames="templateFrames"
      class="mb-3"
    />
    <!-- 본문 -->
    <div v-html="content" class="mb-12 cr-document-content user-select-text" />
    <!-- 결재의견 / 수신이력 -->
    <SectionTitle title="결재이력" />
    <OpinionList
      ref="opinionList"
      class="mb-12"
      :opinions="opinions"
      :editable="document.status === 'PROGRESS'"
      @editComment="onEditComment"
    />
    <!-- 문서 또는 결재 관리 버튼 그룹 -->
    <div class="d-flex justify-center">
      <v-btn v-if="isPreviewPage" depressed @click="routeToHome">
        <v-icon small>mdi-home-outline</v-icon>
        <span class="ml-1">홈으로</span>
      </v-btn>
      <v-btn v-else depressed @click="routeToList">
        <v-icon small>mdi-arrow-left</v-icon>
        <span class="ml-1">목록</span>
      </v-btn>
      <v-btn class="ml-2" depressed @click="print">
        <v-icon small>mdi-printer-outline</v-icon>
        <span class="ml-1">인쇄</span>
      </v-btn>
      <v-spacer></v-spacer>
      <DocBtnGroup
        v-if="!invalid"
        :document="document"
        :approval-lines="approvalLines"
        :form="form"
        @click:reuse="onClickReuseBtn"
        @click:edit="onClickEditBtn"
        @click:retrieve="onClickRetrieveBtn"
        @click:delete="onClickDeleteBtn"
        @click:receipt="receiptDialog = true"
        @click:approve="approveDialog = true"
        @click:approveCancel="onClickCancelApproveBtn"
        @click:receive="receiveDialog = true"
        @click:return="returnDialog = true"
      />
    </div>
    <!-- 다이얼 로그 -->
    <OpenTypeChangeDialog
      v-model="openTypeDialog"
      :init-data="document.openType"
      @click:confirm="changeOpenType"
    />
    <ApprovalLineSelectDialog
      v-model="lineSelectDialog"
      :select-type="lineSelectDialogType"
      :approval-lines="approvalLines"
      :draftUser="draftUser"
      :draftOrgan="draftOrgan"
      persistent
      scrollable
      @submit="applySelectedData"
    />
    <ApproveDialog
      v-model="approveDialog"
      :approval-lines="approvalLines"
      :form="form"
      @click:confirm="postApprove"
    />
    <ReceiptDialog
      v-model="receiptDialog"
      :document="document"
      :approval-lines="approvalLines"
      @click:confirm="routeInnerDraft"
    />
    <ReceiveDialog
      v-model="receiveDialog"
      :document="document"
      :approval-lines="approvalLines"
      @click:confirm="receiveLine"
    />
    <ReturnDialog
      v-model="returnDialog"
      :document="document"
      :approval-lines="approvalLines"
      @click:confirm="returnLine"
    />
  </div>
</template>

<script>
import LoadingOverlay from "@/approval/views/components/common/LoadingOverlay";
import SectionTitle from "@/approval/views/components/common/SectionTitle";
import DocumentInfoSection from "@/approval/views/components/document/DocumentInfoSection";
import ApprovalLineExpansionPanel from "@/approval/views/components/approvalline/ApprovalLineExpansionPanel";
import DocumentBasicSection from "@/approval/views/components/document/DocumentBasicSection";
import TemplateMain from "@/approval/views/components/template/TemplateMain";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import OpinionList from "@/approval/views/components/document/OpinionList";
import ApproveDialog from "@/approval/views/components/dialog/ApproveDialog";
import { LINE_GROUP } from "@/approval/constant/approvalLine";
import ReceiptDialog from "@/approval/views/components/dialog/ReceiptDialog";
import ReceiveDialog from "@/approval/views/components/dialog/ReceiveDialog";
import ReturnDialog from "@/approval/views/components/dialog/ReturnDialog";
import ApprovalLineSelectDialog from "@/approval/views/components/dialog/ApprovalLineSelectDialog";
import OpenTypeChangeDialog from "@/approval/views/components/dialog/OpenTypeChangeDialog";
import { ApprovalLines } from "@/approval/utils/ApprovalLines";
import LineBtnGroup from "@/approval/views/components/document/LineBtnGroup";
import DocBtnGroup from "@/approval/views/components/document/DocBtnGroup";
import { getFullDate } from "@/commons/utils/moment";

export default {
  name: "DocumentViewPage",
  components: {
    DocBtnGroup,
    OpenTypeChangeDialog,
    ApprovalLineSelectDialog,
    ReturnDialog,
    ReceiveDialog,
    ReceiptDialog,
    ApproveDialog,
    OpinionList,
    TemplateMain,
    DocumentBasicSection,
    ApprovalLineExpansionPanel,
    DocumentInfoSection,
    SectionTitle,
    LoadingOverlay,
    LineBtnGroup
  },
  props: {
    docId: {
      required: true,
      description: "문서 아이디"
    },
    boxKey: {
      required: true,
      description: "불러올 양식함 key"
    }
  },
  data: () => ({
    lineSelectDialogType: "DEFAULT",
    lineSelectDialog: false,
    openTypeDialog: false,
    approveDialog: false,
    receiptDialog: false,
    receiveDialog: false,
    returnDialog: false
  }),
  computed: {
    ...mapState("approvalDocumentView", [
      "loading",
      "document",
      "approvalLines",
      "form",
      "opinions"
    ]),
    draftedTime() {
      return getFullDate(this.document.draftedTimeMillis);
    },
    completedTime() {
      const completedTimeMillis = this.document?.completedTimeMillis;
      return completedTimeMillis ? getFullDate(completedTimeMillis) : null;
    },
    ...mapGetters("auth", ["getUserInfo"]),
    isPreviewPage() {
      return this.$route.name === "approval_document_preview";
    },
    invalid() {
      return (
        ["DRAFT", "PROGRESS"].includes(this.document.status) &&
        this.document?.originDocPreview?.isDeleted
      );
    },
    invalidReason() {
      if (this.document?.originDocPreview?.isDeleted) {
        return "원본 문서가 폐기되어 해당 내부기안을 더이상 진행할 수 없습니다.";
      }
      return "문서가 유효하지 않습니다.";
    },
    isDraftUser() {
      return this.getUserInfo?.id === this.draftUser?.id;
    },
    documentInfo() {
      return {
        formName: this.document?.formName,
        docNum: this.document?.docNum,
        keepYear: this.document?.keepYear,
        openType: this.document?.openType,
        draftOrgan: this.document?.draftOrgan,
        draftUser: this.document?.draftUser
      };
    },
    templateFrames() {
      return this.document?.templateFrame?.fields ?? [];
    },
    content() {
      const regex = /@ACCESS_TOKEN@/g;
      let content = this.document.content;
      return content?.replace(regex, localStorage.getItem("access_token"));
    },
    draftUser() {
      return this.document?.draftUser;
    },
    draftOrgan() {
      return this.document?.draftOrgan;
    }
  },
  methods: {
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapActions("confirm", ["confirm"]),
    ...mapMutations("approvalDocumentView", ["RESET"]),
    ...mapActions("approvalDocumentView", [
      "init",
      "editComment",
      "retrieveDocument",
      "deleteDocument",
      "changeUpperLines",
      "addShareLines",
      "approveLine",
      "rejectLine",
      "cancelLine"
    ]),
    ...mapActions("approvalDocumentView", {
      changeOpenTypeAction: "changeOpenType",
      receiveLineAction: "receiveLine",
      returnLineAction: "returnLine"
    }),
    // 목록으로 이동
    routeToList() {
      this.$router.push({
        name: "approval_document_list",
        params: { boxKey: this.boxKey },
        query: this.$route.query
      });
    },
    // 홈으로 이동
    routeToHome() {
      this.$router.push({
        name: "approval_home"
      });
    },
    // 문서 인쇄
    print() {
      window.open(
        `/#/approval/doc/print/${this.docId}`,
        `approval-print`,
        "width=1050,height=870,location=no,directories=no,resizable=no,status=no,toolbar=yes,menubar=no,scrollbars=yes"
      );
    },
    // 내부 결재 상신 페이지로 이동
    routeInnerDraft(lineId) {
      this.$router.push({
        name: "approval_draft_inner",
        query: {
          originDocId: this.docId,
          originLineId: lineId
        }
      });
    },
    // URL 복사
    async copyURL() {
      if (this.document?.status !== "COMPLETE") return;
      const url = `${window.location.origin}/#/approval/doc/preview/${this.docId}`;
      await navigator.clipboard.writeText(url);
      await this.openSnackbar({
        type: "SUCCESS",
        message: "URL이 복사 되었습니다."
      });
    },
    // 결재선 선택 다이얼로그 열기
    openLineSelectDialog(selectType) {
      this.lineSelectDialogType = selectType;
      this.lineSelectDialog = true;
    },
    // [결재의견] 수정
    async onEditComment({ id, comment }) {
      const result = await this.editComment({ id, comment });
      if (result) {
        this.$refs.opinionList.closeDialog();
      }
    },
    // [재기안] 버튼 클릭
    onClickReuseBtn() {
      this.confirm({
        headline: "재기안",
        message:
          "현재 문서의 내용을 재사용 하여 <br/> 새로운 기안을 작성 하시겠습니까?",
        callback: () =>
          this.$router.push({
            name: "approval_draft_reuse",
            query: { docId: this.docId }
          })
      });
    },
    // [수정] 버튼 클릭
    onClickEditBtn() {
      this.confirm({
        headline: "기안 수정",
        message: "해당 문서의 내용을 수정하여 재상신 하시겠습니까?",
        callback: () =>
          this.$router.push({
            name: "approval_draft_edit",
            params: { ...this.$route.params },
            query: { ...this.$route.query }
          })
      });
    },
    // [회수] 버튼 클릭
    onClickRetrieveBtn() {
      this.confirm({
        headline: "기안 회수",
        message: "기안을 회수합니다.<br>계속 진행 하시겠습니까?",
        callback: async () => {
          const success = await this.retrieveDocument(this.docId);
          if (success) this.receiveDialog = false;
        }
      });
    },
    // [삭제] 버튼 클릭
    onClickDeleteBtn() {
      this.confirm({
        headline: "기안 삭제",
        message:
          "영구적으로 삭제되며 복원할 수 없습니다.<br>계속 진행 하시겠습니까?",
        callback: () => this.deleteDocument()
      });
    },
    // [결재취소] 버튼 클릭
    onClickCancelApproveBtn() {
      // 취소할 결재선
      const targetLine = new ApprovalLines(this.approvalLines)
        .filterByGroup(LINE_GROUP.APPROVAL)
        .filterByStatus("APPROVED")
        .sortAsc()
        .getLast();
      this.confirm({
        headline: "결재 취소",
        message: "결재를 취소합니다.<br>계속 진행 하시겠습니까?",
        callback: () => this.cancelLine(targetLine?.id)
      });
    },
    // [결재]: 승인, 전결, 반려, 전단계반려
    postApprove({ lineId, approveType, comment }) {
      switch (approveType) {
        case "approve":
          this.approveLine({ lineId, preApproval: false, comment });
          break;
        case "preApprove":
          this.approveLine({ lineId, preApproval: true, comment });
          break;
        case "reject":
          this.rejectLine({ lineId, preReject: false, comment });
          break;
        case "preReject":
          this.rejectLine({ lineId, preReject: true, comment });
          break;
      }
    },
    // [공개설정] 변경
    async changeOpenType(openType) {
      const success = await this.changeOpenTypeAction(openType);
      if (success) this.openTypeDialog = false;
    },
    // [수신확인]
    async receiveLine(lineId) {
      const success = await this.receiveLineAction(lineId);
      if (success) this.receiveDialog = false;
    },
    // [반송]
    async returnLine({ lineId, comment }) {
      const success = await this.returnLineAction({ lineId, comment });
      if (success) this.returnDialog = false;
    },
    // [상위 결재선], [공람자] 변경
    applySelectedData({ approvalLines = [] }) {
      const headline =
        this.lineSelectDialogType === "CHANGE_UPPER"
          ? "상위결재선 변경"
          : "공람자 추가";
      const message =
        "관련 사용자들에게 알림이 발송될 수 있으며 <br/> 이후 변경사항을 되돌릴 수 없습니다.";
      const callback = () => {
        let success = false;
        // 상위 결재선 변경
        if (this.lineSelectDialogType === "CHANGE_UPPER") {
          const nextApproveLine = new ApprovalLines(approvalLines)
            .filterByStatus("PENDING_APPROVAL")
            .getLast();
          const newUpperLines = new ApprovalLines(approvalLines)
            .filterByGroup(LINE_GROUP.APPROVAL)
            .filter(({ sortOrder }) => sortOrder > nextApproveLine?.sortOrder)
            .get();
          const payload = newUpperLines.map(this.convertApprovalLineToPayload);
          success = this.changeUpperLines(payload);
        }
        // 공람자 추가
        if (this.lineSelectDialogType === "CHANGE_SHARE") {
          const newShareLines = new ApprovalLines(approvalLines)
            .filterByGroup(LINE_GROUP.SHARE)
            .filter(({ id }) => !id)
            .get();
          const payload = newShareLines.map(this.convertApprovalLineToPayload);
          success = this.addShareLines(payload);
        }
        if (success) this.lineSelectDialog = false;
      };
      this.confirm({ headline, message, callback });
    },
    // 결재선 생성 DTO 형식으로 변환
    convertApprovalLineToPayload(line) {
      const { type, sortOrder, approver } = line;
      return {
        type,
        sortOrder,
        ...(approver.isUser
          ? { organId: approver.organId, userId: approver.userId }
          : { organId: approver.organId })
      };
    }
  },
  watch: {
    // 라우팅에 의해 docId가 변경될 경우
    docId(n) {
      this.init(n);
    }
  },
  async mounted() {
    await this.init(this.docId);
  },
  destroyed() {
    this.RESET();
  }
};
</script>

<style lang="scss" scoped>
.cr-document-content ::v-deep {
  overflow-x: hidden;
  padding: 16px;
  border: thin solid rgba(0, 0, 0, 0.08);
  img {
    max-width: 100%;
  }
}

.cr-top-banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 170px;
  width: 100%;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  // 상단 테두리 라운딩처리
  border-radius: 8px 8px 0 0;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12) !important;
}
</style>
