import { render, staticRenderFns } from "./ApproverCell.vue?vue&type=template&id=05b1bc86&scoped=true&"
import script from "./ApproverCell.vue?vue&type=script&lang=js&"
export * from "./ApproverCell.vue?vue&type=script&lang=js&"
import style0 from "./ApproverCell.vue?vue&type=style&index=0&id=05b1bc86&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05b1bc86",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VHover } from 'vuetify/lib/components/VHover';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VChip,VHover,VList,VListItem,VListItemTitle,VMenu,VTooltip})
