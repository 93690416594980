<template>
  <div>
    <v-list v-if="!isEmpty">
      <v-list-group
        v-for="box in formBoxList"
        :key="box.id"
        v-model="box.active"
        no-action
        color="white"
        :class="box.active ? 'primary cr-list-item-active' : ''"
      >
        <!-- ROOT : 양식함 목록 -->
        <template v-slot:activator>
          <div class="mr-auto">
            <span class="mr-2">{{ box.name }}</span>
            <span class="font-weight-bold">{{ box.count }}</span>
          </div>
        </template>

        <!-- CHILD: 양식 목록 -->
        <div class="pa-0 pa-sm-2 pa-md-4" style="background: whitesmoke">
          <FormList
            :formBox="box"
            @formSelected="form => $emit('formSelected', form)"
          />
        </div>
      </v-list-group>
    </v-list>
    <!-- 로딩 / no-item / 더보기 -->
    <div
      v-if="loading || isEmpty || !last"
      class="d-flex justify-center align-center"
      style="min-height: 50px"
    >
      <v-progress-circular
        v-if="loading"
        :width="3"
        color="primary"
        indeterminate
      />
      <span v-else-if="isEmpty" class="black--text pa-16">
        표시할 정보가 없음.
      </span>
      <v-btn v-else-if="!last" plain @click="loadNextPage">더보기</v-btn>
    </div>
  </div>
</template>

<script>
import FormList from "@/approval/views/components/form/FormList";
import { getFormBoxList } from "@/approval/api/approval.api";

export default {
  name: "FormSelector",
  components: { FormList },
  props: {},
  emits: ["formSelected"],
  data() {
    return {
      loading: false,
      formBoxList: [],
      page: 0,
      totalElements: 0,
      totalPages: 0,
      last: true
    };
  },
  methods: {
    // 양식함 목록 불러오기
    async loadFormBoxList() {
      this.loading = true;
      const { status, data } = await getFormBoxList(true, { page: this.page });
      if (status !== 200) {
        // 실패시
      }
      // 응답 결과
      const {
        content: list,
        number: page,
        totalElements,
        totalPages,
        last
      } = data;
      // 첫페이지일 경우 첫번째 양식함 오픈
      if (page === 0) list[0].active = true;
      // 응답 결과 바인딩
      this.formBoxList = [...this.formBoxList, ...list];
      this.page = page;
      this.totalElements = totalElements;
      this.totalPages = totalPages;
      this.last = last;
      this.loading = false;
    },
    // 다음 페이지 양식함 목록 불러오기
    loadNextPage() {
      if (this.last) return;
      this.page++;
      this.loadFormBoxList();
    }
  },
  computed: {
    isEmpty() {
      return !this.formBoxList || this.formBoxList.length < 1;
    }
  },
  async mounted() {
    await this.loadFormBoxList();
  },
  destroyed() {
    this.loading = false;
    this.formBoxList = [];
    this.page = 0;
    this.totalElements = 0;
    this.totalPages = 0;
    this.last = true;
  }
};
</script>

<style lang="scss" scoped>
.cr-list-item-active {
  //background: dimgrey;
}
</style>
