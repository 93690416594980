<template>
  <div class="d-flex align-center">
    <v-btn
      v-if="showOpenTypeChangeBtn"
      depressed
      color="primary"
      class="ml-2"
      @click="$emit('click:openTypeChange')"
    >
      공개 설정
    </v-btn>
    <v-btn
      v-if="showReceiveChangeBtn"
      depressed
      color="primary"
      class="ml-2"
      @click="$emit('click:receiveChange')"
    >
      수신 추가
    </v-btn>
    <v-btn
      v-if="showShareChangeBtn"
      depressed
      color="primary"
      class="ml-2"
      @click="$emit('click:shareChange')"
    >
      공람 추가
    </v-btn>
    <v-btn
      v-if="showUpperLineChangeBtn"
      depressed
      color="primary"
      class="ml-2"
      @click="$emit('click:upperLineChange')"
    >
      상위 결재선 변경
    </v-btn>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { ApprovalLines } from "@/approval/utils/ApprovalLines";
import { LINE_GROUP } from "@/approval/constant/approvalLine";

export default {
  emits: [
    "click:openTypeChange",
    "click:receiveChange",
    "click:shareChange",
    "click:upperLineChange"
  ],
  props: {
    document: {
      description: "문서"
    },
    approvalLines: {
      description: "문서의 전체 결재선"
    },
    form: {
      description: "기안 양식"
    }
  },
  computed: {
    ...mapState("auth", ["userInfo"]),
    ...mapGetters("approvalConfig", ["getManagedOrganIds"]),
    ...mapGetters("serviceConfig", ["getApprovalServiceConfig"]),
    showOpenTypeChangeBtn() {
      if (!this.draftUserId || !this.loginUserId) return false;
      return (
        this.draftUserId === this.loginUserId &&
        this.document?.status === "COMPLETE" &&
        this.form?.openTypeChangeable
      );
    },
    showReceiveChangeBtn() {
      return false;
    },
    showShareChangeBtn() {
      if (!this.draftUserId || !this.loginUserId) return false;
      if (this.document?.status !== "COMPLETE") return false;
      return (
        this.draftUserId === this.loginUserId ||
        this.approvalGroupLines.some(this.checkLineOwner)
      );
    },
    showUpperLineChangeBtn() {
      if (!this.getApprovalServiceConfig?.upperLineChangeUse) return false;
      if (!this.checkLineOwner(this.nextApproveLine)) return false;
      if (!["DRAFT", "PROGRESS"].includes(this.document?.status)) {
        return false;
      }
      // 최종 결재자일 경우 불가능
      const lastSortOrder = new ApprovalLines(this.approvalLines)
        .filterByGroup(LINE_GROUP.APPROVAL)
        .get()
        .reverse()[0]?.sortOrder;
      return this.nextApproveLine?.sortOrder < lastSortOrder;
    },
    draftUserId() {
      return this.document?.draftUser?.id;
    },
    loginUserId() {
      return this.userInfo?.id;
    },
    nextApproveLine() {
      return new ApprovalLines(this.approvalLines)
        .filterByGroup(LINE_GROUP.APPROVAL)
        .filterByStatus("PENDING_APPROVAL")
        .getLast();
    },
    approvalGroupLines() {
      return new ApprovalLines(this.approvalLines)
        .filterByGroup(LINE_GROUP.APPROVAL)
        .get();
    }
  },
  methods: {
    // 로그인 유저가 {line}의 결재권자 인지 체크
    checkLineOwner(line = {}) {
      if (!this.loginUserId) return false;
      if (line?.approver?.isUser) {
        return this.loginUserId === line.approver.userId;
      }
      if (line?.approver?.isOrgan) {
        return this.getManagedOrganIds.includes(line.approver.organId);
      }
      return false;
    }
  }
};
</script>

<style scoped></style>
