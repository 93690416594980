<template>
  <div class="cr-print-main">
    <!-- 문서 제목 -->
    <SectionTitle :title="document.title">
      <template v-slot:tail>
        <div>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <div
                v-on="on"
                v-bind="attrs"
                class="d-flex align-center text-truncate"
              >
                <v-icon x-small class="mr-1">mdi-pencil</v-icon>
                <span class="text-caption">{{ draftedTime }}</span>
              </div>
            </template>
            <span>상신일자</span>
          </v-tooltip>
          <v-tooltip v-if="completedTime" left>
            <template v-slot:activator="{ on, attrs }">
              <div
                v-on="on"
                v-bind="attrs"
                class="d-flex align-center  text-truncate"
              >
                <v-icon x-small class="mr-1">mdi-check-underline</v-icon>
                <span class="text-caption">{{ completedTime }}</span>
              </div>
            </template>
            <span>완료일자</span>
          </v-tooltip>
        </div>
      </template>
    </SectionTitle>
    <!-- 문서 기본 정보 -->
    <DocumentInfoSection v-bind="documentInfo" class="mb-12" />
    <!-- 결재선 -->
    <SectionTitle title="결재선" />
    <ApprovalLineExpansionPanel
      :approval-lines="approvalLines"
      class="mb-12"
      open-all
      readonly
    />
    <!-- 기안 내용 -->
    <SectionTitle title="기안내용" />
    <!-- 문서 내용 -->
    <DocumentBasicSection
      :title="document.title"
      :files="document.files"
      :origin-doc="document.originDocPreview"
      :ref-docs="document.refDocs"
      class="mb-3"
    />
    <!-- 템플릿 내용 -->
    <TemplateMain
      v-if="templateFrames && templateFrames.length > 0"
      :value="document.templateBody"
      :templateFrames="templateFrames"
      class="mb-3"
    />
    <!-- 본문 -->
    <div
      ref="content"
      v-html="content"
      class="mb-12 cr-document-content user-select-text"
    ></div>
    <!-- 결재의견 / 수신이력 -->
    <SectionTitle title="결재이력" />
    <OpinionList
      ref="opinionList"
      class="mb-4"
      :opinions="opinions"
      :tabs="['APPROVAL']"
    />
    <OpinionList
      ref="opinionList"
      class="mb-0"
      :opinions="opinions"
      :tabs="['RECEIVE']"
    />
  </div>
</template>

<script>
import OpinionList from "@/approval/views/components/document/OpinionList";
import SectionTitle from "@/approval/views/components/common/SectionTitle";
import DocumentInfoSection from "@/approval/views/components/document/DocumentInfoSection";
import TemplateMain from "@/approval/views/components/template/TemplateMain";
import DocumentBasicSection from "@/approval/views/components/document/DocumentBasicSection";
import ApprovalLineExpansionPanel from "@/approval/views/components/approvalline/ApprovalLineExpansionPanel";
import { mapActions, mapState } from "vuex";
import { getFullDate } from "@/commons/utils/moment";
export default {
  components: {
    ApprovalLineExpansionPanel,
    DocumentBasicSection,
    TemplateMain,
    DocumentInfoSection,
    SectionTitle,
    OpinionList
  },
  props: {
    docId: {
      description: "문서 아이디"
    }
  },
  computed: {
    ...mapState("approvalDocumentView", [
      "document",
      "approvalLines",
      "form",
      "opinions"
    ]),
    draftedTime() {
      return getFullDate(this.document.draftedTimeMillis);
    },
    completedTime() {
      return this.document.status === "COMPLETE"
        ? getFullDate(this.document?.updatedTimeMillis)
        : undefined;
    },
    documentInfo() {
      return {
        formName: this.document?.formName,
        docNum: this.document?.docNum,
        keepYear: this.document?.keepYear,
        openType: this.document?.openType,
        draftOrgan: this.document?.draftOrgan,
        draftUser: this.document?.draftUser
      };
    },
    templateFrames() {
      return this.document?.templateFrame?.fields ?? [];
    },
    content() {
      const regex = /@ACCESS_TOKEN@/g;
      let content = this.document.content;
      return content?.replace(regex, localStorage.getItem("access_token"));
    },
    draftUser() {
      return this.document?.draftUser;
    },
    draftOrgan() {
      return this.document?.draftOrgan;
    }
  },
  methods: {
    ...mapActions("splash", ["setShowSplash"]),
    ...mapActions("approvalDocumentView", ["init"]),
    async delay(millis = 0) {
      return new Promise(resolve => setTimeout(resolve, millis));
    },
    async waitImgLoad() {
      return new Promise(resolve => {
        const content = this.$refs.content;
        const imgs = content.querySelectorAll("img");
        if (!imgs.length) resolve(); // 이미지가 없으면 즉시 종료

        // 이미지 로딩된 카운트
        let totalCount = imgs.length;
        let loadedCount = 0;

        // 이미지에 이벤트 핸들러 등록
        const finishHandler = () => {
          loadedCount++;
          if (loadedCount === totalCount) resolve(); // 모든 이미지 로딩 완료시
        };
        for (const img of imgs) {
          // 로딩이 완료된 이미지일 경우 핸들러 부착 건너뛰기
          if (img.complete) {
            loadedCount++;
            if (totalCount === loadedCount) resolve(); // 모든 이미지 로딩 완료시
          } else {
            img.onload = finishHandler;
            img.onerror = finishHandler;
          }
        }

        // 일정 시간 초 이상 로딩 실패시 처리
        const timeoutSecond = 30;
        setTimeout(() => {
          if (totalCount === loadedCount) return;
          imgs.forEach(img => {
            img.onload = null;
            img.onerror = null;
          });
          resolve();
        }, timeoutSecond * 1000);
      });
    }
  },
  mounted() {
    this.setShowSplash(false);
    this.$nextTick(async () => {
      await this.init(this.docId);
      await this.waitImgLoad();
      await this.delay(500);
      window.print();
    });
  },
  watch: {
    async docId() {
      await this.init(this.docId);
      await this.waitImgLoad();
      await this.delay(500);
      window.print();
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-print-main {
  padding: 32px;
  .cr-document-content ::v-deep {
    overflow-x: hidden;
    padding: 16px;
    border: thin solid rgba(0, 0, 0, 0.08);
    img {
      max-width: 100%;
    }
  }
}
/* 인쇄시 스타일 */
@media print {
  /* 배율 조정 */
  .cr-print-main {
    padding: 0;
    zoom: 0.7;
  }
}
</style>
