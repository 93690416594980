<template>
  <div class="d-flex flex-column">
    <!-- 탭 헤더 -->
    <v-tabs
      v-model="activeTabIndex"
      class="mb-4"
      style="border-bottom: thin solid rgba(0, 0, 0, 0.08)"
    >
      <v-tab v-for="tab in tabs" :key="tab">
        <span class="mr-1">{{ getTabName(tab) }}</span>
      </v-tab>
    </v-tabs>
    <!-- 결재이력 목록 -->
    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="-1"
      :mobile-breakpoint="-1"
      disable-sort
      hide-default-footer
      class="cr-approval-opinion-table"
      @click:row="item => $emit('click:row', item)"
    >
      <!-- 결재 구분 -->
      <template v-slot:item.type="{ value }">
        <span>{{ getTypeName(value) }}</span>
      </template>
      <!-- 결재자 -->
      <template v-slot:item.approver="{ value }">
        <v-tooltip :disabled="value.isOrgan" bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-on="on" v-bind="attrs">
              {{ value.isUser ? value.userName : value.organName }}
            </span>
          </template>
          <span>{{ value.userEmail }}</span>
          <span> | </span>
          <span>{{ value.organName }}</span>
        </v-tooltip>
      </template>
      <!-- 결재 상태 -->
      <template v-slot:item.status="{ value }">
        <span :style="getStatusStyle(value)">
          {{ getStatusName(value) }}
        </span>
      </template>
      <!-- 일시 -->
      <template v-slot:item.createdTimeMillis="{ value }">
        <span>{{ convertDateFormat(value) }}</span>
      </template>
      <!-- 액션 버튼 -->
      <template v-slot:item.actions="{ item, index }">
        <v-icon
          small
          color="accent"
          :disabled="index !== 0 || !hasEditPermission(item)"
          @click="openEditDialog(item)"
        >
          mdi-pencil
        </v-icon>
      </template>
    </v-data-table>
    <!-- 결재의견 수정 다이얼로그 -->
    <OpinionEditDialog
      v-if="showEditDialog"
      v-model="showEditDialog"
      :opinion-id="editOpinionId"
      :opinion-comment="editOpinionComment"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import { getFullDate } from "@/commons/utils/moment";
import { mapGetters } from "vuex";
import OpinionEditDialog from "@/approval/views/components/dialog/OpinionEditDialog";
import { LINE_TYPE } from "@/approval/constant/approvalLine";

export default {
  components: { OpinionEditDialog },
  emits: ["editComment"],
  props: {
    tabs: {
      type: Array,
      default: () => ["APPROVAL", "RECEIVE"],
      description: "표시할 탭 목록"
    },
    opinions: {
      type: Array,
      default: () => [],
      description: "결재의견 목록"
    },
    editable: {
      type: Boolean,
      default: false,
      description: "편집 모드"
    }
  },
  data: () => ({
    activeTabIndex: 0,
    showEditDialog: false,
    // 수정중인 결재의견
    editOpinionId: null,
    editOpinionComment: ""
  }),
  computed: {
    ...mapGetters("auth", ["getUserInfo"]),
    ...mapGetters("approvalConfig", ["getManagedOrganIds"]),
    headers() {
      let headers = [
        {
          text: "결재구분",
          value: "type",
          align: "center",
          class: "text-truncate",
          cellClass: "text-truncate"
        },
        {
          text: "결재자",
          value: "approver",
          align: "center",
          class: "text-truncate",
          cellClass: "text-truncate"
        },
        {
          text: "결재상태",
          value: "status",
          align: "center",
          class: "text-truncate",
          cellClass: "text-truncate"
        },
        {
          text: "일시",
          value: "createdTimeMillis",
          align: "center",
          class: "text-truncate",
          cellClass: "text-truncate"
        },
        {
          text: "결재의견",
          value: "comment",
          width: "100%",
          class: "text-truncate"
        }
      ];
      if (this.activeTab === "APPROVAL" && this.editable) {
        headers = [
          ...headers,
          {
            text: "관리",
            value: "actions",
            align: "center"
          }
        ];
      }
      return headers;
    },
    items() {
      let typeFilters = [];
      switch (this.activeTab) {
        case "APPROVAL":
          typeFilters = ["APPROVAL", "HELP", "HELP_ORGAN"];
          break;
        case "RECEIVE":
          typeFilters = ["RECEIVE", "RECEIVE_ORGAN"];
          break;
      }
      return this.opinions.filter(({ type }) => typeFilters.includes(type));
    },
    activeTab() {
      return this.tabs[this.activeTabIndex];
    }
  },
  methods: {
    getTabName(tab) {
      switch (tab) {
        case "APPROVAL":
          return "결재의견";
        case "RECEIVE":
          return "수신이력";
        default:
          return "";
      }
    },
    getStatusName(status) {
      switch (status) {
        case "APPROVED":
          return "승인";
        case "AUTO_APPROVED":
          return "자동승인";
        case "PRE_APPROVED":
          return "전결";
        case "REJECTED":
          return "반려";
        case "PRE_REJECTED":
          return "전단계 반려";
        case "CANCELED":
          return "결재취소";
        case "CHANGE_UPPER":
          return "상위 결재선 변경";
        case "RECEIVED":
          return "수신";
        case "RETURNED":
          return "반송";
        case "RECEIPT":
          return "접수";
        case "RECEIPT_CANCELED":
          return "접수취소";
        default:
          return "-";
      }
    },
    getStatusStyle(status) {
      switch (status) {
        case "APPROVED":
        case "AUTO_APPROVED":
        case "PRE_APPROVED":
        case "RECEIVED":
          return "color: #37f";
        case "RETURNED":
        case "REJECTED":
        case "PRE_REJECTED":
        case "CANCELED":
          return "color: #ff8080";
        default:
          return "";
      }
    },
    getTypeName(type) {
      switch (type) {
        case "APPROVAL":
          return "결재";
        case "HELP":
          return "협조";
        case "HELP_ORGAN":
          return "부서 협조";
        case "RECEIVE":
          return "수신";
        case "RECEIVE_ORGAN":
          return "부서 수신";
        default:
          return "-";
      }
    },
    hasEditPermission(opinion = {}) {
      const { type, approver = {} } = opinion;
      // 수정 가능한 결재 유형인지 체크
      const editableTypes = [
        LINE_TYPE.APPROVAL,
        LINE_TYPE.HELP,
        LINE_TYPE.HELP_ORGAN
      ];
      if (!editableTypes.includes(type)) return false;
      // 결재자 권한이 존재하는지 체크
      if (approver.isUser) {
        return approver.userId === this.getUserInfo.id;
      } else {
        return this.getManagedOrganIds.includes(approver.organId);
      }
    },
    convertDateFormat(timeMillis) {
      return getFullDate(timeMillis);
    },
    openEditDialog(opinion) {
      this.editOpinionId = opinion.id;
      this.editOpinionComment = opinion.comment;
      this.showEditDialog = true;
    },
    closeDialog() {
      this.showEditDialog = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.v-data-table.cr-approval-opinion-table::v-deep {
  > .v-data-table__wrapper {
    > table {
      // 테이블 위아래 border
      border-top: thin solid rgba(0, 0, 0, 0.12);
      border-bottom: thin solid rgba(0, 0, 0, 0.12);
      // 헤더 색상
      > thead > tr {
        color: #666;
        background-color: #fafafa;
      }

      // 테이블 격자 border
      > thead > tr,
      > tbody > tr {
        border-bottom: thin solid rgba(0, 0, 0, 0.12);

        th:not(:last-child),
        td:not(:last-child) {
          border-right: thin solid rgba(0, 0, 0, 0.12);
        }
      }
    }
  }
}
</style>
