var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-treeview',{attrs:{"item-key":"key","dense":"","activatable":"","hoverable":"","transition":"","return-object":"","active":_vm.activeItem,"items":_vm.organTreeItems,"load-children":function (parentNode) { return _vm.loadOrganTreeChildren(parentNode); }},on:{"update:active":function($event){_vm.activeItem=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(item.type === "USER" ? "mdi-account" : "mdi-graph")+" ")])]}},{key:"label",fn:function(ref){
var item = ref.item;
return [(item.type === 'USER')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-inline-block py-2"},'div',attrs,false),on),[_c('span',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('span',{staticClass:"text-caption grey--text"},[_vm._v(" "+_vm._s(item.jobGrade)+" ")])])]}}],null,true)},[_vm._v(" "+_vm._s(((item.email) + " | " + (item.organ.name)))+" ")]):_c('span',[_vm._v(_vm._s(item.name))])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }