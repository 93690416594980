<template>
  <div class="cr-info-main">
    <v-row no-gutters style="border-left: thin solid rgba(0, 0, 0, 0.08)">
      <!-- 기안양식 -->
      <v-col cols="12" md="6" class="cr-option-wrap">
        <div class="fill-height d-flex align-center">
          <div class="cr-option-title">기안양식</div>
          <div class="cr-option-content">{{ formName }}</div>
        </div>
      </v-col>
      <!-- 문서번호 -->
      <v-col cols="12" md="6" class="cr-option-wrap">
        <div class="fill-height d-flex align-center">
          <div class="cr-option-title">문서번호</div>
          <div class="cr-option-content">
            <span>{{ docNum }}</span>
            <v-btn
              v-if="docNum"
              icon
              x-small
              class="ml-1"
              @click="copyToClipboard(docNum)"
            >
              <v-icon>mdi-content-copy</v-icon>
            </v-btn>
          </div>
        </div>
      </v-col>
      <!-- 보존연한 -->
      <v-col cols="12" md="6" class="cr-option-wrap">
        <div class="fill-height d-flex align-center">
          <div class="cr-option-title">보존연한</div>
          <div class="cr-option-content">
            {{ convertKeepYearToText(keepYear) }}
          </div>
        </div>
      </v-col>
      <!-- 공개여부 (편집 모드 지원) -->
      <v-col cols="12" md="6" class="cr-option-wrap">
        <div class="fill-height d-flex align-center">
          <div class="cr-option-title">공개여부</div>
          <div v-if="editable && openTypeChangeable" class="cr-option-content">
            <v-select
              :value="openType"
              :items="openTypeItems"
              :error-messages="openTypeError"
              dense
              outlined
              hide-details
              item-text="name"
              item-value="value"
              style="width: 100%; max-width: 250px;"
              @input="val => $emit('update:openType', val)"
            />
          </div>
          <div v-else class="cr-option-content">
            {{ getNameByOpenType(openType) }}
          </div>
        </div>
      </v-col>
      <!-- 기안자 -->
      <v-col cols="12" md="6" class="cr-option-wrap">
        <div class="fill-height d-flex align-center">
          <div class="cr-option-title">기안자</div>
          <div class="cr-option-content">
            {{ convertDraftUserToText(draftUser) }}
          </div>
        </div>
      </v-col>
      <!-- 기안부서 (편집 모드 지원) -->
      <v-col cols="12" md="6" class="cr-option-wrap">
        <div class="fill-height d-flex align-center">
          <div class="cr-option-title">기안부서</div>
          <div v-if="editable && !fixedDraftOrgan" class="cr-option-content">
            <v-select
              :value="draftOrgan"
              :items="userOrgans"
              :error-messages="draftOrganError"
              dense
              outlined
              hide-details
              return-object
              item-text="name"
              item-value="organId"
              placeholder="기안부서 선택"
              style="width: 100%; max-width: 250px;"
              @input="val => $emit('update:draftOrgan', val)"
            />
          </div>
          <div v-else-if="draftOrgan" class="cr-option-content">
            {{ draftOrgan.name }}
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import KEEP_YEAR from "@/approval/constant/keepYear";
import OPEN_TYPE from "@/approval/constant/openType";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
  emits: ["update:openType", "update:draftOrgan"],
  setup: () => ({ v$: useVuelidate() }),
  props: {
    formName: {
      default: "-",
      description: "양식명"
    },
    docNum: {
      default: "-",
      description: "문서번호"
    },
    keepYear: {
      default: "-",
      description: "보존연한"
    },
    openType: {
      default: "-",
      description: "공개유형"
    },
    draftUser: {
      default: () => {},
      description: "기안자"
    },
    draftOrgan: {
      default: () => {},
      description: "기안 부서"
    },
    fixedDraftOrgan: {
      type: Boolean,
      default: false,
      description: "기안부서 고정 여부"
    },
    editable: {
      type: Boolean,
      default: false,
      description: "편집모드 여부"
    },
    openTypeChangeable: {
      type: Boolean,
      default: false,
      description: "공개유형 편집 가능 여부"
    }
  },
  computed: {
    ...mapGetters("auth", ["getUserInfo"]),
    // 사용자의 부서 목록 (Select Option)
    userOrgans() {
      const organList = this.getUserInfo?.organRelationList ?? [];
      return organList.map(({ organId, organizationName: name }) => ({
        organId,
        name
      }));
    },
    // 공개유형 목록 (Select Option)
    openTypeItems() {
      return Object.keys(OPEN_TYPE).map(openType => ({
        value: openType,
        name: this.getNameByOpenType(openType)
      }));
    },
    openTypeError() {
      const { $dirty, required } = this.v$.openType;
      const errors = [];
      if (!$dirty) return errors;
      if (required.$invalid) errors.push("필수 입력사항 입니다.");
      return errors;
    },
    draftOrganError() {
      const { $dirty, required, includeUserOrgans } = this.v$.draftOrgan;
      const errors = [];
      if (!$dirty) return errors;
      if (required.$invalid) errors.push("필수 입력사항 입니다.");
      if (includeUserOrgans.$invalid) {
        errors.push("본인의 소속 부서를 선택해주세요.");
      }
      return errors;
    }
  },
  methods: {
    ...mapActions("snackbar", ["openSnackbar"]),
    async validate() {
      return await this.v$.$validate();
    },
    // 공개여부 타입을 문자열로 변환
    getNameByOpenType(openType) {
      switch (openType) {
        case "PUBLIC":
          return "공개";
        case "PRIVATE":
          return "비공개";
        case "ORGAN":
          return "부서공개";
        default:
          return "";
      }
    },
    convertKeepYearToText(keepYear) {
      if (keepYear === "UNLIMITED") return "무제한";
      return `${KEEP_YEAR[keepYear]}년`;
    },
    convertDraftUserToText(draftUser = {}) {
      const { name, email } = draftUser;
      return `${name}(${email})`;
    },
    async copyToClipboard(text) {
      await navigator.clipboard.writeText(text);
      this.openSnackbar({
        type: "SUCCESS",
        message: "문서번호가 복사 되었습니다."
      });
    }
  },
  validations() {
    // utils
    const isUsersOrgan = organ => {
      return this.userOrgans.some(({ organId }) => organId === organ.organId);
    };
    // validations
    return {
      openType: { required },
      draftOrgan: {
        required: val => val?.organId || false,
        includeUserOrgans: val => isUsersOrgan(val)
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.cr-info-main {
  border-top: thin solid rgba(0, 0, 0, 0.08);

  .cr-option-wrap {
    border-bottom: thin solid rgba(0, 0, 0, 0.08);
    border-right: thin solid rgba(0, 0, 0, 0.08);
    .cr-option-title {
      height: 50px;
      width: 120px;
      min-width: 120px;
      line-height: 50px;
      padding-left: 16px;
      background-color: #fafafa;
    }
    .cr-option-content {
      width: 100%;
      padding: 0 16px;
    }
  }
}
/* 인쇄시 스타일 */
@media print {
  /* A4 기준 595px로 너비가 작아 반응형 작동하지 않아 강제 조정 */
  .cr-option-wrap {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
</style>
