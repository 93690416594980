/**
 * theme 색상을 hex 값으로 변경
 * @param color theme color [EX] primary, accent, error
 * @returns {*} hex color [EX] #ff8080
 */
import vuetify from "@/commons/plugins/vuetify";

export function themeColorToHex(color) {
  if (color.startsWith("#")) return this.color;
  return vuetify.framework.theme.isDark
    ? vuetify.framework.theme.themes.dark[color]
    : vuetify.framework.theme.themes.light[color];
}

/**
 * theme 색상을 rgba 값으로 변경
 * @param color theme color [EX] primary, accent, error
 * @param opacity opacity [EX] 0.7
 * @returns {*} rgba color [EX] rgba(255, 255, 255, 0.7)
 */
export function themeColorToRGBA(color, opacity) {
  const hex = themeColorToHex(color);
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

/**
 * hex 색상을 rgba 값으로 변경
 * @param color hex color [EX] #ff8080
 * @param opacity opacity [EX] 0.7
 * @returns {*} rgba color [EX] rgba(255, 255, 255, 0.7)
 */
export function hexColorToRGBA(color, opacity) {
  const r = parseInt(color.slice(1, 3), 16);
  const g = parseInt(color.slice(3, 5), 16);
  const b = parseInt(color.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}
