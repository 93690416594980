<template>
  <v-container
    class="pa-0 py-15 px-15"
    style="width: 1320px; max-width: 1320px;"
  >
    <!-- 문서함 이름 -->
    <v-row no-gutters class="d-flex align-center pb-2">
      <span class="text-h5 font-weight-bold mr-2">{{ boxName }}</span>
      <!-- 도움말 -->
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on" class="text-truncate">
            <v-icon>mdi-help-circle-outline</v-icon>
          </span>
        </template>
        <span v-html="boxDescription"></span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <!-- 문서 담당 부서 보기 -->
      <v-menu v-if="boxKey === 'oh' || boxKey === 'or'" offset-y rounded="0">
        <template v-slot:activator="{ on, attrs }">
          <v-btn depressed v-bind="attrs" v-on="on">
            문서 담당 부서
          </v-btn>
        </template>
        <v-list v-if="managedOrgans.length > 0">
          <v-list-item v-for="(item, index) in managedOrgans" :key="index">
            <v-list-item-title>{{ item.organizationName }}</v-list-item-title>
          </v-list-item>
        </v-list>
        <v-list v-else>
          <v-list-item class="text-caption">
            문서담당부서가 없습니다.
          </v-list-item>
        </v-list>
      </v-menu>
      <!-- 문서대장 내보내기 -->
      <v-tooltip v-if="boxKey === 'ledger'" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-on="on"
            v-bind="attrs"
            elevation="0"
            tile
            @click="showExportDialog = true"
          >
            <v-icon small color="green">mdi-file-excel</v-icon>
            <span>내보내기</span>
          </v-btn>
        </template>
        현재 검색조건의 목록을 xlsx 파일로 출력합니다.
      </v-tooltip>
    </v-row>
    <div class="d-flex flex-column">
      <!-- 검색 조건 패널-->
      <DocumentSearchDetail
        :headers="headers"
        :draft-from-date.sync="searchDetail.draftFromDate"
        :draft-to-date.sync="searchDetail.draftToDate"
        :form-id.sync="searchDetail.formId"
        :keyword-flag.sync="searchDetail.keywordFlag"
        :keyword.sync="searchDetail.keyword"
        class="mb-4 cr-search-panel"
        @submit="search"
      />
      <!-- 검색 버튼 -->
      <v-row no-gutters class="d-flex align-center justify-center mb-2">
        <v-btn depressed color="primary" class="px-6" @click="search">
          조회
        </v-btn>
      </v-row>
      <!-- 상단 메뉴 (총 갯수, limit) -->
      <div class="d-flex align-center mb-2">
        <p class="ma-0">
          <span>총 </span>
          <span class="font-weight-bold">{{ getTotalElements }}</span>
          <span>건</span>
        </p>
        <v-spacer></v-spacer>
        <v-select
          v-model="limit"
          :items="limits"
          dense
          outlined
          hide-details
          style="max-width: 80px"
        ></v-select>
      </div>
      <!-- 문서 목록 -->
      <DocumentList
        :headers="headers"
        :items="getDocumentItems"
        :sort="pageReq['sort']"
        :dir="pageReq['dir']"
        :loading="isLoading"
        @click:title="onClickTitle"
        @update:options="onUpdateOptions"
        @search:form="searchByForm"
        @search:draftUser="searchByDraftUser"
        @search:draftOrgan="searchByDraftOrgan"
      />
      <Pagination
        v-if="getTotalElements > 0"
        type="list"
        :total-elements="getTotalElements"
        :page="computedPage"
        :page-size="size"
        :block-size="5"
        class="mt-2"
        @pageClick="pageClick"
      />
    </div>
    <DocumentListExportDialog
      v-if="boxKey === 'ledger'"
      v-model="showExportDialog"
      :search-detail="{
        draftFromDate,
        draftToDate,
        formId,
        keywordFlag,
        keyword
      }"
      :sort="sort"
      :dir="dir"
    />
  </v-container>
</template>

<script>
import { getBoxByKey } from "@/approval/constant/box";
import { mapActions, mapGetters, mapState } from "vuex";
import DocumentList from "@/approval/views/components/document/DocumentList";
import Pagination from "@/commons/views/Pagination";
import B0X_HEADERS from "@/approval/constant/boxHeaders";
import DocumentSearchDetail from "@/approval/views/components/document/DocumentSearchDetail";
import DocumentListExportDialog from "@/approval/views/components/dialog/DocumentListExportDialog";

export default {
  components: {
    DocumentListExportDialog,
    DocumentSearchDetail,
    Pagination,
    DocumentList
  },
  props: {
    boxKey: {
      required: true,
      description: "불러올 문서함 key"
    },
    page: {
      default: 0,
      description: "페이지 번호"
    },
    size: {
      default: 10,
      description: "페이지당 아이템 갯수"
    },
    sort: {
      description: "정렬할 컬럼"
    },
    dir: {
      description: "정렬 방향 (ASC/DESC)"
    },
    draftFromDate: {
      description: "[상세검색] 상신일자(시작일)"
    },
    draftToDate: {
      description: "[상세검색] 상신일자(종료일)"
    },
    formId: {
      description: "[상세검색] 기안 양식"
    },
    keywordFlag: {
      description: "키워드 검색 유형"
    },
    keyword: {
      type: Object,
      description: "키워드 검색 유형"
    }
  },
  data: () => ({
    limits: [10, 20, 30, 40, 50],
    showExportDialog: false,
    searchDetail: {}
  }),
  computed: {
    ...mapState("approvalConfig", ["basicConfig", "managerConfig"]),
    ...mapGetters("approvalDocumentList", [
      "isLoading",
      "getDocumentItems",
      "getTotalElements"
    ]),
    // 현재 선택된 양식함
    boxItem() {
      return getBoxByKey(this.boxKey);
    },
    // 페이지 + 1 보정된 값
    computedPage() {
      return this.page + 1;
    },
    // 목록당 아이템 갯수
    limit: {
      get() {
        return this.size;
      },
      set(val) {
        if (this.size === val) return;
        if (!this.limits.includes(val)) return;
        // 로드 크기를 변경한 경우 사용자 설정에 반영
        this.updateBasicConfig({ pageSize: val });
        // 변경한 pageSize 적용하여 라우팅 갱신
        this.$router.push({
          name: "approval_document_list",
          params: { ...this.$route.params },
          query: { ...this.$route.query, size: val, page: 0 }
        });
      }
    },
    // 문서 목록 테이블 헤더
    headers() {
      const path = this.boxItem?.path;
      return B0X_HEADERS[path] ?? [];
    },
    // 양식함 이름
    boxName() {
      return this.boxItem?.name ?? "";
    },
    // 양식함 설명
    boxDescription() {
      return this.boxItem?.description ?? "";
    },
    pageReq() {
      return {
        page: this.page,
        size: this.size,
        sort:
          this.sort ??
          this.headers?.find(({ isDefaultSort }) => isDefaultSort)?.value ??
          "draftedTimeMillis",
        dir: this.dir ?? "DESC"
      };
    },
    managedOrgans() {
      return this.managerConfig?.managedOrgans ?? [];
    }
  },
  methods: {
    ...mapActions("approvalDocumentList", ["init"]),
    ...mapActions("approvalConfig", ["updateBasicConfig"]),
    async initPage() {
      this.searchDetail = {
        draftFromDate: this.draftFromDate,
        draftToDate: this.draftToDate,
        formId: this.formId,
        keywordFlag: this.keywordFlag || "TITLE",
        keyword: this.keyword
      };
      await this.init(this.$props);
    },
    pageClick(page) {
      const routePage = page - 1;
      this.$router.push({
        name: "approval_document_list",
        params: { ...this.$route.params },
        query: { ...this.$route.query, page: routePage }
      });
    },
    onClickTitle({ id }) {
      // 임시저장 목록일 경우 수정페이지로 라우팅
      if (this.boxKey === "dt") {
        this.$router.push({
          name: "approval_draft_edit",
          params: { ...this.$route.params, docId: id },
          query: { ...this.$route.query }
        });
        return;
      }
      this.$router.push({
        name: "approval_document_view",
        params: { docId: id, boxKey: this.boxKey },
        query: this.$route.query
      });
    },
    search() {
      const { keyword, keywordFlag, ...searchDetail } = this.searchDetail;
      const query = {
        ...searchDetail,
        ...keyword,
        ...(keyword ? { keywordFlag } : {}),
        page: 0
      };
      // 빈속성은 쿼리에서 삭제
      Object.keys(query).forEach(key => {
        if (query[key] === undefined || query[key] === null) {
          delete query[key];
        }
      });
      this.$router.push({
        name: "approval_document_list",
        params: { ...this.$route.params },
        query
      });
    },
    searchByForm(formId) {
      this.$router.push({
        name: "approval_document_list",
        params: { ...this.$route.params },
        query: { formId, page: 0 }
      });
    },
    searchByDraftUser(user) {
      const { userId, userName, userEmail } = user;
      this.$router.push({
        name: "approval_document_list",
        params: { ...this.$route.params },
        query: {
          keywordFlag: "DRAFT_USER_ID",
          userId,
          name: userName,
          email: userEmail,
          page: 0
        }
      });
    },
    searchByDraftOrgan(organ) {
      const { organId, organName, organEmail } = organ;
      this.$router.push({
        name: "approval_document_list",
        params: { ...this.$route.params },
        query: {
          keywordFlag: "DRAFT_ORGAN_ID",
          organId: organId,
          name: organName,
          email: organEmail,
          page: 0
        }
      });
    },
    onUpdateOptions({ sortBy, sortDesc }) {
      // 기존 sort/dir 옵션과 동일할 경우 skip
      if (
        this.pageReq?.sort === sortBy &&
        (this.pageReq?.dir === "DESC") === sortDesc
      ) {
        return;
      }
      this.$router.push({
        name: "approval_document_list",
        params: { ...this.$route.params },
        query: {
          ...this.$route.query,
          page: 0,
          sort: sortBy,
          dir: sortDesc ? "DESC" : "ASC"
        }
      });
    }
  },
  async mounted() {
    await this.initPage();
  },
  watch: {
    // 라우팅 변경 감지하여 페이지 렌더링
    $props: {
      handler() {
        this.initPage();
      },
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-search-panel {
  padding: 16px 0;
  border-top: thin solid rgba(0, 0, 0, 0.12);
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
</style>
