<template>
  <div class="py-4 py-md-15">
    <!-- 제목 -->
    <SectionTitle title="기안 양식 선택" />
    <!-- 양식함 목록 -->
    <FormSelector @formSelected="formSelected" />
  </div>
</template>

<script>
import SectionTitle from "@/approval/views/components/common/SectionTitle";
import FormSelector from "@/approval/views/components/selector/FormSelector";

export default {
  name: "FormSelectPage",
  components: { FormSelector, SectionTitle },
  data() {
    return {};
  },
  methods: {
    // 폼 선택 완료시
    formSelected(form) {
      this.$router.push({
        name: "approval_draft",
        params: { formId: form.id }
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
