<template>
  <div style="height: 100%">
    <!-- 탭 헤더-->
    <v-tabs v-model="activeTab">
      <v-tab
        v-for="tab in tabs"
        :key="tab.routeName"
        @click="() => clickTab(tab.routeName)"
      >
        {{ tab.name }}
      </v-tab>
    </v-tabs>
    <!-- 탭 아이템 -->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  props: {},
  components: {},
  data: () => ({
    activeTab: 0,
    tabs: [
      {
        routeName: "approval_config_preset",
        name: "내 결재선"
      }
    ]
  }),
  methods: {
    // 탭 클릭시 라우팅 이동
    clickTab(routeName) {
      this.$router.push({
        name: routeName
      });
    }
  },
  mounted() {
    // routeName 으로 activeTab 지정
    this.activeTab = this.tabs.findIndex(t => t.routeName === this.$route.name);
  }
};
</script>

<style scoped></style>
