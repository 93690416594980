<template>
  <v-dialog
    v-model="value"
    :scrollable="scrollable"
    :max-width="maxWidth"
    v-on="$listeners"
    persistent
    retain-focus
    no-click-animation
  >
    <v-form
      v-model="valid"
      @submit.prevent="
        $emit('click:apply', { name, draftOrgan, approvalLines })
      "
    >
      <v-card v-if="value" :loading="loading">
        <!-- 타이틀 -->
        <v-card-title>
          <span class="text-h6 font-weight-bold">내 결재선 등록</span>
        </v-card-title>
        <!-- 본문 -->
        <v-card-text style="">
          <div>
            <v-text-field
              v-model="name"
              background-color="#0000000F"
              label="내 결재선명"
              placeholder="내 결재선명"
              counter="40"
              :rules="rules"
              :loading="loading"
              :disabled="loading"
              autofocus
              flat
              solo
            />
            <div class="mb-1">미리보기</div>
            <div class="cr-preview-section">
              <ApprLineSummary
                :draft-organ="draftOrgan"
                :approval-lines="approvalLines"
              />
            </div>
          </div>
        </v-card-text>
        <!-- 확인/취소 버튼-->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="loading"
            text
            outlined
            @click="$emit('input', false)"
          >
            취소
          </v-btn>
          <v-btn
            :loading="loading"
            text
            outlined
            type="submit"
            color="accent"
            :disabled="!valid"
          >
            등록
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import ApprLineSummary from "@/approval/views/components/dialog/ApprLinePresetSelectDialog/ApprLineSummary";
export default {
  emits: ["click:apply"],
  components: { ApprLineSummary },
  props: {
    value: {
      type: Boolean,
      default: false,
      description: "다이얼로그 표시 여부 (v-model)"
    },
    loading: {
      type: Boolean,
      default: () => false,
      description: "로딩 표시 여부"
    },
    draftOrgan: {
      type: Object,
      required: true,
      description: "내 결재선으로 저장할 기안 부서"
    },
    approvalLines: {
      type: Array,
      required: true,
      description: "내 결재선으로 저장할 결재선 목록"
    },
    persistent: {
      type: Boolean,
      default: false,
      description: "by vuetify dialog prop"
    },
    scrollable: {
      type: Boolean,
      default: false,
      description: "by vuetify dialog prop"
    },
    maxWidth: {
      type: Number,
      default: 500,
      description: "by vuetify dialog prop"
    }
  },
  data: () => ({
    valid: false,
    name: "",
    rules: [
      value => !!value || "필수 입력 사항입니다.",
      value => (value || "").length <= 40 || "최대 40 글자까지 입력 가능합니다."
    ]
  }),
  methods: {
    reset() {
      this.name = "";
    }
  },
  watch: {
    value(show) {
      if (show) {
        this.reset();
      }
    }
  }
};
</script>

<style scoped>
.v-input.v-text-field ::v-deep .v-text-field__details {
  padding: 0 2px;
}
.cr-preview-section {
  padding: 16px;
  max-height: 500px;
  overflow-y: auto;
  /*border-radius: 4px;*/
  border: thin solid rgba(0, 0, 0, 0.06);
  background-color: rgba(245, 245, 245, 0.99);
}
</style>
