<template>
  <div class="fill-height d-flex flex-column">
    <div class="fill-height d-flex flex-column overflow-y-auto">
      <!-- 기안자/기안부서 -->
      <div
        v-if="draftOrganChangeable"
        class="cr-section-title d-flex align-center"
      >
        <span class="text-no-wrap mr-2">기안</span>
        <v-spacer></v-spacer>
        <span class="black--text">{{ draftUserName }}</span>
        <span class="mx-1">|</span>
        <v-select
          v-model="_draftOrgan"
          item-value="organId"
          item-text="name"
          style="max-width: 200px;"
          class="cr-draft-organ-select"
          :placeholder="draftOrganSelectPlaceholder"
          :class="draftOrganErrors.length ? 'invalid' : ''"
          :items="userOrgans"
          :error-messages="draftOrganErrors"
          dense
          solo
          flat
          hide-details
          return-object
          @input="v$.draftOrgan.$touch()"
          @blur="v$.draftOrgan.$touch()"
        />
      </div>
      <div v-else class="cr-section-title d-flex align-center">
        <span class="text-no-wrap mr-2">기안</span>
        <v-spacer></v-spacer>
        <span class="black--text">{{ draftUserOrganText }}</span>
      </div>
      <v-divider />
      <!-- 결재/협조 -->
      <div class="cr-section-title">결재/협조</div>
      <v-divider />
      <DraggableList group="APPROVAL" />
    </div>
    <v-divider />
    <!-- 수신/참조/공람 -->
    <TabGroupedList />
  </div>
</template>

<script>
import DraggableList from "@/approval/views/components/selector/ApprovalLineSelector/SelectedSection/DraggableList";
import TabGroupedList from "@/approval/views/components/selector/ApprovalLineSelector/SelectedSection/TabGroupedList";
import { mapActions, mapGetters, mapState } from "vuex";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
export default {
  components: {
    TabGroupedList,
    DraggableList
  },
  setup: () => ({ v$: useVuelidate() }),
  computed: {
    ...mapGetters("auth", ["getUserInfo", "getRepresentativeOrgan"]),
    ...mapGetters("approvalLineSelector", {
      validAllLines: "isAllValid",
      lineErrors: "getErrors"
    }),
    ...mapState("approvalLineSelector", [
      "draftOrgan",
      "approvalLines",
      "draftUser",
      "approvalLines",
      "selectType"
    ]),
    draftUserOrganText() {
      const { email, name } = this.draftUser ?? {};
      const { name: organName } = this.draftOrgan ?? {};
      return `${name}(${email}) | ${organName}`;
    },
    draftOrganErrors() {
      const { $dirty, required, includeUserOrgans } = this.v$.draftOrgan;
      const errors = [];
      if (!$dirty) return errors;
      if (required.$invalid) errors.push("기안부서를 선택해주세요.");
      if (!required.$invalid && includeUserOrgans.$invalid) {
        errors.push("기안부서는 본인의 소속부서만 지정 가능합니다.");
      }
      return errors;
    },
    draftOrganSelectPlaceholder() {
      const { name } = this.draftOrgan;
      return name || "기안부서 선택";
    },
    draftUserName() {
      return this.draftUser?.name ?? "";
    },
    userOrgans() {
      const organs = this.getUserInfo?.organRelationList ?? [];
      return organs.map(extractFunc);
    },
    draftOrganChangeable() {
      return this.selectType === "DEFAULT";
    },
    _draftOrgan: {
      get() {
        return this.draftOrgan;
      },
      set(val) {
        this.changeDraftOrgan(val);
      }
    }
  },
  methods: {
    ...mapActions("approvalLineSelector", [
      "removeLine",
      "changeDraftOrgan",
      "validateByRules",
      "validateByServer"
    ]),
    ...mapActions("snackbar", ["openSnackbar"]),
    async validate() {
      await this.validateByServer();
      const valid = await this.v$.$validate();
      if (!valid) {
        let errors = [];
        if (!this.approvalLines.length) {
          errors = [...errors, "최소 한명의 결재자를 지정해 주세요"];
        }
        errors = [...errors, ...this.draftOrganErrors];
        errors = [...errors, ...this.lineErrors];
        const errorText = errors.map(err => "- " + err).join("\n");
        this.openSnackbar({
          message: `결재선 등록 실패\n${errorText}`
        });
      }
      return valid;
    }
  },
  validations() {
    return {
      draftOrgan: {
        required,
        includeUserOrgans: val => {
          // 기안부서 선택 권한 없는 유형(상위결재선 변경 등)은 SKIP
          if (!this.draftOrganChangeable) return true;
          return this.userOrgans.some(({ organId }) => organId === val.organId);
        }
      },
      approvalLines: {
        required,
        validByRules: () => this.validateByRules(),
        noError: () => this.validAllLines
      }
    };
  }
};
// Helper:: organRelation => draftOrgan's format
const extractFunc = (organRelation = {}) => {
  const { organId, organizationName } = organRelation;
  return { organId, name: organizationName };
};
</script>

<style lang="scss" scoped>
.cr-section-title::v-deep {
  width: 100%;
  background-color: whitesmoke;
  padding: 8px;

  /* 기안부서 선택 인풋 */
  .cr-draft-organ-select {
    .v-input__control {
      min-height: 30px !important;
      font-size: 0.875rem !important;
    }
    &.invalid .v-input__slot {
      background: rgba(255, 23, 68, 0.05) !important;
      border: thin dashed var(--v-error-base) !important;
    }
  }
}
</style>
