<template>
  <v-treeview
    item-key="key"
    dense
    activatable
    hoverable
    transition
    return-object
    :active.sync="activeItem"
    :items="organTreeItems"
    :load-children="parentNode => loadOrganTreeChildren(parentNode)"
  >
    <!-- 아이콘 -->
    <template v-slot:prepend="{ item }">
      <v-icon small>
        {{ item.type === "USER" ? "mdi-account" : "mdi-graph" }}
      </v-icon>
    </template>
    <!-- 이름 -->
    <template v-slot:label="{ item }">
      <!-- 사용자 -->
      <v-tooltip v-if="item.type === 'USER'" bottom>
        <template v-slot:activator="{ on, attrs }">
          <div v-on="on" v-bind="attrs" class="d-inline-block py-2">
            <span class="mr-1">
              {{ item.name }}
            </span>
            <span class="text-caption grey--text">
              {{ item.jobGrade }}
            </span>
          </div>
        </template>
        {{ `${item.email} | ${item.organ.name}` }}
      </v-tooltip>
      <!-- 조직 -->
      <span v-else>{{ item.name }}</span>
    </template>
  </v-treeview>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "OrganSection",
  props: {},
  data: () => ({}),
  computed: {
    ...mapState("approvalLineSelector", [
      "organTreeItems",
      "organTreeActiveItem"
    ]),
    activeItem: {
      get() {
        return [this.organTreeActiveItem];
      },
      set([activeItem]) {
        this.SET_ORGAN_TREE_ACTIVE_ITEM(activeItem);
      }
    }
  },
  methods: {
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapActions("approvalLineSelector", ["loadOrganTreeChildren"]),
    ...mapMutations("approvalLineSelector", ["SET_ORGAN_TREE_ACTIVE_ITEM"])
  }
};
</script>

<style lang="scss" scoped>
.v-treeview::v-deep {
  .v-treeview-node__prepend {
    min-width: 0;
  }
}
</style>
