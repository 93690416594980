<template>
  <v-dialog
    v-model="value"
    :scrollable="scrollable"
    :max-width="maxWidth"
    v-on="$listeners"
    persistent
    retain-focus
    no-click-animation
  >
    <v-card v-if="value" :loading="loading">
      <!-- 타이틀 -->
      <v-card-title class="d-flex align-center">
        <span class="text-h6 font-weight-bold mr-2">내 결재선</span>
        <span v-if="!loading" class="text-body-1">{{ items.length }}</span>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" icon @click="init">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          새로고침
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" icon @click="routeSettingPage">
              <v-icon>mdi-cog</v-icon>
            </v-btn>
          </template>
          내 결재선 설정
        </v-tooltip>
      </v-card-title>
      <!-- 본문 -->
      <v-card-text style="max-height: 600px; overflow-y: auto">
        <v-list v-if="items.length">
          <v-list-group
            v-model="item.active"
            v-for="item in items"
            :key="item.id"
            active-class="white--text primary"
            no-action
          >
            <!-- 타이틀 -->
            <template v-slot:activator>
              <div
                class="d-flex align-center"
                style="width: 100%; overflow: hidden;"
              >
                <v-icon v-if="item.active">mdi-checkbox-marked</v-icon>
                <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
                <span class="ml-2 text-truncate">{{ item.name }}</span>
              </div>
            </template>
            <!-- 타이틀 클릭시 확장된 컨텐츠 -->
            <div class="pa-5 grey lighten-3">
              <ApprLineSummary
                :draft-organ="item.draftOrgan"
                :approvalLines="item.approvalLines"
              />
            </div>
          </v-list-group>
        </v-list>
        <div v-else class="cr-no-item">
          데이타가 존재하지 않습니다.
        </div>
      </v-card-text>
      <!-- 확인/취소 버튼-->
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text outlined @click="$emit('input', false)">
          취소
        </v-btn>
        <v-btn
          color="accent"
          text
          outlined
          :loading="loading"
          :disabled="!selectedItem"
          @click="$emit('click:apply', selectedItem)"
        >
          적용
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ApprLineSummary from "@/approval/views/components/dialog/ApprLinePresetSelectDialog/ApprLineSummary";

export default {
  components: { ApprLineSummary },
  emits: ["click:apply"],
  props: {
    value: {
      type: Boolean,
      default: false,
      description: "다이얼로그 표시 여부 (v-model)"
    },
    persistent: {
      type: Boolean,
      default: false,
      description: "by vuetify dialog prop"
    },
    scrollable: {
      type: Boolean,
      default: false,
      description: "by vuetify dialog prop"
    },
    maxWidth: {
      type: Number,
      default: 600,
      description: "by vuetify dialog prop"
    }
  },
  data: () => ({
    loading: false,
    items: []
  }),
  computed: {
    ...mapGetters("approvalConfig", ["getApprLinePresets"]),
    selectedItem() {
      return this.items.find(({ active }) => !!active);
    }
  },
  methods: {
    ...mapActions("approvalConfig", ["fetchApprLinePresetList"]),
    async init() {
      this.loading = true;
      await this.fetchApprLinePresetList();
      this.items = this.getApprLinePresets.map(preset => ({
        ...preset,
        active: false
      }));
      this.loading = false;
    },
    reset() {
      this.items = [];
    },
    routeSettingPage() {
      window.open("#/approval/config/preset");
    }
  },
  mounted() {
    this.init();
  },
  watch: {
    value(show) {
      // 다이얼로그 열때 셀렉트 기본값 바인딩
      show ? this.init() : this.reset();
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-no-item {
  display: flex;
  width: 100%;
  height: 150px;
  justify-content: center;
  align-items: center;
}
</style>
