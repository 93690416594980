<template>
  <div class="d-flex align-center">
    <v-btn v-if="false" depressed class="ml-2">수신 추가</v-btn>
    <v-btn v-if="false" depressed class="ml-2">수신 재발송</v-btn>
    <v-btn
      v-if="showReuseBtn"
      depressed
      dark
      color="grey darken-2"
      class="ml-2"
      @click="$emit('click:reuse')"
    >
      재기안
    </v-btn>
    <v-btn
      v-if="showEditBtn"
      depressed
      dark
      color="primary"
      class="ml-2"
      @click="$emit('click:edit')"
    >
      수정
    </v-btn>
    <v-btn
      v-if="showRetrieveBtn"
      depressed
      dark
      color="grey darken-2"
      class="ml-2"
      @click="$emit('click:retrieve')"
    >
      회수
    </v-btn>
    <v-btn
      v-if="showDeleteBtn"
      depressed
      dark
      color="error"
      class="ml-2"
      @click="$emit('click:delete')"
    >
      삭제
    </v-btn>
    <v-btn
      v-if="showReceiptBtn"
      depressed
      dark
      color="primary"
      class="ml-2"
      @click="$emit('click:receipt')"
    >
      접수
    </v-btn>
    <v-btn
      v-if="showApproveBtn"
      depressed
      dark
      color="primary"
      class="ml-2"
      @click="$emit('click:approve')"
    >
      결재
    </v-btn>
    <v-btn
      v-if="showApproveCancelBtn"
      depressed
      class="ml-2"
      dark
      color="grey darken-2"
      @click="$emit('click:approveCancel')"
    >
      결재취소
    </v-btn>
    <v-btn
      v-if="showReceiveBtn"
      depressed
      dark
      color="primary"
      class="ml-2"
      @click="$emit('click:receive')"
    >
      수신확인
    </v-btn>
    <v-btn
      v-if="showReturnBtn"
      depressed
      dark
      color="grey darken-2"
      class="ml-2"
      @click="$emit('click:return')"
    >
      반송
    </v-btn>
  </div>
</template>

<script>
import { ApprovalLines } from "@/approval/utils/ApprovalLines";
import { LINE_GROUP, LINE_TYPE } from "@/approval/constant/approvalLine";
import { mapGetters, mapState } from "vuex";

export default {
  emits: [
    "click:reuse",
    "click:edit",
    "click:retrieve",
    "click:delete",
    "click:receipt",
    "click:approve",
    "click:approveCancel",
    "click:receive",
    "click:return"
  ],
  props: {
    document: {
      description: "문서"
    },
    approvalLines: {
      description: "문서의 전체 결재선"
    },
    form: {
      description: "기안 양식"
    }
  },
  computed: {
    ...mapState("auth", ["userInfo"]),
    ...mapGetters("approvalConfig", ["getManagedOrganIds"]),
    showReuseBtn() {
      if (!this.draftUserId || !this.loginUserId) return false;
      return (
        this.draftUserId === this.loginUserId &&
        this.document?.status === "COMPLETE" &&
        this.form?.docReusable === true
      );
    },
    showEditBtn() {
      if (!this.draftUserId || !this.loginUserId) return false;
      return (
        !this.document.originDoc &&
        this.draftUserId === this.loginUserId &&
        ["REJECT", "RETRIEVE"].includes(this.document?.status)
      );
    },
    showRetrieveBtn() {
      if (!this.draftUserId || !this.loginUserId) return false;
      return (
        this.draftUserId === this.loginUserId &&
        this.document?.status === "DRAFT"
      );
    },
    showDeleteBtn() {
      if (!this.draftUserId || !this.loginUserId) return false;
      return (
        this.draftUserId === this.loginUserId &&
        ["REJECT", "RETRIEVE", "TEMP"].includes(this.document?.status)
      );
    },
    showReceiveBtn() {
      if (this.document?.status !== "COMPLETE") return;
      const receivableLines = new ApprovalLines(this.approvalLines)
        .filterByGroup(LINE_GROUP.RECEIVE)
        .filterByStatus("PENDING_RECEIVE")
        .filter(this.checkLineOwner)
        .get();
      return receivableLines?.length > 0;
    },
    showReturnBtn() {
      return this.showReceiveBtn;
    },
    showReceiptBtn() {
      let lines = [];
      // 접수 가능한 부서협조 결재선
      if (["DRAFT", "PROGRESS"].includes(this.document?.status)) {
        const myOrganHelpLines = new ApprovalLines(this.approvalLines)
          .filterByType(LINE_TYPE.HELP_ORGAN)
          .filterByStatus("PENDING_APPROVAL")
          .filter(this.checkLineOwner)
          .get();
        lines = [...lines, ...myOrganHelpLines];
      }
      // 접수 가능한 부서수신 결재선
      if (["COMPLETE"].includes(this.document?.status)) {
        const myOrganReceiveLines = new ApprovalLines(this.approvalLines)
          .filterByType(LINE_TYPE.RECEIVE_ORGAN)
          .filterByStatus("PENDING_RECEIVE")
          .filter(this.checkLineOwner)
          .get();
        lines = [...lines, ...myOrganReceiveLines];
      }
      return lines?.length > 0;
    },
    showApproveBtn() {
      return (
        ["DRAFT", "PROGRESS"].includes(this.document?.status) &&
        this.checkLineOwner(this.nextApproveLine)
      );
    },
    showApproveCancelBtn() {
      return (
        this.showApproveBtn === false &&
        this.document?.status === "PROGRESS" &&
        this.prevApproveLine?.status === "APPROVED" &&
        this.checkLineOwner(this.prevApproveLine)
      );
    },
    draftUserId() {
      return this.document?.draftUser?.id;
    },
    loginUserId() {
      return this.userInfo?.id;
    },
    nextApproveLine() {
      return new ApprovalLines(this.approvalLines)
        .filterByGroup(LINE_GROUP.APPROVAL)
        .filterByStatus("PENDING_APPROVAL")
        .getLast();
    },
    prevApproveLine() {
      return new ApprovalLines(this.approvalLines)
        .filterByGroup(LINE_GROUP.APPROVAL)
        .filter(({ sortOrder }) => sortOrder < this.nextApproveLine?.sortOrder)
        .sortAsc()
        .getLast();
    }
  },
  methods: {
    // 로그인 유저가 {line}의 결재권자 인지 체크
    checkLineOwner(line = {}) {
      if (!this.loginUserId) return false;
      if (line?.approver?.isUser) {
        return this.loginUserId === line.approver.userId;
      }
      if (line?.approver?.isOrgan) {
        return this.getManagedOrganIds.includes(line.approver.organId);
      }
      return false;
    }
  }
};
</script>

<style scoped></style>
