<template>
  <v-col cols="12" class="cr-option-wrap">
    <div
      class="fill-height d-flex"
      :class="vertical ? 'flex-column' : 'align-center'"
    >
      <!-- 필드 Title -->
      <div class="cr-option-title" :class="vertical ? 'vertical' : ''">
        <span>{{ fieldName }}</span>
        <span v-if="required" class="ml-2 accent--text">*</span>
        <!-- 액션 버튼 (테이블 행 추가 등등)-->
        <v-btn
          v-if="showBtn"
          icon
          color="info"
          class="ml-auto mr-4"
          @click.stop="$refs.fieldItem.btnAction()"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
      <!-- 핃드 Item -->
      <div class="cr-option-content" :class="vertical ? 'vertical' : ''">
        <TemplateFieldItem
          ref="fieldItem"
          v-model="computedModel"
          :templateFrame="templateFrame"
          :editable="editable"
        />
      </div>
    </div>
  </v-col>
</template>

<script>
import TemplateFieldItem from "@/approval/views/components/template/TemplateFieldItem";
export default {
  name: "TemplateField",
  components: {
    TemplateFieldItem
  },
  props: {
    value: {
      type: Object,
      description: "필드 데이타 (v-model)"
    },
    templateFrame: {
      type: Object,
      required: true,
      description: "필드 속성"
    },
    editable: {
      type: Boolean,
      default: false,
      description: "편집 모드"
    }
  },
  computed: {
    // v-model
    computedModel: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    },
    // 세로배치
    vertical() {
      return this.type === "TABLE";
    },
    // 컴포넌트 타입
    type() {
      return this.templateFrame?.type;
    },
    // 필수 입력 여부
    required() {
      return this.templateFrame?.required ?? false;
    },
    // 필드명
    fieldName() {
      return this.templateFrame?.name;
    },
    // 제목 영역에 버튼을 표시할지 여부
    showBtn() {
      return this.editable && this.type === "TABLE";
    }
  },
  methods: {
    async validate() {
      return (await this.$refs?.fieldItem?.validate?.()) || false;
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-option-wrap {
  padding: 0;
  border-bottom: thin solid rgba(0, 0, 0, 0.08);
  border-right: thin solid rgba(0, 0, 0, 0.08);

  .cr-option-title {
    display: flex;
    align-items: center;
    height: 60px;
    width: 160px;
    min-width: 160px;
    line-height: 50px;
    padding-left: 16px;
    background-color: #fafafa;
    &.vertical {
      height: 60px;
      width: 100%;
      border-bottom: thin solid rgba(0, 0, 0, 0.08);
    }
  }

  .cr-option-content {
    height: 60px;
    width: 100%;
    padding: 0 16px;
    &:not(.vertical) {
      display: flex;
      align-items: center;
    }
    &.vertical {
      width: 100%;
      height: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }
}
</style>
