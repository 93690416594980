<template>
  <v-dialog
    v-model="showDialog"
    :persistent="persistent"
    :fullscreen="fullScreen"
    :scrollable="scrollable"
    :max-width="maxWidth"
    retain-focus
    no-click-animation
  >
    <v-card v-if="showDialog">
      <v-card-title>
        <span class="text-h6 font-weight-bold">{{ _title }}</span>
        <v-spacer></v-spacer>
        <div v-if="showPresetMenu">
          <v-btn
            small
            depressed
            class="font-weight-regular mr-1"
            @click="showPresetSelectDialog = true"
          >
            내 결재선 불러오기
          </v-btn>
          <v-btn
            small
            depressed
            class="font-weight-regular"
            color="grey darken-1"
            :dark="!disabledSavePreset"
            :disabled="disabledSavePreset"
            @click="showPresetCreateDialog = true"
          >
            내 결재선으로 추가
          </v-btn>
        </div>
      </v-card-title>
      <v-card-text>
        <ApprovalLineSelector
          ref="selector"
          v-if="showDialog"
          v-bind="$props"
          v-on="$listeners"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text outlined @click="showDialog = false">
          취소
        </v-btn>
        <v-btn
          color="accent"
          text
          outlined
          :disabled="disabledConfirm"
          @click="submit"
        >
          확인
        </v-btn>
      </v-card-actions>
    </v-card>
    <ApprLinePresetSelectDialog
      v-model="showPresetSelectDialog"
      @click:apply="applyPreset"
    />
    <ApprLinePresetSaveDialog
      v-model="showPresetCreateDialog"
      :loading="loadingPresetSave"
      :draft-organ="selectorDraftOrgan"
      :approval-lines="selectorLines"
      @click:apply="savePreset"
    />
  </v-dialog>
</template>

<script>
import ApprovalLineSelector from "@/approval/views/components/selector/ApprovalLineSelector";
import ApprLinePresetSelectDialog from "@/approval/views/components/dialog/ApprLinePresetSelectDialog";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { getGroupByType } from "@/approval/constant/approvalLine";
import { ApprovalLines } from "@/approval/utils/ApprovalLines";
import ApprLinePresetSaveDialog from "@/approval/views/components/dialog/ApprLinePresetSaveDialog";
import { LINE_TYPE } from "../../../constant/approvalLine";

export default {
  components: {
    ApprLinePresetSaveDialog,
    ApprLinePresetSelectDialog,
    ApprovalLineSelector
  },
  emits: ["submit"],
  props: {
    value: {
      type: Boolean,
      default: false,
      description: "다이얼로그 표시 여부 (v-model)"
    },
    selectType: {
      type: String,
      default: () => "DEFAULT",
      description:
        "편집유형: [DEFAULT, INNER_DRAFT, CHANGE_UPPER, CHANGE_SHARE, CHANGE_RECEIVE]"
    },
    approvalLines: {
      type: Array,
      default: () => [],
      description: "결재선목록 (초기값)"
    },
    draftUser: {
      type: Object,
      description: "기안자 (초기값)"
    },
    draftOrgan: {
      type: Object,
      description: "기안 부서 (초기값)"
    },
    showPresetMenu: {
      type: Boolean,
      default: () => false,
      description: "결재선 프리셋 관련 메뉴 표시 여부"
    },
    title: {
      type: String,
      description: "다이얼로그 타이틀"
    },
    persistent: {
      type: Boolean,
      default: false,
      description: "by vuetify dialog prop"
    },
    scrollable: {
      type: Boolean,
      default: false,
      description: "by vuetify dialog prop"
    },
    fullScreen: {
      type: Boolean,
      default: false,
      description: "by vuetify dialog prop"
    },
    maxWidth: {
      type: Number,
      default: 1000,
      description: "by vuetify dialog prop"
    }
  },
  data: () => ({
    showPresetSelectDialog: false,
    showPresetCreateDialog: false,
    loadingPresetSave: false
  }),
  computed: {
    ...mapState("approvalLineSelector", {
      selectorDraftOrgan: "draftOrgan",
      selectorLines: "approvalLines"
    }),
    ...mapGetters("approvalLineSelector", ["isAllValid"]),
    ...mapGetters("approvalConfig", ["getApprLinePresets"]),
    // for props sync
    showDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    },
    _title() {
      if (this.title) return this.title;
      switch (this.selectType) {
        case "INNER_DRAFT":
          return "내부 결재선 설정";
        case "CHANGE_UPPER":
          return "상위 결재선 변경";
        case "CHANGE_SHARE":
          return "공람 변경";
        case "CHANGE_RECEIVE":
          return "수신 변경";
        default:
          return "결재선 설정";
      }
    },
    // 기안부서 또는 결재선이 초기값에서 편집되었는지 여부 (dirty)
    hasChanged() {
      const beforeSignatures = this.convertForComparing(this.approvalLines);
      const afterSignatures = this.convertForComparing(this.selectorLines);
      const sameDraftOrgan =
        this.draftOrgan?.organId === this.selectorDraftOrgan?.organId;
      const sameLines =
        beforeSignatures.length === afterSignatures.length &&
        beforeSignatures.every((sign, idx) => sign === afterSignatures[idx]);
      return !sameDraftOrgan || !sameLines;
    },
    // 결재선에 결재유형 최소 한명 이상 포함 여부
    includesApprovalTypeLine() {
      return this.selectorLines.some(({ type }) => type === LINE_TYPE.APPROVAL);
    },
    disabledConfirm() {
      return !this.hasChanged || !this.includesApprovalTypeLine;
    },
    disabledSavePreset() {
      return (this.selectorLines || []).length === 0 || !this.isAllValid;
    }
  },
  methods: {
    ...mapActions("confirm", ["confirm"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapActions("approvalConfig", ["saveApprLinePreset"]),
    ...mapActions("approvalLineSelector", [
      "validateByRules",
      "validateByServer"
    ]),
    ...mapMutations("approvalLineSelector", [
      "SET_APPROVAL_LINES",
      "SET_DRAFT_ORGAN"
    ]),
    // {ApprovalLineSelector}에서 편집한 결재선으로 submit 이벤트 발생
    submit() {
      this.$refs.selector.submit();
    },
    // 변경점 감지를 위해 정렬 및 시그니처 추출
    convertForComparing(approvalLines = []) {
      return new ApprovalLines(approvalLines)
        .sortAsc("sortOrder")
        .sort((a, b) => {
          const aGroup = getGroupByType(a.type);
          const bGroup = getGroupByType(b.type);
          return aGroup.localeCompare(bGroup);
        })
        .get()
        .map(({ approver }) => this.getApproverSignature(approver));
    },
    // userId, organId 모두 일치해야만 동일한 결재자로 인정
    getApproverSignature(approver = {}) {
      const { userId, organId } = approver;
      return `${userId}_${organId}`;
    },
    // 내 결재선 적용
    async applyPreset(preset = {}) {
      const { draftOrgan = {}, approvalLines = [] } = preset;
      const callback = async () => {
        // 내 결재선의 기안 부서와 결재선 목록으로 변경
        this.SET_DRAFT_ORGAN(draftOrgan);
        this.SET_APPROVAL_LINES(approvalLines);
        // 적용된 결재선 유효성 검사
        this.validateByRules();
        await this.validateByServer();
        // 다이얼로그 닫기
        this.showPresetSelectDialog = false;
        this.openSnackbar({
          message: "결재선이 적용되었습니다.",
          type: "SUCCESS"
        });
      };
      this.confirm({
        headline: `내 결재선 적용`,
        message: `선택한 결재선을 적용 하시겠습니까? </br> 현재 설정된 결재선이 초기화 됩니다.`,
        callback
      });
    },
    // 내 결재선 등록
    async savePreset({ name, draftOrgan, approvalLines }) {
      this.loadingPresetSave = true;
      const saved = await this.saveApprLinePreset({
        name,
        draftOrgan,
        approvalLines
      });
      this.loadingPresetSave = false;
      if (saved) this.showPresetCreateDialog = false;
      this.openSnackbar({
        message: saved
          ? "내 결재선이 등록되었습니다."
          : "내 결재선 등록에 실패했습니다. 최대 30개 까지 저장 가능합니다.",
        type: saved ? "SUCCESS" : "ERROR"
      });
    }
  }
};
</script>

<style scoped></style>
