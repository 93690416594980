<template>
  <v-tooltip bottom color="black" max-width="400" :disabled="!hasError">
    <template v-slot:activator="{ on, attrs }">
      <v-chip
        v-on="on"
        v-bind="attrs"
        color="primary"
        class="cr-line-chip"
        :class="hasError ? 'invalid' : ''"
        :close="removable"
        small
        outlined
        @click:close="$emit('click:remove', approvalLine)"
      >
        <v-tooltip bottom color="black" max-width="300" :disabled="hasError">
          <template v-slot:activator="{ on, attrs }">
            <span
              v-on="on"
              v-bind="attrs"
              class="text-truncate"
              style="max-width: 200px;"
            >
              {{ approverName }}
            </span>
          </template>
          <!-- 툴팁 텍스트 -->
          <div class="d-flex flex-column align-center text-break text-center">
            <span>{{ approverName }}</span>
            <span v-if="approverIsUser" class="text-caption">
              {{ approverEmail }}
            </span>
            <span v-if="approverIsUser" class="text-caption">
              {{ approverOrganName }}
            </span>
          </div>
        </v-tooltip>
      </v-chip>
    </template>
    <!-- 결재선 에러 툴팁 -->
    <div style="white-space: pre-line">
      {{ errorText }}
    </div>
  </v-tooltip>
</template>

<script>
export default {
  emits: ["click:remove"],
  props: {
    approvalLine: {
      type: Object,
      required: true,
      description: "결재선 아이템"
    },
    removable: {
      type: Boolean,
      default: true,
      description: "편집 가능 여부"
    }
  },
  computed: {
    approverIsUser() {
      return this.approvalLine?.approver?.isUser;
    },
    // 결재자 이름
    approverName() {
      return this.approvalLine?.approver?.isUser
        ? this.approvalLine?.approver?.userName
        : this.approvalLine?.approver?.organName;
    },
    // 결재자 부서명
    approverOrganName() {
      return this.approvalLine?.approver.organName;
    },
    // 결재자 이메일
    approverEmail() {
      return this.approvalLine?.approver.isUser
        ? this.approvalLine?.approver.userEmail
        : undefined;
    },
    lineErrors() {
      return this.approvalLine?.errors ?? [];
    },
    hasError() {
      return this.lineErrors?.length > 0;
    },
    errorText() {
      return this.lineErrors.map(err => "※ " + err).join("\n");
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-line-chip {
  margin: 4px;
  &.invalid {
    &.v-chip {
      background-color: rgba(255, 23, 68, 0.05) !important;
      color: rgba(255, 23, 68, 1) !important;
      border: thin dashed rgba(255, 23, 68, 1) !important;
      span {
        color: rgba(0, 0, 0, 0.3) !important;
      }
    }
  }
}
</style>
