<template>
  <div class="d-flex flex-column">
    <!-- 탭 헤더 -->
    <v-tabs v-model="activeTab" background-color="#f5f5f5" height="38">
      <v-tab v-for="tab in tabs" :key="tab">
        <div class="d-flex justify-center align-center">
          <span class="mr-1">{{ getTabName(tab) }}</span>
          <span v-if="getLinesByTab(tab).length > 0">
            {{ getLinesByTab(tab).length }}
          </span>
        </div>
      </v-tab>
    </v-tabs>
    <!-- 컨텐츠 -->
    <div
      v-if="activeTabLines.length"
      style="height: 150px"
      class="d-flex flex-wrap align-content-start pa-2 overflow-y-auto"
    >
      <TabGroupedListItem
        v-for="line in activeTabLines"
        :key="line.key"
        :approvalLine="line"
        :removable="removable(line)"
        @click:remove="removeLine(line)"
      />
    </div>
    <div v-else class="cr-no-item">
      선택된 결재선이 없습니다.
    </div>
  </div>
</template>

<script>
import { LINE_GROUP, getGroupByType } from "@/approval/constant/approvalLine";
import { mapActions, mapState } from "vuex";
import { ApprovalLines } from "@/approval/utils/ApprovalLines";
import TabGroupedListItem from "@/approval/views/components/selector/ApprovalLineSelector/SelectedSection/listItem/TabGroupedListItem";

export default {
  components: { TabGroupedListItem },
  props: {},
  data: () => ({
    tabs: [LINE_GROUP.RECEIVE, LINE_GROUP.CC, LINE_GROUP.SHARE],
    activeTab: 0
  }),
  computed: {
    ...mapState("approvalLineSelector", ["approvalLines", "selectType"]),
    // 현재 선택된 탭의 결재선 목록
    activeTabLines() {
      return this.getLinesByTab(this.tabs[this.activeTab]);
    }
  },
  methods: {
    ...mapActions("approvalLineSelector", ["removeLine"]),
    // 결재선 탭 이름
    getTabName(tab) {
      switch (tab) {
        case LINE_GROUP.RECEIVE:
          return "수신";
        case LINE_GROUP.CC:
          return "참조";
        case LINE_GROUP.SHARE:
          return "공람";
      }
    },
    // 탭에 해당하는 결재선 목록
    getLinesByTab(tab) {
      return new ApprovalLines(this.approvalLines).filterByGroup(tab).get();
    },
    // 결재선 제거 가능 여부
    removable(line) {
      if (this.selectType === "INNER_DRAFT") return false;
      if (this.selectType === "CHANGE_UPPER") return false;
      if (
        this.selectType === "CHANGE_SHARE" ||
        this.selectType === "CHANGE_RECEIVE"
      ) {
        // 새로 추가한 결재선만 삭제 가능
        return !line?.id;
      }
      return true;
    }
  },
  watch: {
    // 새로운 결재선이 목록에 추가될 경우 해당 그룹 포커스
    approvalLines(n, p) {
      if (n.length < 1) return;
      if (n.length <= p.length) return;
      const recentlyAddedLine = n.find(
        ({ key }) => !p.some(({ key: existKey }) => existKey === key)
      );
      const recentlyAddedTabIndex = this.tabs.indexOf(
        getGroupByType(recentlyAddedLine?.type)
      );
      if (recentlyAddedTabIndex >= 0) this.activeTab = recentlyAddedTabIndex;
    }
  },
  mounted() {
    // [공람 변경], [수신 변경]의 경우 초기 탭 위치 지정
    this.$nextTick(() => {
      let initTabType;
      switch (this.selectType) {
        case "CHANGE_SHARE":
          initTabType = LINE_GROUP.SHARE;
          break;
        case "CHANGE_RECEIVE":
          initTabType = LINE_GROUP.RECEIVE;
          break;
      }
      const initTabIndex = this.tabs.indexOf(getGroupByType(initTabType));
      if (initTabIndex > 0) this.activeTab = initTabIndex;
    });
  }
};
</script>

<style lang="scss" scoped>
.cr-no-item {
  display: flex;
  width: 100%;
  height: 150px;
  justify-content: center;
  align-items: center;
}
</style>
