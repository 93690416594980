<template>
  <div
    class="d-flex flex-column align-center justify-center"
    style="height: 100%"
  >
    <v-icon size="64" color="grey" class="mt-2">
      mdi-file-document-alert
    </v-icon>
    <h1 class="mt-2">문서가 존재하지 않습니다.</h1>
    <h4 class="mt-1">기안자 또는 관리자에 의해 폐기되었을 수 있습니다.</h4>
    <div class="d-flex align-center mt-6">
      <v-btn
        v-if="showBackButton"
        tile
        depressed
        v-text="$t('common.뒤로')"
        width="100"
        height="40"
        class="mr-1"
        @click="routeToBack"
      />
      <v-btn
        tile
        depressed
        v-text="$t('common.홈으로')"
        width="100"
        height="40"
        @click="routeToHome"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showBackButton: false
    };
  },
  methods: {
    routeToBack() {
      this.$router.go(-2);
    },
    routeToHome() {
      this.$router.push({ name: "approval_home" });
    }
  },
  mounted() {
    this.showBackButton = window?.history?.length > 2;
  }
};
</script>

<style scoped></style>
