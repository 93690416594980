<template>
  <v-autocomplete
    :value="value"
    :loading="loading"
    :items="searchResults"
    :search-input.sync="searchWord"
    :error-messages="errorMessage"
    :placeholder="placeholder"
    :label="placeholder"
    solo
    flat
    dense
    outlined
    no-filter
    small-chips
    hide-details
    return-object
    deletable-chips
    auto-select-first
    item-text="name"
    :item-value="getItemValue"
    no-data-text="검색 결과가 없습니다."
    @input="val => $emit('input', val)"
    @blur="$emit('blur')"
  >
    <!--검색결과 리스트-->
    <template v-slot:item="{ item }">
      <v-list-item-group>
        <v-list-item-title>{{ getSearchResultText(item) }}</v-list-item-title>
      </v-list-item-group>
    </template>
    <template v-if="!last" v-slot:append-item>
      <v-btn
        tile
        depressed
        text
        color="primary"
        style="width: 100%;"
        @click="loadMore"
      >
        검색 결과 더보기
      </v-btn>
    </template>
  </v-autocomplete>
</template>
<script>
import { getEmails } from "@/commons/api/autocomplete.api";

export default {
  props: {
    searchTypes: {
      default: () => ["MEMBER"],
      type: Array
    },
    value: {
      default: () => null,
      type: Object
    },
    placeholder: {
      default: () => "",
      type: String
    },
    errorMessage: {
      default: () => [],
      type: [Object, Array]
    }
  },
  data() {
    return {
      loading: false,
      searchWord: "",
      searchResults: [],
      page: 0,
      last: false
    };
  },
  watch: {
    searchWord(searchWord) {
      this.fetchSearchResult({ searchWord });
    },
    searchResults(searchResults) {
      if (!this.value) return;
      // 현재 선택된 항목 (v-model)이 검색결과에 포함되지 않은 경우 임의로 추가
      // 검색 결과에 포함되어 있지 않으면 선택된 아이템이 표시되지 않음
      const includeSelection = searchResults.some(
        item => this.getItemValue(item) === this.getItemValue(this.value)
      );
      if (!includeSelection) {
        const selection = {
          ...this.value,
          type: this.value?.userId ? "MEMBER" : "ORGAN"
        };
        this.searchResults = [...searchResults, selection];
      }
    }
  },
  methods: {
    remove() {
      this.$emit("input", null);
    },
    async fetchSearchResult({ searchWord, page = 0 }) {
      this.loading = true;
      const params = {
        page,
        query: searchWord,
        typeList: this.searchTypes
      };
      const { data } = (await getEmails(params)) || {};
      const { content, last } = data;
      this.page = page;
      this.last = last;
      this.searchResults =
        page > 0 ? [...this.searchResults, ...content] : content;
      this.loading = false;
    },
    loadMore() {
      this.fetchSearchResult({
        searchWord: this.searchWord,
        page: this.page + 1
      });
    },
    getTypeName(type) {
      switch (type) {
        case "MEMBER":
          return this.$t("common.조직도");
        case "RECENT":
          return this.$t("common.최근");
        case "ORGAN":
          return this.$t("common.부서");
        case "CONTACT":
          return this.$t("common.주소록");
      }
      return "";
    },
    getSearchResultText(item) {
      const { email, name, jobTitle, jobGrade, organization, type } = item;
      let data = `${this.getTypeName(type)}: ${name && `"${name}"`}`;
      if (email) {
        data += ` <${email}>`;
      }
      if (organization) {
        data += ` ${organization}`;
      }
      if (jobGrade) {
        data += ` / ${jobGrade}`;
      }
      if (jobTitle) {
        data += ` / ${jobTitle}`;
      }
      return data;
    },
    getItemValue(item) {
      return item?.userId || item?.organId;
    }
  }
};
</script>
