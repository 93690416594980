<template>
  <div class="text-center">
    <v-menu :disabled="disableContextMenu" offset-y>
      <template v-slot:activator="menu">
        <v-tooltip top color="black">
          <template v-slot:activator="tooltip">
            <div
              v-bind="{ ...menu.attrs, ...tooltip.attrs }"
              v-on="{ ...menu.on, ...tooltip.on }"
              class="text-truncate"
              :class="disableContextMenu ? '' : 'cr-link'"
            >
              {{ formName }}
            </div>
          </template>
          <!-- tooltip 메시지 -->
          <span>{{ formName }}</span>
        </v-tooltip>
      </template>
      <!-- 이름 클릭시 메뉴 -->
      <v-list outlined dense tile>
        <v-hover
          v-slot="{ hover }"
          v-for="(item, index) in menuItems"
          :key="index"
        >
          <v-list-item
            :class="hover ? 'cr-list-item--hover' : ''"
            :disabled="item.disabled"
            @click="item.click"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-hover>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
export default {
  emits: ["search:form", "draft:form"],
  props: {
    disableContextMenu: {
      type: Boolean,
      default: false,
      description: "컨텍스트 메뉴 비활성화"
    },
    formId: { description: "기안양식 아이디" },
    formName: { description: "기안 양식 이름" }
  },
  data() {
    return {
      menuItems: [
        {
          title: "양식으로 검색",
          click: () => this.$emit("search:form", this.formId)
        },
        {
          title: "양식으로 기안 작성",
          click: () => this.$emit("draft:form", this.formId)
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.cr-link {
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
.cr-list-item--hover {
  background-color: #c5e0ff71;
  cursor: pointer;
}
</style>
