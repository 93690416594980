<template>
  <v-dialog
    :value="value"
    :scrollable="scrollable"
    :max-width="maxWidth"
    v-on="$listeners"
    persistent
    retain-focus
    no-click-animation
  >
    <v-card>
      <!-- 타이틀 -->
      <v-card-title>
        <span class="text-h6 font-weight-bold">공개 설정 변경</span>
      </v-card-title>
      <!-- 본문 -->
      <v-card-text>
        <p>변경할 공개 유형을 선택해 주세요</p>
        <v-radio-group v-model="openType" row mandatory>
          <v-radio
            v-for="openType of openTypes"
            :key="openType"
            :value="openType"
            :label="getLabel(openType)"
          />
        </v-radio-group>
      </v-card-text>
      <!-- 확인/취소 버튼-->
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text outlined @click="$emit('input', false)">
          취소
        </v-btn>
        <v-btn
          color="accent"
          text
          outlined
          @click="$emit('click:confirm', openType)"
        >
          확인
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import OPEN_TYPE from "@/approval/constant/openType";

export default {
  name: "OpenTypeChangeDialog",
  emits: ["click:confirm"],
  props: {
    value: {
      type: Boolean,
      default: false,
      description: "다이얼로그 표시 여부 (v-model)"
    },
    initData: {
      default: OPEN_TYPE.PUBLIC,
      description: "초기 선택값"
    },
    persistent: {
      type: Boolean,
      default: false,
      description: "by vuetify dialog prop"
    },
    scrollable: {
      type: Boolean,
      default: false,
      description: "by vuetify dialog prop"
    },
    maxWidth: {
      type: Number,
      default: 600,
      description: "by vuetify dialog prop"
    }
  },
  data: () => ({
    openType: OPEN_TYPE.PUBLIC
  }),
  computed: {
    openTypes() {
      return OPEN_TYPE;
    }
  },
  methods: {
    getLabel(openType) {
      switch (openType) {
        case OPEN_TYPE.PUBLIC:
          return "공개";
        case OPEN_TYPE.PRIVATE:
          return "비공개";
        case OPEN_TYPE.ORGAN:
          return "부서공개";
        default:
          return "-";
      }
    },
    setInitData() {
      this.openType =
        Object.keys(this.openTypes).find(
          openType => openType === this.initData
        ) || OPEN_TYPE.PUBLIC;
    }
  },
  watch: {
    value(show) {
      if (show) this.setInitData();
    }
  }
};
</script>

<style scoped></style>
