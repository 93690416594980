<template>
  <div style="position: relative">
    <!-- header -->
    <div class="d-flex align-center">
      <span class="cr-section-title mr-1">문서 담당 부서</span>
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on" class="text-truncate">
            <v-icon>mdi-help-circle-outline</v-icon>
          </span>
        </template>
        <p class="ma-0">
          문서담당자로 지정된 부서 입니다.<br />
          부서협조, 부서수신 유형의 결재를 처리할 수 있습니다.<br /><br />
          문서담당권한 편집은 관리자에게 문의하세요.
        </p>
      </v-tooltip>
    </div>
    <v-divider class="my-2"></v-divider>
    <!-- body-->
    <div v-if="isEmpty" class="cr-no-item-wrap">
      문서담당자로 지정된 부서가 없습니다.
    </div>
    <v-row v-else no-gutters class="overflow-hidden">
      <v-col
        v-for="(item, idx) in items"
        :key="idx"
        cols="6"
        class="overflow-hidden"
      >
        <v-tooltip color="black" bottom>
          <template v-slot:activator="{ on, attrs }">
            <div v-on="on" v-bind="attrs" class="cr-organ-item">
              <span
                class="text-subtitle-1 font-weight-bold text-truncate"
                style="width: 100%"
              >
                {{ item.organizationName }}
              </span>
              <v-icon v-if="item.isManager" color="yellow darken-2">
                mdi-crown
              </v-icon>
            </div>
          </template>
          <!-- 툴팁 텍스트 -->
          <div class="text-center">
            <span>
              {{
                `${item.organizationName} ${item.isManager ? "[조직장]" : ""}`
              }}
            </span>
            <br />
            <span v-if="item.jobTitle" class="text-caption">
              {{ item.jobTitle }}
            </span>
          </div>
        </v-tooltip>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {},
  data: () => ({}),
  computed: {
    ...mapState("approvalConfig", ["managerConfig"]),
    items() {
      return this.managerConfig?.managedOrgans ?? [];
    },
    isEmpty() {
      return this.items.length === 0;
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.cr-organ-item {
  display: flex;
  justify-content: start;
  align-items: center;
  height: 50px;
  margin: 4px;
  padding: 12px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.03);
}
.cr-no-item-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.38);
}
</style>
