var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cr-table-wrap",staticStyle:{"height":"100%"}},[_c('v-data-table',{attrs:{"value":_vm.selectedItems,"items":_vm.items,"items-per-page":-1,"item-key":"id","hide-default-footer":"","fixed-header":"","show-select":""},on:{"input":function (val) { return _vm.$emit('update:selectedItems', val); }},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-simple-checkbox',_vm._g(_vm._b({},'v-simple-checkbox',props,false),on)),_c('span',[_vm._v(_vm._s(((_vm.selectedItems.length) + "개 선택됨")))]),_c('v-spacer'),(_vm.selectedItems.length === 0)?_c('v-tooltip',{attrs:{"disabled":!_vm.isMaxItems,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{attrs:{"tile":"","depressed":"","color":"primary","disabled":_vm.isMaxItems},on:{"click":function($event){return _vm.$emit('click:add')}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")]),_c('span',[_vm._v("추가")])],1)],1)]}}],null,true)},[_vm._v(" 최대 30개 까지 등록 가능합니다. ")]):_c('v-btn',{attrs:{"tile":"","depressed":"","color":"error"},on:{"click":function($event){return _vm.$emit('click:delete', _vm.selectedItems)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-trash-can-outline")]),_c('span',[_vm._v("삭제")])],1)],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
var isSelected = ref.isSelected;
var select = ref.select;
return [_c('tr',{class:_vm.viewItemId === item.id ? 'cr-focus' : '',on:{"click":function($event){return _vm.$emit('click:item', item)}}},[_c('th',[_c('div',{staticClass:"d-flex align-center"},[_c('v-simple-checkbox',{directives:[{name:"ripple",rawName:"v-ripple",value:(false),expression:"false"}],attrs:{"value":isSelected},on:{"input":select}}),_c('div',[_c('div',{staticClass:"cr-table-item-title text-body-1"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"cr-table-item-date text-caption"},[_vm._v(" "+_vm._s(_vm.convertDate(item.createdTimeMillis))+" ")])])],1)])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }