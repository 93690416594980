<template>
  <v-container
    ref="mainContainer"
    class="cr-approval-main"
    :style="style"
    fluid
  >
    <v-row justify="center" style="height: 100%">
      <v-col :md="fluidScreen ? 12 : 9" class="pa-0" style="height: 100%">
        <!-- 전자결재 내부 라우터 -->
        <router-view></router-view>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ApprovalMain",
  destroyed() {},
  computed: {
    routeName() {
      return this.$route.name;
    },
    fluidScreenPages() {
      return [
        "approval_home",
        "approval_config",
        "approval_config_preset",
        "approval_document_list",
        "approval_document_view_print"
      ];
    },
    greyBackgroundPages() {
      return ["approval", "approval_home"];
    },
    overflowHiddenPages() {
      return ["approval_config", "approval_config_preset"];
    },
    // {true}일 경우 container padding 없음
    fluidScreen() {
      return this.fluidScreenPages.includes(this.$route.name);
    },
    style() {
      let style = [];
      const bgGrey = this.greyBackgroundPages.includes(this.$route.name);
      const overflowHidden = this.overflowHiddenPages.includes(
        this.$route.name
      );
      style.push(bgGrey ? "background: #EEE" : "background: white");
      style.push(overflowHidden ? "overflow: hidden" : "overflow: auto");
      return style.map(s => `${s};`).join(" ");
    }
  },
  methods: {
    ...mapActions("splash", ["setShowSplash"]),
    ...mapActions("serviceConfig", ["loadConfig"]),
    ...mapActions("approvalConfig", ["fetchBasicConfig", "fetchManagedOrgans"])
  },
  async mounted() {
    // 전자결재 모듈 최초 진입시 필요한 설정 로드
    await this.loadConfig("APPROVAL");
    await this.fetchBasicConfig();
    await this.fetchManagedOrgans();
    this.setShowSplash(false);
  },
  watch: {
    routeName() {
      this.$refs["mainContainer"].scroll({
        top: 0,
        behavior: "auto"
      });
    }
  }
};
</script>

<style lang="scss">
.cr-approval-main {
  height: 100%;
  width: 100%;
}

/* input UI */
.v-input {
  &.v-text-field--outlined fieldset {
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.12);
  }
  &.v-text-field--outlined.v-input--is-focused fieldset {
    border-color: gray;
  }
  &.v-text-field--outlined.error--text fieldset {
    border-color: var(--v-error-base);
  }
}

/* 인쇄시 기본 스타일 */
@media print {
  /* 배경색 출력 처리 */
  body {
    -webkit-print-color-adjust: exact;
  }
  /* tooltip 출력 제외 */
  .v-tooltip__content {
    visibility: hidden !important;
  }
  /* wrap box 스타일 해제 */
  .v-application .v-main .v-main__wrap .cr-approval-main {
    height: auto !important;
    box-shadow: none !important;
  }
}
</style>
