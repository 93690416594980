<template>
  <v-tooltip bottom color="black" max-width="400" :disabled="!hasError">
    <template v-slot:activator="{ on, attrs }">
      <div
        v-on="on"
        v-bind="attrs"
        class="cr-list-item"
        :class="hasError ? 'invalid' : ''"
      >
        <!-- 결재유형 -->
        <span class="mr-2 text-no-wrap text-caption grey--text">
          {{ approvalTypeText }}
        </span>
        <!-- 결재자 정보-->
        <v-tooltip bottom color="black" max-width="300" :disabled="hasError">
          <template v-slot:activator="{ on, attrs }">
            <span
              v-on="on"
              v-bind="attrs"
              class=" text-truncate font-weight-regular black--text mr-auto"
            >
              {{ displayText }}
            </span>
          </template>
          <!-- 결재자 정보  tooltip-->
          <div class="d-flex flex-column align-center text-break text-center">
            <span>{{ approverName }}</span>
            <span v-if="approverIsUser" class="text-caption">
              {{ approverEmail }}
            </span>
            <span v-if="approverIsUser" class="text-caption">
              {{ approverOrganName }}
            </span>
          </div>
        </v-tooltip>
        <!-- 관리 버튼 그룹 -->
        <v-btn
          v-if="editable"
          icon
          small
          @click="$emit('click:remove', approvalLine)"
        >
          <v-icon small>mdi-close</v-icon>
        </v-btn>
        <v-btn v-if="editable" icon small class="cr-list-handle">
          <v-icon small>mdi-menu</v-icon>
        </v-btn>
      </div>
    </template>
    <!-- 결재선 에러 툴팁 -->
    <div style="white-space: pre-line">
      {{ errorText }}
    </div>
  </v-tooltip>
</template>

<script>
import { LINE_TYPE } from "@/approval/constant/approvalLine";

export default {
  emits: ["click:remove"],
  props: {
    approvalLine: {
      type: Object,
      required: true,
      description: "결재선 아이템"
    },
    editable: {
      type: Boolean,
      default: true,
      description: "편집 가능 여부"
    }
  },
  computed: {
    // 결재 유형
    approvalTypeText() {
      switch (this.approvalLine?.type) {
        case LINE_TYPE.APPROVAL:
          return "결재";
        case LINE_TYPE.HELP:
        case LINE_TYPE.HELP_ORGAN:
          return "협조";
        case LINE_TYPE.RECEIVE:
        case LINE_TYPE.RECEIVE_ORGAN:
          return "수신";
        case LINE_TYPE.CC:
          return "참조";
        case LINE_TYPE.SHARE:
        case LINE_TYPE.SHARE_ORGAN:
          return "공람";
        default:
          return "";
      }
    },
    displayText() {
      return this.approvalLine?.approver.isUser
        ? `${this.approvalLine?.approver.userName} | ${this.approvalLine?.approver.organName}`
        : this.approvalLine?.approver.organName;
    },
    approverIsUser() {
      return this.approvalLine?.approver?.isUser;
    },
    approverName() {
      return this.approvalLine?.approver.isUser
        ? this.approvalLine?.approver.userName
        : this.approvalLine?.approver.organName;
    },
    approverOrganName() {
      return this.approvalLine?.approver.organName;
    },
    approverEmail() {
      return this.approvalLine?.approver.isUser
        ? this.approvalLine?.approver.userEmail
        : undefined;
    },
    lineErrors() {
      return this.approvalLine?.errors ?? [];
    },
    hasError() {
      return this.lineErrors?.length > 0;
    },
    errorText() {
      return this.lineErrors.map(err => "※ " + err).join("\n");
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-list-item {
  display: flex;
  align-items: center;
  padding: 4px 12px;

  &.invalid {
    background-color: rgba(255, 23, 68, 0.05);
    border: thin dashed rgba(255, 23, 68, 1) !important;
    button {
      color: rgba(255, 23, 68, 1) !important;
    }
    span {
      color: rgba(0, 0, 0, 0.3) !important;
    }
  }
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
