<template>
  <div>
    <v-row no-gutters style="border-left: thin solid rgba(0, 0, 0, 0.08)">
      <!-- 상단 border -->
      <v-col cols="12" class="cr-option-wrap"></v-col>
      <!-- 기안제목 -->
      <v-col cols="12" class="cr-option-wrap">
        <div class="fill-height d-flex align-center">
          <div class="cr-option-title">
            <span>기안제목</span>
            <span v-if="editable" class="ml-2 accent--text">*</span>
          </div>
          <div class="cr-option-content">
            <!-- 편집모드 -->
            <div v-if="editable" class="d-flex align-end">
              <v-text-field
                v-model="_title"
                dense
                outlined
                hide-details
                placeholder="제목 입력"
                style="width: 100%; max-width: 600px;"
                :error-messages="titleErrors"
                @input="v$.title.$touch()"
                @blur="v$.title.$touch()"
              />
              <Counter :value="_title" max="50" class="ml-2" />
            </div>
            <!-- 읽기전용 -->
            <div v-else class="d-flex align-center fill-height">
              <span>{{ _title }}</span>
            </div>
          </div>
        </div>
      </v-col>
      <!-- 원본문서 -->
      <v-col v-if="originDoc" cols="12" class="cr-option-wrap">
        <div class="fill-height d-flex align-center">
          <div class="cr-option-title">원본 문서</div>
          <div class="cr-option-content">
            <DocumentMeta
              :id="originDoc.id"
              :title="originDoc.title"
              :draft-user="originDoc.draftUser"
              :draft-organ="originDoc.draftOrgan"
              :drafted-time-millis="originDoc.draftedTimeMillis"
              :is-deleted="originDoc.isDeleted"
              @click="openDocument(originDoc.id)"
            />
          </div>
        </div>
      </v-col>
      <!-- 파일첨부 -->
      <v-col
        v-if="editable || files.length > 0"
        cols="12"
        class="cr-option-wrap"
      >
        <div class="fill-height d-flex align-center">
          <div class="cr-option-title">파일첨부</div>
          <div class="cr-option-content">
            <!-- 업로드 버튼 (편집모드)-->
            <div
              v-if="editable"
              class="d-flex align-end"
              style="min-height: 48px"
            >
              <input
                id="file-input"
                type="file"
                hidden
                multiple
                @change="e => onUploadFileChange(e.target.files)"
              />
              <label v-if="editable" for="file-input" style="cursor: pointer">
                <v-icon class="cr-add-btn">mdi-plus</v-icon>
              </label>
              <span class="text-caption ml-2">
                {{ fileCountLimitText }}
              </span>
            </div>
            <!-- 파일 목록 (편집 모드) -->
            <div v-if="editable" class="d-flex flex-column mt-2">
              <SimpleFileItem
                v-for="(item, idx) in getItems"
                :key="idx"
                :name="item.name"
                :size="item.size"
                :status="item.status"
                editable
                @click:remove="REMOVE_FILE(idx)"
                @click="downloadFile(item)"
              />
            </div>
            <!-- 파일 목록 (읽기 전용) -->
            <div v-else class="d-flex flex-column">
              <SimpleFileItem
                v-for="(item, idx) in files"
                :key="idx"
                :name="item.name"
                :size="item.size"
                :status="item.status"
                @click="downloadFile(item)"
              />
            </div>
          </div>
        </div>
      </v-col>
      <!-- 참조문서 -->
      <v-col
        v-if="editable || _refDocs.length > 0"
        cols="12"
        class="cr-option-wrap"
      >
        <div class="fill-height d-flex align-center">
          <div class="cr-option-title">참조문서</div>
          <div class="cr-option-content">
            <!-- 편집모드 일때 -->
            <div
              v-if="editable"
              class="d-flex align-end"
              style="min-height: 48px"
            >
              <v-icon
                class="cr-add-btn"
                @click.prevent="showRefSelectDialog = !showRefSelectDialog"
              >
                mdi-plus
              </v-icon>
              <span class="text-caption ml-2">
                {{ refCountLimitText }}
              </span>
            </div>
            <div class="d-flex flex-column" :class="editable ? ' mt-2' : ''">
              <DocumentMeta
                v-for="refDoc in _refDocs"
                :key="refDoc.id"
                :id="refDoc.id"
                :title="refDoc.title"
                :draft-user="refDoc.draftUser"
                :draft-organ="refDoc.draftOrgan"
                :drafted-time-millis="refDoc.draftedTimeMillis"
                :is-deleted="refDoc.isDeleted"
                :close="editable"
                dense
                class="mb-1"
                @click="openDocument"
                @click:close="removeRefDoc"
              />
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <!-- 다이얼로그 -->
    <DocSelectDialog
      v-model="showRefSelectDialog"
      :init-data="_refDocs"
      @click:confirm="setRefDocs"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import SimpleFileItem from "@/approval/views/components/common/SimpleFileItem";
import storage from "@/commons/api/storage";
import DocSelectDialog from "@/approval/views/components/dialog/DocSelectDialog";
import DocumentMeta from "@/approval/views/components/document/DocumentMeta";
import Counter from "@/approval/views/components/common/Counter";
import useVuelidate from "@vuelidate/core";
import { maxLength, required } from "@vuelidate/validators";

export default {
  name: "DocumentBasicSection",
  setup: () => ({ v$: useVuelidate() }),
  components: { Counter, DocumentMeta, DocSelectDialog, SimpleFileItem },
  emits: ["update:title", "update:refDocs"],
  props: {
    editable: {
      type: Boolean,
      default: false,
      description: "편집모드 여부"
    },
    title: {
      type: String,
      default: () => "",
      description: "제목"
    },
    files: {
      type: Array,
      default: () => [],
      description: "첨부파일 DTO 목록"
    },
    refDocs: {
      type: Array,
      default: () => [],
      description: "참조 문서"
    },
    originDoc: {
      type: Object,
      default: () => {},
      description: "원본 문서"
    }
  },
  data: () => ({
    showRefSelectDialog: false
  }),
  computed: {
    ...mapGetters("approvalFileUploader", ["getItems"]),
    _title: {
      get() {
        return this.title;
      },
      set(val) {
        this.$emit("update:title", val);
      }
    },
    _refDocs: {
      get() {
        return this.refDocs;
      },
      set(val) {
        this.$emit("update:refDocs", val);
      }
    },
    titleErrors() {
      const { $dirty, required, maxLength } = this.v$.title;
      const errors = [];
      if (!$dirty) return errors;
      if (required.$invalid) errors.push("필수 입력사항 입니다.");
      if (maxLength.$invalid) errors.push("최대 50글자 입력 가능합니다.");
      return errors;
    },
    fileCountLimitText() {
      const maxCount = 10;
      const currentCount = this.getItems.length;
      return `${currentCount} / ${maxCount}`;
    },
    refCountLimitText() {
      const maxCount = 10;
      const currentCount = this.refDocs.length;
      return `${currentCount} / ${maxCount}`;
    }
  },
  methods: {
    ...mapActions("auth", ["checkToken"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapMutations("approvalFileUploader", ["ADD_FILE", "REMOVE_FILE"]),
    async validate() {
      await this.v$.$validate();
      return !this.v$.$invalid;
    },
    // 업로드할 파일 선택시
    onUploadFileChange(files) {
      const maxCount = 10;
      const maxSize = 25 * 1024 * 1024; // 25BMB
      const excludedFileNames = [];
      // 최대 업로드 갯수 초과시
      if (this.getItems.length + files.length > maxCount) {
        this.openSnackbar({
          message: "최대 10개까지 업로드 가능합니다."
        });
        return;
      }
      // 업로드 대기열에 추가
      Array.from(files).forEach(file => {
        // 용량 초과시 제외
        if (file.size > maxSize) {
          excludedFileNames.push(file.name);
          return;
        }
        this.ADD_FILE(file);
      });
      // 제외된 파일 있을 경우 알림
      if (excludedFileNames.length > 0) {
        let message = "업로드 가능한 파일 크기는 25MB 입니다.";
        for (const fileName of excludedFileNames) {
          message += `\n - ${fileName}`;
        }
        this.openSnackbar({ message });
      }
    },
    // 파일 다운로드
    async downloadFile(file) {
      // 토큰 체크 (만료된 경우 재발급 받도록)
      await this.checkToken();
      const token = storage.getAccessToken();
      const fileId = file?.id;
      window.open(
        `${process.env.VUE_APP_API_SERVER_URL}/api/approval/file/download/${fileId}?access_token=${token}`
      );
    },
    // 참조문서 등록시
    setRefDocs(refDocs) {
      if (refDocs.length > 10) {
        this.openSnackbar({
          message: "참조문서는 최대 10개까지 등록 가능합니다."
        });
        return;
      }
      this._refDocs = refDocs;
      this.showRefSelectDialog = false;
    },
    // 참조문서 삭제
    removeRefDoc(docId) {
      this._refDocs = this._refDocs.filter(({ id }) => id !== docId);
    },
    // 원본 문서 보기로 이동
    openDocument(docId) {
      window.open(`#/approval/doc/preview/${docId}`);
    }
  },
  validations() {
    return {
      title: { required, maxLength: maxLength(50) }
    };
  }
};
</script>

<style lang="scss" scoped>
.cr-add-btn {
  width: 40px;
  height: 40px;
  color: grey;
  background-color: white;
  border: 1px solid grey;
  &:after {
    opacity: 0 !important;
  }
}
.cr-option-wrap {
  border-bottom: thin solid rgba(0, 0, 0, 0.08);
  border-right: thin solid rgba(0, 0, 0, 0.08);

  .cr-option-title {
    height: 100%;
    min-width: 160px;
    display: flex;
    align-items: center;
    min-height: 50px;
    padding-left: 16px;
    background-color: #fafafa;
  }

  .cr-option-content {
    height: 100%;
    width: 100%;
    padding: 8px 16px;
    overflow: hidden;
  }
}
</style>
