<template>
  <div class="cr-table-wrap" style="height: 100%">
    <v-data-table
      :value="selectedItems"
      :items="items"
      :items-per-page="-1"
      item-key="id"
      hide-default-footer
      fixed-header
      show-select
      @input="val => $emit('update:selectedItems', val)"
    >
      <!-- 테이블 헤더 체크박스 -->
      <template v-slot:header.data-table-select="{ props, on }">
        <div class="d-flex align-center">
          <v-simple-checkbox v-bind="props" v-on="on" />
          <span>{{ `${selectedItems.length}개 선택됨` }}</span>
          <v-spacer></v-spacer>
          <v-tooltip
            v-if="selectedItems.length === 0"
            :disabled="!isMaxItems"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <div v-on="on" v-bind="attrs">
                <v-btn
                  tile
                  depressed
                  color="primary"
                  :disabled="isMaxItems"
                  @click="$emit('click:add')"
                >
                  <v-icon small>mdi-plus</v-icon>
                  <span>추가</span>
                </v-btn>
              </div>
            </template>
            최대 30개 까지 등록 가능합니다.
          </v-tooltip>
          <v-btn
            v-else
            tile
            depressed
            color="error"
            @click="$emit('click:delete', selectedItems)"
          >
            <v-icon small>mdi-trash-can-outline</v-icon>
            <span>삭제</span>
          </v-btn>
        </div>
      </template>
      <!-- row -->
      <template v-slot:item="{ item, isSelected, select }">
        <tr
          :class="viewItemId === item.id ? 'cr-focus' : ''"
          @click="$emit('click:item', item)"
        >
          <th>
            <div class="d-flex align-center">
              <v-simple-checkbox
                v-ripple="false"
                :value="isSelected"
                @input="select"
              />
              <div>
                <div class="cr-table-item-title text-body-1">
                  {{ item.name }}
                </div>
                <div class="cr-table-item-date text-caption">
                  {{ convertDate(item.createdTimeMillis) }}
                </div>
              </div>
            </div>
          </th>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { getFullDate } from "@/commons/utils/moment";

export default {
  emits: ["update:selectedItems", "click:item", "click:add", "click:delete"],
  props: {
    items: {
      type: Array,
      default: () => [],
      description: "프리셋 목록"
    },
    viewItem: {
      type: Object,
      default: () => {},
      description: "상세보기 열려있는 프리셋"
    },
    selectedItems: {
      type: Array,
      default: () => [],
      description: "선택된 프리셋 목록"
    }
  },
  data: () => ({}),
  computed: {
    viewItemId() {
      return this.viewItem?.id;
    },
    isMaxItems() {
      return this.items?.length >= 30;
    }
  },
  methods: {
    convertDate(timeMillis) {
      return getFullDate(timeMillis);
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-table-wrap::v-deep {
  height: 100%;
  .v-data-table {
    height: 100%;
    .v-data-table__wrapper {
      height: 100%;
      thead > tr > th {
        padding: 0 0 0 16px;
      }
      tbody > tr {
        :hover {
          cursor: pointer;
        }
        &.cr-focus {
          background-color: #efefef !important;
        }
        .cr-table-item-title,
        .cr-table-item-date {
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }
  }
}
</style>
