<template>
  <div
    class="py-4 py-md-15"
    :style="formVersionMismatch ? 'margin-top: 150px;' : ''"
  >
    <LoadingOverlay :value="loading" linear :text="loadingText" />
    <!-- 양식 사용 불가능한 경우 -->
    <FormErrorOverlay
      v-if="!loading && !formVersionMismatch && formError"
      :value="!!formError"
      :message="formError"
      title="양식을 사용할 수 없습니다."
    />
    <!-- 기안양식 수정된 경우 패널 -->
    <div v-if="formVersionMismatch" class="cr-top-banner">
      <p style="text-align: center">
        <span class="text-body-1 font-weight-light">
          {{
            form.isDeleted
              ? "관리자가 해당 양식을 삭제하여 더이상 수정 및 재사용이 불가능 합니다."
              : "관리자가 해당 양식을 수정하여 더이상 수정 및 재사용이 불가능 합니다."
          }}
        </span>
        <br />
        <span class="text-body-1 font-weight-light">
          해당 페이지는 읽기 전용입니다.
        </span>
      </p>
      <div>
        <div class="mt-1">
          <v-btn
            v-if="!form.isDeleted"
            outlined
            color="white"
            @click="routeDraftPage"
          >
            <span class="font-weight-light">변경된 양식으로 새로 작성</span>
          </v-btn>
          <v-btn
            v-if="draftType === 'EDIT'"
            outlined
            color="white"
            class="ml-1"
            @click="deleteDocument"
          >
            <span class="font-weight-light">문서 삭제</span>
          </v-btn>
        </div>
      </div>
    </div>
    <!-- 문서 제목 -->
    <SectionTitle :title="form.name" />
    <!-- 문서 기본 정보 -->
    <DocumentInfoSection
      v-bind="documentInfo"
      ref="documentInfo"
      class="mb-12"
      :editable="!formError"
      :fixed-draft-organ="isInnerDraft"
      :openTypeChangeable="form.openTypeChangeable"
      @update:openType="SET_OPEN_TYPE"
      @update:draftOrgan="changeDraftOrgan"
    />
    <!-- 결재선 -->
    <SectionTitle ref="approvalLineSection" title="결재선">
      <template v-if="!formError" v-slot:tail>
        <v-btn
          depressed
          color="primary"
          @click="SET_SHOW_APPROVAL_LINE_SELECT_DIALOG(true)"
        >
          {{ isInnerDraft ? `내부 결재선 설정` : `결재선 설정` }}
        </v-btn>
      </template>
    </SectionTitle>
    <!-- 결재선 유효하지 않을 경우 경고창 -->
    <v-expand-transition>
      <InfoPanel
        v-if="approvalLineErrorMessage"
        color="error"
        title="결재선이 유효하지 않습니다."
        :content="approvalLineErrorMessage"
        class="mt-0 mb-4"
      />
    </v-expand-transition>
    <ApprovalLineExpansionPanel
      editable
      hide-status
      class="mb-12"
      :approval-lines="approvalLines"
      @update:approvalLines="updateApprovalLines"
    />
    <!-- 기안 내용 -->
    <SectionTitle title="기안내용" />
    <InfoPanel
      color="primary"
      title="작성 안내"
      :content="form.writeGuide"
      class="mt-0 mb-4"
    />
    <!-- 문서 내용 -->
    <DocumentBasicSection
      ref="documentBasic"
      :editable="!formError"
      :title="title"
      :ref-docs="refDocs"
      :origin-doc="originDoc"
      :files="files"
      class="mb-3"
      @update:title="SET_TITLE"
      @update:refDocs="SET_REF_DOCS"
    />
    <!-- 템플릿 내용 -->
    <TemplateMain
      v-if="templateFrames && templateFrames.length > 0"
      ref="templateMain"
      class="mb-3"
      :editable="!formError"
      :value="templateBody"
      :templateFrames="templateFrames"
      @input="SET_TEMPLATE_BODY"
    />
    <!-- 본문 에디터 -->
    <TinymceEditor
      v-if="editorShow"
      style="width: 100%;"
      class="pa-0 pb-4"
      :useImage="true"
      :useImagePaste="false"
      :callback="imageUploadCallback"
    />
    <!-- 버튼 그룹 -->
    <div v-if="!formError" class="d-flex justify-center">
      <v-btn
        v-if="!isInnerDraft"
        dark
        color="grey"
        class="mr-2"
        min-width="100"
        @click="saveDocument"
      >
        임시 저장
      </v-btn>
      <v-btn dark color="primary" min-width="100" @click="draftDocument">
        상신
      </v-btn>
      <v-btn
        v-if="draftType === 'EDIT'"
        class="ml-2"
        dark
        color="error"
        min-width="100"
        @click="deleteDocument"
      >
        삭제
      </v-btn>
    </div>
    <!-- 다이얼로그 -->
    <ApprovalLineSelectDialog
      :value="showApprovalLineSelectDialog"
      :select-type="approvalLineDialogType"
      :show-preset-menu="!isInnerDraft"
      :approval-lines="approvalLines"
      :draftUser="draftUser"
      :draftOrgan="draftOrgan"
      persistent
      scrollable
      @input="SET_SHOW_APPROVAL_LINE_SELECT_DIALOG"
      @submit="applySelectedData"
    />
    <FileUploadDialog />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import SectionTitle from "@/approval/views/components/common/SectionTitle";
import DocumentInfoSection from "@/approval/views/components/document/DocumentInfoSection";
import DocumentBasicSection from "@/approval/views/components/document/DocumentBasicSection";
import InfoPanel from "@/approval/views/components/common/InfoPanel";
import ApprovalLineExpansionPanel from "@/approval/views/components/approvalline/ApprovalLineExpansionPanel";
import ApprovalLineSelectDialog from "@/approval/views/components/dialog/ApprovalLineSelectDialog";
import LoadingOverlay from "@/approval/views/components/common/LoadingOverlay";
import TemplateMain from "@/approval/views/components/template/TemplateMain";
import TinymceEditor from "@/commons/views/editor/tinymce/TinymceEditor";
import FileUploadDialog from "@/approval/views/components/dialog/FileUploadDialog";
import { uploadFile } from "@/approval/api/approval.api";
import { LINE_TYPE } from "@/approval/constant/approvalLine";
import { ApprovalLines } from "@/approval/utils/ApprovalLines";
import FormErrorOverlay from "@/approval/views/components/common/ErrorOverlay";

export default {
  components: {
    FormErrorOverlay,
    FileUploadDialog,
    TinymceEditor,
    TemplateMain,
    LoadingOverlay,
    ApprovalLineSelectDialog,
    ApprovalLineExpansionPanel,
    InfoPanel,
    DocumentInfoSection,
    DocumentBasicSection,
    SectionTitle
  },
  props: {
    draftType: {
      required: true,
      description: "기안 상신 유형 ['DRAFT','INNER_DRAFT','EDIT']"
    },
    formId: {
      description: "기안 양식 아이디 (일반 기안 상신일때)"
    },
    originDocId: {
      description: "원본 문서 아이디 (내부 기안 상신일때)"
    },
    originLineId: {
      description: "접수할 원본 문서의 결재선 (내부 기안 상신일때)"
    },
    docId: {
      description: "기존 문서 아이디 (수정일 경우)"
    }
  },
  data: () => ({
    /*
     * dirty${section} 변수는 해당 섹션의 유효성 검증이 페이지 초기화 이후
     * 1회 이상 수행되었는지 여부입니다. (경고 패널 표시여부 분기를 위함)
     */
    dirtyInfoSection: false,
    dirtyApprovalLineSection: false,
    dirtyBasicSection: false,
    dirtyTemplateSection: false
  }),
  computed: {
    ...mapState("approvalDraft", [
      "loading",
      "loadingText",
      "formVersionMismatch",
      "form",
      "openType",
      "docNum",
      "keepYear",
      "title",
      "content",
      "files",
      "refDocs",
      "originDoc",
      "approvalLines",
      "draftUser",
      "draftOrgan",
      "templateBody",
      "templateFrames",
      "showApprovalLineSelectDialog",
      "showFileUploadDialog",
      "leaveLock"
    ]),
    ...mapGetters("approvalDraft", [
      "isInnerDraft",
      "isAllLinesValid",
      "getLineErrors"
    ]),
    ...mapGetters("auth", ["getUserInfo"]),
    ...mapGetters("editor", {
      editorShow: "isShow",
      editorContent: "getContent"
    }),
    // 문서 정보 모델
    documentInfo() {
      return {
        formName: this.form.name,
        docNum: this.docNum,
        keepYear: this.keepYear,
        openType: this.openType,
        draftUser: this.draftUser,
        draftOrgan: this.draftOrgan
      };
    },
    // 결재 유형의 결재선 갯수
    approvalTypeLineCount() {
      return new ApprovalLines(this.approvalLines)
        .filterByType(LINE_TYPE.APPROVAL)
        .getCount();
    },
    approvalLineDialogType() {
      return this.isInnerDraft ? "INNER_DRAFT" : "DEFAULT";
    },
    approvalLineErrorMessage() {
      let errors = [];
      if (this.dirtyApprovalLineSection && this.approvalTypeLineCount === 0) {
        errors = [...errors, "최소 한명의 결재자를 지정해 주세요"];
      }
      errors = [...errors, ...this.getLineErrors];
      return errors.map(err => "- " + err).join("\n");
    },
    formError() {
      if (!this.form?.id) {
        return "기안양식을 불러오는데 실패했습니다.";
      }
      if (this.form.type === "INNER" && !this.isInnerDraft) {
        return "내부결재 양식은 접수시에만 사용 가능합니다.";
      }
      if (!this.form.isActive) {
        return "해당 양식은 비활성화 상태입니다.";
      }
      if (this.formVersionMismatch) {
        return "기안양식이 수정 또는 삭제되어 재사용할 수 없습니다.";
      }
      return false;
    }
  },
  methods: {
    ...mapActions("confirm", ["confirm"]),
    ...mapActions("approvalDraft", [
      "init",
      "draft",
      "saveTemp",
      "deleteDoc",
      "updateApprovalLines",
      "changeDraftOrgan",
      "validateByRules",
      "validateByServer"
    ]),
    ...mapMutations("approvalDraft", [
      "SET_TITLE",
      "SET_CONTENT",
      "SET_REF_DOCS",
      "SET_OPEN_TYPE",
      "SET_DRAFT_ORGAN",
      "SET_SHOW_FILE_UPLOAD_DIALOG",
      "SET_SHOW_APPROVAL_LINE_SELECT_DIALOG",
      "SET_TEMPLATE_BODY",
      "SET_APPROVAL_LINES",
      "RESET"
    ]),
    ...mapActions("approvalFileUploader", ["upload"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapMutations("editor", { SET_EDITOR_INIT: "SET_INIT" }),
    async initPage() {
      // dirty 초기화
      this.dirtyInfoSection = false;
      this.dirtyApprovalLineSection = false;
      this.dirtyBasicSection = false;
      this.dirtyTemplateSection = false;
      // 기본 정보 세팅
      await this.init({
        draftType: this.draftType,
        formId: this.formId,
        originDocId: this.originDocId,
        originLineId: this.originLineId,
        docId: this.docId
      });
      // 결재선 존재할 경우 유효성검사
      if (this.approvalLines.length) {
        this.validateByRules();
        await this.validateByServer();
        this.dirtyApprovalLineSection = true;
      }
      // 에디터 초기화
      setTimeout(() => {
        this.SET_EDITOR_INIT({
          editorId: "tiny_approval",
          content: this.content,
          height: 650
        });
      }, 0);
    },
    // 기안 작성 페이지 리디렉트
    routeDraftPage() {
      this.$router.push({
        name: "approval_draft",
        params: { formId: this.form.id }
      });
    },
    // 결재선 다이얼로그 선택 완료시
    applySelectedData({ approvalLines = [], draftOrgan = {} }) {
      this.changeDraftOrgan(draftOrgan);
      this.updateApprovalLines(approvalLines);
      this.SET_SHOW_APPROVAL_LINE_SELECT_DIALOG(false);
    },
    async validate() {
      // STEP.1 문서정보 부분 유효성 검사
      const validInfoSection = await this.$refs?.documentInfo?.validate?.();
      this.dirtyInfoSection = true;
      if (!validInfoSection) {
        const message = "공개여부와 기안부서를 입력해주세요";
        this.openSnackbar({ message });
        this.$refs.documentInfo.$el.scrollIntoView({
          behavior: "auto",
          block: "center"
        });
        return false;
      }
      // STEP.2 결재선 유효성 검사
      this.validateByRules();
      await this.validateByServer();
      this.dirtyApprovalLineSection = true;
      if (this.approvalLineErrorMessage) {
        const message = "결재선을 다시 확인해주세요";
        this.openSnackbar({ message });
        this.$refs.approvalLineSection.$el.scrollIntoView({
          behavior: "auto",
          block: "center"
        });
        return false;
      }
      // STEP.3 기본 정보 유효성 검사
      const validBasicSection = await this.$refs?.documentBasic?.validate?.();
      this.dirtyBasicSection = true;
      if (!validBasicSection) {
        const message = "기본 정보를 입력해주세요";
        this.openSnackbar({ message });
        this.$refs.documentBasic.$el.scrollIntoView({
          behavior: "auto",
          block: "center"
        });
        return false;
      }
      // STEP.4 템플릿 유효성 검사
      const isEmptyTemplate =
        !this.templateFrames || this.templateFrames.length === 0;
      const validTemplateBody = isEmptyTemplate
        ? true
        : await this.$refs?.templateMain?.validate?.();
      this.dirtyTemplateSection = true;
      if (!validTemplateBody) {
        const message = "템플릿 내용을 다시 확인해주세요";
        this.openSnackbar({ message });
        this.$refs.templateMain.$el.scrollIntoView({
          behavior: "auto",
          block: "center"
        });
        return false;
      }
      return true;
    },
    // 기안 상신
    async draftDocument() {
      const valid = await this.validate();
      if (!valid) return false;
      // 파일 업로드 후 기안 상신
      await this.confirm({
        headline: "기안 상신",
        message:
          "기안을 상신 하시겠습니까?<br>상신된 문서는 결재자가 처리한 이후에는 회수할 수 없습니다.",
        callback: () => this.upload({ callback: () => this.draft() })
      });
    },
    // 기안 임시저장
    async saveDocument() {
      const valid = await this.validate();
      if (!valid) return false;
      // 파일 업로드 후 임시저장
      this.confirm({
        headline: "임시저장",
        message:
          "기안을 임시저장 하시겠습니까?<br>저장된 문서는 [기안함/저장된] 목록에서 확인 가능합니다.",
        callback: () => this.upload({ callback: () => this.saveTemp() })
      });
    },
    // 기안 삭제
    deleteDocument() {
      this.confirm({
        headline: "기안 삭제",
        message: "기안을 삭제합니다.<br>계속 진행 하시겠습니까?",
        callback: () => this.deleteDoc()
      });
    },
    // 본문 이미지 첨부
    imageUploadCallback(callback) {
      const input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", "image/*");

      input.onchange = async function() {
        const file = this.files[0];
        const { data, status } = await uploadFile(file);
        // 업로드 실패시
        if (status !== 200) {
          const message =
            status === 507
              ? "전체 용량을 초과 했습니다."
              : "이미지 업로드를 실패했습니다.";
          return alert(message);
        }
        // 성공시 이미지 URL 치환
        const host_url = window.location.origin;
        callback(
          `${host_url}/api/approval/file/img/${
            data.id
          }?access_token=${localStorage.getItem("access_token")}`,
          { alt: "image file" }
        );
      };
      input.click();
    },
    // 문서 작성중 닫힘 방지 작동 핸들러
    beforeUnloadHandler(event) {
      event.preventDefault(); // 기본 동작을 취소합니다.
      event.returnValue = ""; // 필요에 따라 반환할 값 설정 가능합니다.
    }
  },
  watch: {
    // 라우팅 변경 감지하여 페이지 렌더링
    $props: {
      handler() {
        this.initPage();
      },
      deep: true
    },
    editorContent(content) {
      this.SET_CONTENT(content);
    }
  },
  mounted() {
    this.initPage();
  },
  created() {
    // 문서 작성중 닫힘 방지
    window.addEventListener("beforeunload", this.beforeUnloadHandler);
  },
  destroyed() {
    // 문서 작성중 닫힘 방지 제거
    window.removeEventListener("beforeunload", this.beforeUnloadHandler);
    this.RESET();
  },
  // 문서 작성중 이탈시
  beforeRouteLeave(to, from, next) {
    if (this.leaveLock) {
      const message =
        "이 페이지를 벗어나면 마지막 저장 후 수정된 내용은 저장되지 않습니다.";
      const leave = window.confirm(message);
      next(leave);
    } else {
      return next(true);
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-top-banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 170px;
  width: 100%;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  // 상단 테두리 라운딩처리
  border-radius: 8px 8px 0 0;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12) !important;
}
// 문서 작성 화면 내 global input style
::v-deep {
  .v-input {
    min-width: 200px;
  }
  .v-text-field--outlined fieldset {
    border-width: 1px;
  }
}
</style>
