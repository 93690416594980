<template>
  <div class="cr-counter" :class="exceeded ? 'exceeded' : ''">
    <span>{{ length }}</span>
    <span> / </span>
    <span>{{ max }}</span>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      default: "",
      required: true,
      type: String
    },
    max: {
      default: 0,
      required: true,
      type: [Number, String]
    }
  },
  computed: {
    length() {
      return this.value.length;
    },
    exceeded() {
      return this.value.length > this.max;
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-counter {
  flex: 0 1 auto;
  font-size: 12px;
  min-height: 12px;
  line-height: 12px;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.6);
  &.exceeded {
    color: var(--v-error-base);
  }
}
</style>
