<template>
  <v-btn
    depressed
    block
    class="cr-form-btn ma-0 pa-0"
    :class="outlined ? 'cr-outlined' : ''"
    style="background: white; color: black; height: 150px; width: 100%;"
    @click="$emit('formSelected', form)"
  >
    <div class="d-flex flex-column flex-fill fill-height ">
      <!-- 양식명 -->
      <div class="font-weight-bold pa-2 text-truncate" :style="nameStyle">
        {{ name }}
      </div>
      <v-divider />
      <!-- 양식 설명 -->
      <v-tooltip bottom color="black" max-width="200">
        <template v-slot:activator="{ on, attrs }">
          <div
            v-bind="attrs"
            v-on="on"
            class="d-flex justify-center align-center fill-height"
          >
            <div class="cr-multiline-ellipsis text-caption grey--text pa-1">
              {{ description }}
            </div>
          </div>
        </template>
        <!-- 툴팁 텍스트 -->
        <div class="text-center">
          <span>{{ name }}</span>
          <br />
          <span class="text-caption">{{ description }}</span>
        </div>
      </v-tooltip>
    </div>
  </v-btn>
</template>

<script>
import { themeColorToRGBA } from "@/commons/utils/colorUtils";

export default {
  name: "FormItem",
  props: {
    form: {
      type: Object,
      default: () => {},
      description: "양식"
    },
    outlined: {
      type: Boolean,
      default: false,
      description: "outlined 스타일 적용 여부"
    }
  },
  computed: {
    id() {
      return this.form?.id ?? 0;
    },
    name() {
      return this.form?.name ?? "";
    },
    description() {
      return this.form?.description ?? "";
    },
    nameStyle() {
      const color = themeColorToRGBA("accent", 0.05);
      return `background: ${color}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-form-btn::v-deep {
  .v-btn__content {
    display: block;
    width: 100%;
    height: 100%;
  }
  &.cr-outlined {
    border: thin solid rgba(0, 0, 0, 0.12);
  }
}
.cr-multiline-ellipsis {
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
</style>
