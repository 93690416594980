<template>
  <v-dialog
    :value="value"
    :scrollable="scrollable"
    :max-width="maxWidth"
    v-on="$listeners"
    persistent
    retain-focus
    no-click-animation
  >
    <v-form v-model="valid" ref="form">
      <v-card>
        <!-- 타이틀 -->
        <v-card-title>
          <span class="text-h6 font-weight-bold">반송</span>
        </v-card-title>
        <!-- 본문 -->
        <v-card-text>
          <p>반송할 결재선을 선택해 주세요</p>
          <!-- 반송할 결재선 선택 -->
          <v-select
            v-model="selectedLineId"
            :items="availableLines"
            :item-text="convertItemText"
            item-value="id"
            outlined
            hide-details
            class="mb-4"
          />
          <!-- 반송 사유 입력 -->
          <v-textarea
            v-model="comment"
            ref="textArea"
            rows="5"
            outlined
            autofocus
            placeholder="반송 사유를 입력해주세요."
            :rules="rules"
          />
        </v-card-text>
        <!-- 확인/취소 버튼-->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text outlined @click="$emit('input', false)">
            취소
          </v-btn>
          <v-btn color="accent" text outlined @click="confirm">
            확인
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { LINE_GROUP, LINE_TYPE } from "@/approval/constant/approvalLine";
import { mapGetters, mapState } from "vuex";
import { ApprovalLines } from "@/approval/utils/ApprovalLines";

export default {
  name: "ReturnDialog",
  emits: ["click:confirm"],
  props: {
    value: {
      type: Boolean,
      default: false,
      description: "다이얼로그 표시 여부 (v-model)"
    },
    document: {
      required: true,
      description: "대상 문서"
    },
    approvalLines: {
      required: true,
      description: "문서의 전체 결재선 목록"
    },
    persistent: {
      type: Boolean,
      default: false,
      description: "by vuetify dialog prop"
    },
    scrollable: {
      type: Boolean,
      default: false,
      description: "by vuetify dialog prop"
    },
    maxWidth: {
      type: Number,
      default: 600,
      description: "by vuetify dialog prop"
    }
  },
  data: () => ({
    valid: false,
    selectedLineId: null,
    comment: "",
    rules: [
      v => !!v || "필수 입력 사항입니다.",
      v => v?.length <= 400 || "최대 400 글자까지 입력 가능합니다."
    ]
  }),
  computed: {
    ...mapState("auth", ["userInfo"]),
    ...mapGetters("approvalConfig", ["getManagedOrganIds"]),
    availableLines() {
      return new ApprovalLines(this.approvalLines)
        .filterByGroup(LINE_GROUP.RECEIVE)
        .filterByStatus("PENDING_RECEIVE")
        .filter(this.checkLineOwner)
        .get();
    }
  },
  methods: {
    convertItemText(item) {
      const { type, approver } = item;
      const typeName =
        type === LINE_TYPE.RECEIVE
          ? "수신"
          : type === LINE_TYPE.RECEIVE_ORGAN
          ? "부서수신"
          : "";
      const approverName =
        type === LINE_TYPE.RECEIVE
          ? approver?.userName
          : type === LINE_TYPE.RECEIVE_ORGAN
          ? approver?.organName
          : "";
      return `[${typeName}] ${approverName}`;
    },
    // 로그인 유저가 {line}의 결재권자 인지 체크
    checkLineOwner(line = {}) {
      const { approver } = line;
      if (approver?.isUser) {
        return this.userInfo.id === approver?.userId;
      }
      if (approver?.isOrgan) {
        return this.getManagedOrganIds.includes(approver?.organId);
      }
      return false;
    },
    // 선택창 기본값 바인딩
    selectDefault() {
      const [{ id }] = this.availableLines;
      this.selectedLineId = id;
    },
    reset() {
      this.valid = false;
      this.comment = "";
      this.$refs.form.reset();
    },
    confirm() {
      this.$refs.form.validate();
      if (!this.valid) {
        this.$refs.textArea.focus();
        return;
      }
      this.$emit("click:confirm", {
        lineId: this.selectedLineId,
        comment: this.comment
      });
    }
  },
  watch: {
    value(show) {
      if (show) {
        this.selectDefault();
      } else {
        this.reset();
      }
    }
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
::v-deep .v-input {
  &.v-text-field--outlined {
    fieldset {
      border-width: 1px;
    }
    &.error--text fieldset {
      border-color: var(--v-error-base);
    }
  }
}
</style>
