<template>
  <div style="position: relative">
    <v-progress-linear v-if="loading" absolute indeterminate top />
    <!-- header -->
    <div class="d-flex">
      <span class="cr-section-title">최근 사용한 양식</span>
      <v-spacer></v-spacer>
      <v-btn text color="grey darken-1" @click="routeFormSelect">
        양식함
      </v-btn>
    </div>
    <v-divider class="my-2"></v-divider>
    <!-- body -->
    <div v-if="isEmpty" class="cr-no-item-wrap">
      최근 사용한 양식이 없습니다.
    </div>
    <v-row v-else class="mt-1 no-gutters">
      <v-col
        v-for="item in items"
        :key="item.id"
        cols="6"
        xl="4"
        class="pa-1"
        @click="routeDraft(item.id)"
      >
        <FormItem :form="item" outlined />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FormItem from "@/approval/views/components/form/FormItem";

export default {
  components: { FormItem },
  computed: {
    ...mapState("approvalDashboard", ["recentlyUseFormList"]),
    items() {
      return this.recentlyUseFormList.forms;
    },
    loading() {
      return this.recentlyUseFormList?.loading ?? false;
    },
    isEmpty() {
      return this.items?.length < 1 ?? true;
    }
  },
  methods: {
    routeFormSelect() {
      this.$router.push({ name: "approval_form_select" });
    },
    routeDraft(formId) {
      this.$router.push({ name: "approval_draft", params: { formId } });
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-no-item-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.38);
}
</style>
