<template>
  <v-dialog
    :value="getShow"
    :fullscreen="fullScreen"
    :scrollable="scrollable"
    :max-width="maxWidth"
    persistent
    retain-focus
    no-click-animation
  >
    <v-card>
      <!-- 타이틀 -->
      <v-card-title>
        <span class="text-h6 font-weight-bold">{{ title }}</span>
        <v-spacer></v-spacer>
        <v-btn icon right :ripple="false" @click="cancelUpload">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <!-- 전체 전송 현황 -->
      <v-card-text>
        <div class="py-2">
          <span style="color: #1976d2;">{{ `${completedCount}개` }}</span>
          <span style="color: #333;">{{ ` / ${totalCount}개` }}</span>
          <span class="ml-2" style="fontSize: 13px;">
            {{
              `(${byteCalculation(getTotalLoaded)} / ${byteCalculation(
                getTotalSize
              )})`
            }}
          </span>
        </div>
        <v-progress-linear rounded :value="getTotalProgress" height="30">
          <strong>{{ Math.ceil(getTotalProgress) }}%</strong>
        </v-progress-linear>
        <!-- 파일 개별 전송현황 -->
        <v-data-table
          dense
          fixed-header
          hide-default-footer
          item-key="key"
          class="pt-5"
          :headers="headers"
          :items="getItems"
          :items-per-page="10"
          :server-items-length="10"
        >
          <!-- 전송량 -->
          <template v-slot:[`item.size`]="{ item }">
            {{
              `${byteCalculation(item.loaded)} / ${byteCalculation(item.size)}`
            }}
          </template>
          <!-- 전송 상태 -->
          <template v-slot:[`item.progress`]="{ item }">
            <v-progress-linear
              v-if="item.status === 'UPLOADING'"
              :value="item.progress"
              rounded
              height="12"
            />
            <div v-else :style="getStatusStyle(item.status)">
              {{ getStatusText(item.status) }}
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { byteCalculation } from "@/commons/utils/byteCalculation";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "FileUploadDialog",
  props: {
    title: {
      type: String,
      default: "첨부파일 업로드",
      description: "다이얼로그 타이틀"
    },
    scrollable: {
      type: Boolean,
      default: false,
      description: "by vuetify dialog prop"
    },
    fullScreen: {
      type: Boolean,
      default: false,
      description: "by vuetify dialog prop"
    },
    maxWidth: {
      type: Number,
      default: 800,
      description: "by vuetify dialog prop"
    }
  },
  data: () => ({
    headers: [
      { text: "파일명", sortable: false, value: "name" },
      {
        text: "전송상태",
        sortable: false,
        width: 100,
        value: "progress"
      },
      {
        text: "전송량",
        sortable: false,
        width: 200,
        value: "size"
      }
    ]
  }),
  methods: {
    ...mapActions("approvalFileUploader", ["cancelUpload"]),
    byteCalculation(size) {
      return byteCalculation(size);
    },
    getStatusText(status) {
      switch (status) {
        case "UPLOADING":
          return "전송중";
        case "COMPLETE":
          return "전송완료";
        case "FAIL":
          return "실패";
        default:
          return "대기중";
      }
    },
    getStatusStyle(status) {
      const buildColorProperty = color => `color: ${color};`;
      switch (status) {
        case "UPLOADING":
          return buildColorProperty("blue");
        case "COMPLETE":
          return buildColorProperty("green");
        case "FAIL":
          return buildColorProperty("red");
        default:
          return buildColorProperty("black");
      }
    }
  },
  computed: {
    ...mapGetters("approvalFileUploader", [
      "getShow",
      "getItems",
      "isUploadCompleted",
      "getTotalLoaded",
      "getTotalSize",
      "getTotalProgress"
    ]),
    totalCount() {
      return this.getItems.length;
    },
    completedCount() {
      return this.getItems.filter(item => item.status === "COMPLETE").length;
    }
  },
  mounted() {}
};
</script>
<style lang="scss" scoped></style>
