<template>
  <TextFieldItem
    v-if="type === 'TEXT'"
    ref="fieldItem"
    v-model="computedModel"
    :templateFrame="templateFrame"
    :editable="editable"
  />
  <NumberFieldItem
    v-else-if="type === 'NUMBER'"
    ref="fieldItem"
    v-model="computedModel"
    :templateFrame="templateFrame"
    :editable="editable"
  />
  <AmountFieldItem
    v-else-if="type === 'AMOUNT'"
    ref="fieldItem"
    v-model="computedModel"
    :templateFrame="templateFrame"
    :editable="editable"
  />
  <DateFieldItem
    v-else-if="type === 'DATE'"
    ref="fieldItem"
    v-model="computedModel"
    :templateFrame="templateFrame"
    :editable="editable"
  />
  <DateRangeFieldItem
    v-else-if="type === 'DATE_RANGE'"
    ref="fieldItem"
    v-model="computedModel"
    :templateFrame="templateFrame"
    :editable="editable"
  />
  <SelectFieldItem
    v-else-if="type === 'SELECT'"
    ref="fieldItem"
    v-model="computedModel"
    :templateFrame="templateFrame"
    :editable="editable"
  />
  <UserSelectFieldItem
    v-else-if="type === 'USER_SELECT'"
    ref="fieldItem"
    v-model="computedModel"
    :templateFrame="templateFrame"
    :editable="editable"
  />
  <OrganSelectFieldItem
    v-else-if="type === 'ORGAN_SELECT'"
    ref="fieldItem"
    v-model="computedModel"
    :templateFrame="templateFrame"
    :editable="editable"
  />
  <TableFieldItem
    v-else-if="type === 'TABLE'"
    ref="fieldItem"
    v-model="computedModel"
    :templateFrame="templateFrame"
    :editable="editable"
  />
</template>

<script>
export default {
  name: "TemplateFieldItem",
  components: {
    /* 순환참조 때문에 동적 import */
    TextFieldItem: () =>
      import(
        "@/approval/views/components/template/TemplateFieldItem/TextFieldItem"
      ),
    NumberFieldItem: () =>
      import(
        "@/approval/views/components/template/TemplateFieldItem/NumberFieldItem"
      ),
    SelectFieldItem: () =>
      import(
        "@/approval/views/components/template/TemplateFieldItem/SelectFieldItem"
      ),
    DateFieldItem: () =>
      import(
        "@/approval/views/components/template/TemplateFieldItem/DateFieldItem"
      ),
    DateRangeFieldItem: () =>
      import(
        "@/approval/views/components/template/TemplateFieldItem/DateRangeFieldItem"
      ),
    TableFieldItem: () =>
      import(
        "@/approval/views/components/template/TemplateFieldItem/TableFieldItem"
      ),
    UserSelectFieldItem: () =>
      import(
        "@/approval/views/components/template/TemplateFieldItem/UserSelectFieldItem"
      ),
    OrganSelectFieldItem: () =>
      import(
        "@/approval/views/components/template/TemplateFieldItem/OrganSelectFieldItem"
      ),
    AmountFieldItem: () =>
      import(
        "@/approval/views/components/template/TemplateFieldItem/AmountFieldItem"
      )
  },
  props: {
    value: {
      type: Object,
      description: "필드 데이타 (v-model)"
    },
    templateFrame: {
      type: Object,
      required: true,
      description: "필드 속성"
    },
    editable: {
      type: Boolean,
      default: false,
      description: "편집 모드"
    }
  },
  computed: {
    // v-model
    computedModel: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    },
    // 컴포넌트 타입
    type() {
      return this.templateFrame?.type;
    },
    // 필수 입력 여부
    required() {
      return this.templateFrame?.required ?? false;
    }
  },
  methods: {
    // 상위 컴포넌트에서 호출용도 (btnAction 정의 되어 있을 경우 실행)
    btnAction() {
      if (!this.editable) return;
      this.$refs.fieldItem.btnAction?.();
    },
    async validate() {
      return (await this.$refs?.fieldItem?.validate?.()) || false;
    }
  }
};
</script>
<style lang="scss" scoped></style>
