<template>
  <v-dialog
    :value="value"
    :scrollable="scrollable"
    :max-width="maxWidth"
    v-on="$listeners"
    persistent
    retain-focus
    no-click-animation
  >
    <v-form ref="form" v-model="valid">
      <v-card>
        <!-- 타이틀 -->
        <v-card-title>
          <span class="text-h6 font-weight-bold">{{ title }}</span>
        </v-card-title>
        <!-- 에디터 -->
        <v-card-text class="pb-0">
          <v-textarea
            v-model="comment"
            outlined
            autofocus
            ref="textArea"
            rows="5"
            counter="400"
            label="의견"
            placeholder="의견을 입력해 주세요."
            :rules="rules"
          ></v-textarea>
        </v-card-text>
        <!-- 확인/취소 버튼-->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text outlined @click="$emit('input', false)">
            취소
          </v-btn>
          <v-btn text outlined color="accent" @click="onClickConfirm">
            확인
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: "OpinionEditDialog",
  emits: ["editComment"],
  props: {
    value: {
      type: Boolean,
      default: false,
      description: "다이얼로그 표시 여부 (v-model)"
    },
    title: {
      type: String,
      default: "의견 수정",
      description: "다이얼로그 타이틀"
    },
    opinionId: {
      required: true,
      description: "수정할 결재 의견 아이디"
    },
    opinionComment: {
      description: "기존 결재의견 내용"
    },
    persistent: {
      type: Boolean,
      default: false,
      description: "by vuetify dialog prop"
    },
    scrollable: {
      type: Boolean,
      default: false,
      description: "by vuetify dialog prop"
    },
    maxWidth: {
      type: Number,
      default: 600,
      description: "by vuetify dialog prop"
    }
  },
  data: () => ({
    valid: false,
    comment: {},
    rules: [
      v => !!v || "필수 입력 사항입니다.",
      v => v.length <= 400 || "최대 400 글자까지 입력 가능합니다."
    ]
  }),
  methods: {
    onClickConfirm() {
      this.$refs.form.validate();
      if (!this.valid) {
        this.$refs.textArea.focus();
        return;
      }
      this.$emit("editComment", { id: this.opinionId, comment: this.comment });
    }
  },
  mounted() {
    // 기존 결재의견 내용 삽입
    if (this.opinionComment) this.comment = this.opinionComment;
  }
};
</script>

<style lang="scss" scoped>
::v-deep .v-input {
  &.v-text-field--outlined {
    fieldset {
      border-width: 1px;
    }
    &.error--text fieldset {
      border-color: var(--v-error-base);
    }
  }
}
</style>
