<template>
  <v-tooltip
    top
    color="black"
    :max-width="toolTipMaxWidth"
    :nudge-left="nudgeLeft"
    :nudge-bottom="12"
  >
    <template v-slot:activator="{ attrs, on }">
      <div
        ref="div"
        v-on="on"
        class="cr-link d-flex align-center fill-height"
        @click="$emit('click:title')"
      >
        <span v-bind="attrs" ref="span" class="text-truncate">{{ title }}</span>
      </div>
    </template>
    <span>{{ title }}</span>
  </v-tooltip>
</template>
<script>
export default {
  emits: ["click:title"],
  props: {
    title: { description: "제목" }
  },
  data() {
    return {
      divWidth: 0,
      spanWidth: 0,
      toolTipMaxWidth: 500
    };
  },
  computed: {
    // 툴팁 좌측상단 고정을 위해 nudge 계산
    nudgeLeft() {
      const divWidth = this.divWidth;
      const spanWidth =
        this.spanWidth > this.toolTipMaxWidth
          ? this.toolTipMaxWidth
          : this.spanWidth;
      return divWidth / 2 - spanWidth / 2;
    }
  },
  mounted() {
    // 마운트된 후에 요소의 너비를 가져와서 데이터에 저장
    this.$nextTick(() => {
      this.divWidth = this.$refs?.div?.offsetWidth ?? 0;
      this.spanWidth = this.$refs?.span?.offsetWidth ?? 0;
    });
  }
};
</script>

<style lang="scss" scoped>
.cr-link {
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
