<template>
  <v-row no-gutters class="justify-center">
    <!-- 조직도 -->
    <v-col
      :cols="$vuetify.breakpoint.mdAndUp ? 0 : 12"
      :style="$vuetify.breakpoint.mdAndUp ? 'height: 600px' : 'height: 250px;'"
      class="overflow-x-hidden overflow-y-auto cr-section-border"
    >
      <OrganSection />
    </v-col>
    <!-- 버튼 그룹 -->
    <v-col
      :cols="$vuetify.breakpoint.mdAndUp ? 0 : 12"
      :style="$vuetify.breakpoint.mdAndUp ? 'max-width:88px;' : 12"
      :class="$vuetify.breakpoint.mdAndUp ? 'flex-column px-4' : 'py-4'"
      class="d-flex justify-center"
    >
      <BtnGroup />
    </v-col>
    <!-- 선택된 결재선 목록 -->
    <v-col
      :cols="$vuetify.breakpoint.mdAndUp ? 0 : 12"
      :style="$vuetify.breakpoint.mdAndUp ? 'height: 600px' : 'height: 400px;'"
      class="overflow-hidden cr-section-border"
    >
      <SelectedSection ref="selectedSection" />
    </v-col>
  </v-row>
</template>

<script>
import OrganSection from "@/approval/views/components/selector/ApprovalLineSelector/OrganSection";
import BtnGroup from "@/approval/views/components/selector/ApprovalLineSelector/BtnGroup";
import SelectedSection from "@/approval/views/components/selector/ApprovalLineSelector/SelectedSection";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  components: { SelectedSection, BtnGroup, OrganSection },
  emits: ["submit"],
  props: {
    selectType: {
      type: String,
      default: () => "DEFAULT",
      description:
        "결재선 선택 타입 (일반, 내부결재선 변경, 상위 결재선 변경 등등)"
    },
    approvalLines: {
      type: Array,
      default: () => [],
      description: "결재선 목록 (초기값)"
    },
    draftUser: {
      type: Object,
      required: true,
      description: "기안자 (초기값)"
    },
    draftOrgan: {
      type: Object,
      required: true,
      description: "기안 부서 (초기값)"
    }
  },
  data: () => ({}),
  computed: {
    ...mapState("approvalLineSelector", {
      selectedDraftOrgan: "draftOrgan",
      selectedApprovalLines: "approvalLines"
    }),
    ...mapGetters("approvalLineSelector", ["isAllValid", "getErrors"]),
    ...mapGetters("auth", ["getUserInfo"])
  },
  methods: {
    ...mapActions("approvalLineSelector", [
      "init",
      "validateByRules",
      "validateByServer"
    ]),
    // 편집기에서 편집한 결재선으로 선택완료
    async submit() {
      const valid = await this.$refs.selectedSection.validate();
      if (valid) {
        // 편집된 결재선과 기안부서를 이벤트로 전송
        this.$emit("submit", {
          approvalLines: this.selectedApprovalLines,
          draftOrgan: this.selectedDraftOrgan
        });
      }
      return valid;
    }
  },
  created() {
    // {props}로 전달된 초기값으로 {store}초기화
    this.init({
      selectType: this.selectType,
      approvalLines: this.approvalLines,
      draftUser: this.draftUser,
      draftOrgan: this.draftOrgan
    });
  }
};
</script>

<style lang="scss" scoped>
.cr-section-border {
  border-radius: 5px;
  border: thin solid rgb(0, 0, 0, 25%);
}
</style>
