<template>
  <v-overlay :value="value" @input="val => $emit('input', val)">
    <v-card light :max-width="maxWidth" class="pa-5">
      <v-card-text class="d-flex flex-column align-center">
        <v-icon v-if="showIcon" color="error" size="45" class="mb-1">
          mdi-alert
        </v-icon>
        <span class="headline text-h6 font-weight-bold black--text">
          {{ title }}
        </span>
        <div>{{ message }}</div>
        <div class="d-flex align-center mt-6">
          <v-btn
            v-if="showBackButton"
            tile
            depressed
            v-text="$t('common.뒤로')"
            width="100"
            height="40"
            class="mr-1"
            @click="routeToBack"
          />
          <v-btn
            tile
            depressed
            v-text="$t('common.홈으로')"
            width="100"
            height="40"
            @click="routeToHome"
          />
        </div>
      </v-card-text>
    </v-card>
  </v-overlay>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: true,
      description: "v-model"
    },
    maxWidth: {
      type: [String, Number],
      default: 600,
      description: "다이얼로그 크기"
    },
    showIcon: {
      type: Boolean,
      default: true,
      description: "아이콘 표시 여부"
    },
    title: {
      type: String,
      description: "제목"
    },
    message: {
      type: String,
      required: true,
      description: "에러 메시지"
    }
  },
  data() {
    return {
      showBackButton: false
    };
  },
  methods: {
    routeToBack() {
      this.$router.go(-1);
    },
    routeToHome() {
      this.$router.push({ name: "approval_home" });
    }
  },
  mounted() {
    this.showBackButton = window?.history?.length > 2;
  }
};
</script>

<style scoped></style>
