<template>
  <!-- 문서 정보 툴팁-->
  <v-tooltip bottom color="black" max-width="300">
    <template v-slot:activator="{ on, attrs }">
      <div
        v-ripple
        v-on="on"
        v-bind="attrs"
        class="cr-item-wrap"
        @click="$emit('click')"
      >
        <div style="height: 100px">
          <!-- 문서 제목 -->
          <div
            class="text-no-wrap text-truncate font-weight-bold subtitle-2 mb-2"
          >
            {{ title }}
          </div>
          <!-- 기안일 -->
          <div class="d-flex text-caption">
            <span class="mr-auto text-no-wrap">기안일 </span>
            <span class="ml-4 text-no-wrap text-truncate">
              {{ _draftedTimeMillis }}
            </span>
          </div>
          <!-- 양식명 -->
          <div class="d-flex text-caption">
            <span class="mr-auto text-no-wrap">양식명 </span>
            <span class="ml-4 text-no-wrap text-truncate">
              {{ formName }}
            </span>
          </div>
          <!-- 결재대기자 -->
          <div v-if="nextApprover" class="d-flex text-caption">
            <span class="mr-auto text-no-wrap">결재대기자</span>
            <span class="ml-4 text-no-wrap text-truncate">
              {{ nextApproverName }}
            </span>
          </div>
        </div>
        <!-- 기안 상태 -->
        <div class="subtitle-2 d-flex align-end" style="height: 36px">
          <span class="mr-auto" :style="statusStyle">
            {{ _status }}
          </span>
          <v-spacer></v-spacer>
          <v-btn
            v-if="status === 'COMPLETE' && docReusable"
            small
            tile
            depressed
            @click.stop="$emit('click:reuse', id)"
          >
            재기안
          </v-btn>
        </div>
      </div>
    </template>
    <!-- 툴팁 텍스트 -->
    <div class="d-flex flex-column align-center text-break text-center">
      <span>{{ title }}</span>
      <span class="text-caption">
        {{ formName }}
      </span>
      <span v-if="nextApprover" class="text-caption">
        {{ `결재대기자: ${nextApproverName}` }}
      </span>
    </div>
  </v-tooltip>
</template>

<script>
import moment from "moment";

export default {
  emits: ["click", "click:reuse"],
  props: {
    id: {
      description: ""
    },
    title: {
      description: ""
    },
    status: {
      description: ""
    },
    formName: {
      description: ""
    },
    docReusable: {
      description: ""
    },
    nextApprover: {
      description: ""
    },
    draftedTimeMillis: {
      description: ""
    }
  },
  computed: {
    _draftedTimeMillis() {
      return moment(this.draftedTimeMillis).format("YYYY.MM.DD (dd)");
    },
    _status() {
      switch (this.status) {
        case "DRAFT":
          return "상신";
        case "REJECT":
          return "반려";
        case "PROGRESS":
          return "진행중";
        case "COMPLETE":
          return "완료";
        default:
          return "-";
      }
    },
    statusStyle() {
      switch (this.status) {
        case "DRAFT":
          return "color: #757575";
        case "REJECT":
          return "color: #ff8080";
        case "PROGRESS":
          return "color: #518df7; font-weight: bold";
        case "COMPLETE":
          return "color: #65b746; font-weight: bold";
        default:
          return "-";
      }
    },
    nextApproverName() {
      return this.nextApprover?.userName ?? this.nextApprover?.organName ?? "";
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-item-wrap {
  display: flex;
  flex-direction: column;
  padding: 12px;
  cursor: pointer;
  background-color: white;
  border: thin solid rgba(0, 0, 0, 0.12);
  &:hover {
    background: rgb(210 210 210 / 15%) !important;
  }
}
</style>
