/**
 * 각 문서함별 목록 헤더 정의
 */
const B0X_HEADERS = {
  ledger: [
    {
      text: "문서번호",
      value: "docNum",
      align: "center",
      sortable: false
    },
    {
      text: "기안양식",
      value: "formName",
      align: "center",
      sortable: false
    },
    {
      text: "기안제목",
      value: "title",
      width: "100%",
      sortable: false
    },
    {
      text: "기안자",
      value: "draftUser",
      align: "center",
      sortable: false
    },
    {
      text: "상신일자",
      value: "draftedTimeMillis",
      align: "center",
      sortable: true
    },
    {
      text: "최종승인일자",
      value: "completedTimeMillis",
      align: "center",
      sortable: true,
      isDefaultSort: true
    }
  ],
  "draft/draft": [
    {
      text: "기안양식",
      value: "formName",
      align: "center",
      sortable: false
    },
    {
      text: "기안제목",
      value: "title",
      width: "100%",
      sortable: false
    },
    {
      text: "결재대기자",
      value: "nextApprover",
      align: "center",
      sortable: false
    },
    {
      text: "결재 변동 일자",
      value: "approvalChangedTimeMillis",
      align: "center",
      sortable: true
    },
    {
      text: "상신일자",
      value: "draftedTimeMillis",
      align: "center",
      sortable: true,
      isDefaultSort: true
    }
  ],
  "draft/complete": [
    {
      text: "문서번호",
      value: "docNum",
      align: "center",
      sortable: false
    },
    {
      text: "기안양식",
      value: "formName",
      align: "center",
      sortable: false
    },
    {
      text: "기안제목",
      value: "title",
      width: "100%",
      sortable: false
    },
    {
      text: "상신일자",
      value: "draftedTimeMillis",
      align: "center",
      sortable: true
    },
    {
      text: "최종승인일자",
      value: "completedTimeMillis",
      sortable: true,
      align: "center",
      isDefaultSort: true
    }
  ],
  "draft/temp": [
    {
      text: "기안양식",
      value: "formName",
      align: "center",
      sortable: false
    },
    {
      text: "기안제목",
      value: "title",
      width: "100%",
      sortable: false
    },
    {
      text: "저장일자",
      value: "savedTimeMillis",
      align: "center",
      sortable: true,
      isDefaultSort: true
    }
  ],
  "draft/reject": [
    {
      text: "기안양식",
      value: "formName",
      align: "center",
      sortable: false
    },
    {
      text: "기안제목",
      value: "title",
      width: "100%",
      sortable: false
    },
    {
      text: "반려자",
      value: "rejectApprover",
      align: "center",
      sortable: false
    },
    {
      text: "상신일자",
      value: "draftedTimeMillis",
      align: "center",
      sortable: true
    },
    {
      text: "반려일자",
      value: "rejectedTimeMillis",
      align: "center",
      sortable: true,
      isDefaultSort: true
    }
  ],
  "draft/return": [
    {
      text: "문서번호",
      value: "docNum",
      align: "center",
      sortable: false
    },
    {
      text: "기안양식",
      value: "formName",
      align: "center",
      sortable: false
    },
    {
      text: "기안제목",
      value: "title",
      width: "100%",
      sortable: false
    },
    {
      text: "반송자",
      value: "returnApprover",
      align: "center",
      sortable: false
    },
    {
      text: "상신일자",
      value: "draftedTimeMillis",
      align: "center",
      sortable: true
    },
    {
      text: "반송일자",
      value: "returnedTimeMillis",
      align: "center",
      sortable: true,
      isDefaultSort: true
    }
  ],
  "draft/retrieve": [
    {
      text: "기안양식",
      value: "formName",
      align: "center",
      sortable: false
    },
    {
      text: "기안제목",
      value: "title",
      width: "100%",
      sortable: false
    },
    {
      text: "상신일자",
      value: "draftedTimeMillis",
      align: "center",
      sortable: true
    },
    {
      text: "회수일자",
      value: "retrievedTimeMillis",
      align: "center",
      sortable: true,
      isDefaultSort: true
    }
  ],
  "approve/before": [
    {
      text: "기안양식",
      value: "formName",
      align: "center",
      sortable: false
    },
    {
      text: "기안제목",
      value: "title",
      width: "100%",
      sortable: false
    },
    {
      text: "기안자",
      value: "draftUser",
      align: "center",
      sortable: false
    },
    {
      text: "결재구분",
      value: "approveType",
      align: "center",
      sortable: false
    },
    {
      text: "상신일자",
      value: "draftedTimeMillis",
      align: "center",
      sortable: true,
      isDefaultSort: true
    }
  ],
  "approve/progress": [
    {
      text: "기안양식",
      value: "formName",
      align: "center",
      sortable: false
    },
    {
      text: "기안제목",
      value: "title",
      width: "100%",
      sortable: false
    },
    {
      text: "기안자",
      value: "draftUser",
      align: "center",
      sortable: false
    },
    {
      text: "결재대기중",
      value: "nextApprover",
      align: "center",
      sortable: false
    },
    // {
    //   text: "결재구분",
    //   value: "approveType",
    //   align: "center",
    //   sortable: false
    // },
    {
      text: "상신일자",
      value: "draftedTimeMillis",
      align: "center",
      sortable: true,
      isDefaultSort: true
    }
  ],
  "approve/complete": [
    {
      text: "문서번호",
      value: "docNum",
      align: "center",
      sortable: false
    },
    {
      text: "기안양식",
      value: "formName",
      align: "center",
      sortable: false
    },
    {
      text: "기안제목",
      value: "title",
      width: "100%",
      sortable: false
    },
    {
      text: "기안자",
      value: "draftUser",
      align: "center",
      sortable: false
    },
    {
      text: "상신일자",
      value: "draftedTimeMillis",
      align: "center",
      sortable: true
    },
    {
      text: "최종승인일자",
      value: "completedTimeMillis",
      align: "center",
      sortable: true,
      isDefaultSort: true
    }
  ],
  "approve/reject": [
    {
      text: "기안양식",
      value: "formName",
      align: "center",
      sortable: false
    },
    {
      text: "기안제목",
      value: "title",
      width: "100%",
      sortable: false
    },
    {
      text: "기안자",
      value: "draftUser",
      align: "center",
      sortable: false
    },
    {
      text: "상신일자",
      value: "draftedTimeMillis",
      align: "center",
      sortable: true
    },
    {
      text: "반려일자",
      value: "rejectedTimeMillis",
      align: "center",
      sortable: true,
      isDefaultSort: true
    }
  ],
  "receive/before": [
    {
      text: "문서번호",
      value: "docNum",
      align: "center",
      sortable: false
    },
    {
      text: "기안양식",
      value: "formName",
      align: "center",
      sortable: false
    },
    {
      text: "기안제목",
      value: "title",
      width: "100%",
      sortable: false
    },
    {
      text: "기안자",
      value: "draftUser",
      align: "center",
      sortable: false
    },
    {
      text: "상신일자",
      value: "draftedTimeMillis",
      align: "center",
      sortable: true
    },
    {
      text: "최종승인일자",
      value: "completedTimeMillis",
      align: "center",
      sortable: true,
      isDefaultSort: true
    }
  ],
  "receive/complete": [
    {
      text: "문서번호",
      value: "docNum",
      align: "center",
      sortable: false
    },
    {
      text: "기안양식",
      value: "formName",
      align: "center",
      sortable: false
    },
    {
      text: "기안제목",
      value: "title",
      width: "100%",
      sortable: false
    },
    {
      text: "기안자",
      value: "draftUser",
      align: "center",
      sortable: false
    },
    {
      text: "수신여부",
      value: "receiveStatus",
      align: "center",
      sortable: false
    },
    {
      text: "상신일자",
      value: "draftedTimeMillis",
      align: "center",
      sortable: true
    },
    {
      text: "최종승인일자",
      value: "completedTimeMillis",
      align: "center",
      sortable: true
    },
    {
      text: "수신완료일자",
      value: "receivedTimeMillis",
      align: "center",
      sortable: true,
      isDefaultSort: true
    }
  ],
  share: [
    {
      text: "문서번호",
      value: "docNum",
      align: "center",
      sortable: false
    },
    {
      text: "기안양식",
      value: "formName",
      align: "center",
      sortable: false
    },
    {
      text: "기안제목",
      value: "title",
      width: "100%",
      sortable: false
    },
    {
      text: "기안자",
      value: "draftUser",
      align: "center",
      sortable: false
    },
    {
      text: "상신일자",
      value: "draftedTimeMillis",
      align: "center",
      sortable: true
    },
    {
      text: "최종승인일자",
      value: "completedTimeMillis",
      align: "center",
      sortable: true,
      isDefaultSort: true
    }
  ],
  cc: [
    {
      text: "문서번호",
      value: "docNum",
      align: "center",
      sortable: false
    },
    {
      text: "기안양식",
      value: "formName",
      align: "center",
      sortable: false
    },
    {
      text: "기안제목",
      value: "title",
      width: "100%",
      sortable: false
    },
    {
      text: "기안자",
      value: "draftUser",
      align: "center",
      sortable: false
    },
    {
      text: "진행상태",
      value: "status",
      align: "center",
      sortable: false
    },
    {
      text: "최종승인일자",
      value: "completedTimeMillis",
      align: "center",
      sortable: true
    },
    {
      text: "상신일자",
      value: "draftedTimeMillis",
      align: "center",
      sortable: true,
      isDefaultSort: true
    }
  ],
  "organ/help": [
    {
      text: "기안양식",
      value: "formName",
      align: "center",
      sortable: false
    },
    {
      text: "기안제목",
      value: "title",
      width: "100%",
      sortable: false
    },
    {
      text: "기안자",
      value: "draftUser",
      align: "center",
      sortable: false
    },
    {
      text: "처리자",
      value: "receiptUser",
      align: "center",
      sortable: false
    },
    {
      text: "진행상태",
      value: "receiptStatus",
      align: "center",
      sortable: false
    },
    {
      text: "상신일자",
      value: "draftedTimeMillis",
      align: "center",
      sortable: true
    },
    {
      text: "결재요청일자",
      value: "approvalRequestTimeMillis",
      align: "center",
      sortable: true,
      isDefaultSort: true
    }
  ],
  "organ/receive": [
    {
      text: "문서번호",
      value: "docNum",
      align: "center",
      sortable: false
    },
    {
      text: "기안양식",
      value: "formName",
      align: "center",
      sortable: false
    },
    {
      text: "기안제목",
      value: "title",
      width: "100%",
      sortable: false
    },
    {
      text: "기안자",
      value: "draftUser",
      align: "center",
      sortable: false
    },
    {
      text: "처리자",
      value: "receiptUser",
      align: "center",
      sortable: false
    },
    {
      text: "진행상태",
      value: "receiveStatus",
      align: "center",
      sortable: false
    },
    {
      text: "상신일자",
      value: "draftedTimeMillis",
      align: "center",
      sortable: true
    },
    {
      text: "결재요청일자",
      value: "approvalRequestTimeMillis",
      align: "center",
      sortable: true,
      isDefaultSort: true
    }
  ]
};

export default B0X_HEADERS;
