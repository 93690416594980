<template>
  <div
    v-ripple
    class="cr-document-link"
    :class="dense ? 'pa-2 pl-3' : 'pa-3'"
    @click="$emit('click', id)"
  >
    <div style="width: 100%">
      <!-- 문서 제목 -->
      <div class="d-flex align-center text-body-2 font-weight-bold">
        <v-icon small :color="isDeleted ? 'grey' : 'black'" class="mr-1">
          {{
            isDeleted
              ? "mdi-file-document-remove-outline"
              : "mdi-file-document-outline"
          }}
        </v-icon>
        <span :class="isDeleted ? 'grey--text font-weight-normal' : ''">
          {{ title }}
        </span>
        <span v-if="isDeleted" class="text-caption grey--text ml-auto">
          삭제됨
        </span>
      </div>
      <!-- 기안자 & 기안부서 정보 -->
      <div
        v-if="draftUser && draftOrgan"
        class="d-flex align-center text-caption grey--text"
      >
        <span>{{ draftUser.name }}</span>
        <span class="mx-1">|</span>
        <span>{{ draftOrgan.name }}</span>
      </div>
      <!-- 상신일 -->
      <div v-if="draftedTimeMillis" class="text-caption grey--text">
        {{ `상신: ${draftedTime}` }}
      </div>
    </div>
    <v-btn
      v-if="close"
      icon
      small
      class="ml-auto"
      @click.stop="$emit('click:close', id)"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { getFullDate } from "@/commons/utils/moment";

export default {
  emits: ["click", "click:close"],
  props: {
    id: {
      required: true,
      description: "문서 아이디"
    },
    title: {
      required: true,
      description: "문서 제목"
    },
    draftUser: {
      description: "기안자 정보"
    },
    draftOrgan: {
      description: "기안부서 정보"
    },
    draftedTimeMillis: {
      description: "문서 상신일"
    },
    isDeleted: {
      type: Boolean,
      default: false,
      description: "문서 삭제됨 여부"
    },
    close: {
      type: Boolean,
      default: false,
      description: "닫기 버튼 활성화 여부"
    },
    dense: {
      type: Boolean,
      default: false,
      description: "좁은 여백"
    }
  },
  computed: {
    draftedTime() {
      return getFullDate(this.draftedTimeMillis);
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-document-link {
  display: flex;
  align-items: center;
  min-width: 400px;
  background: rgba(106, 143, 255, 0.03);
  border: thin solid rgba(0, 0, 0, 0.08);
  cursor: pointer;
}
</style>
