<template>
  <v-row no-gutters style="border-left: thin solid rgba(0, 0, 0, 0.08)">
    <!-- 상단 border -->
    <v-col
      ref="topBorder"
      cols="12"
      style="border-bottom: thin solid rgba(0, 0, 0, 0.08);"
    />
    <!-- 템플릿 필드셋 목록 -->
    <v-col
      cols="12"
      v-for="templateFrame in templateFrames"
      :key="templateFrame.id"
    >
      <!-- 편집모드일때 -->
      <TemplateField
        v-if="editable"
        v-model="templateBody[templateFrame.id]"
        :templateFrame="templateFrame"
        :editable="editable"
        ref="templateField"
      />
      <!-- 읽기전용 일때-->
      <TemplateField
        v-else
        :value="value[templateFrame.id]"
        :templateFrame="templateFrame"
        :editable="editable"
      />
    </v-col>
  </v-row>
</template>

<script>
import TemplateField from "@/approval/views/components/template/TemplateField";
export default {
  name: "TemplateMain",
  components: { TemplateField },
  props: {
    value: {
      type: Object,
      required: true,
      description: "템플릿 데이타 (v-model)"
    },
    templateFrames: {
      type: Array,
      required: true,
      description: "템플릿 구조 목록"
    },
    editable: {
      type: Boolean,
      default: false,
      description: "편집 모드"
    }
  },
  data: () => ({
    // 편집모드일때 templateBody 변경 감지를 위해
    templateBody: {}
  }),
  methods: {
    async validate() {
      let validAll = true;
      for (const fieldRef of this.$refs.templateField) {
        const valid = (await fieldRef?.validate?.()) || false;
        if (!valid) validAll = false;
      }
      return validAll;
    }
  },
  watch: {
    // {templateBody}의 변경을 감지 후 {v-model} 업데이트
    templateBody: {
      handler(newItem) {
        this.$emit("input", newItem);
      },
      deep: true
    }
  },
  mounted() {
    // 편집모드에서 초기값 존재시 반영 (재기안 등)
    this.templateBody = { ...this.value };
  }
};
</script>
