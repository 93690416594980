<template>
  <div class="cr-search-wrap">
    <v-form @submit.prevent="$emit('submit')">
      <v-row no-gutters>
        <!-- 상신 일자 조건 -->
        <v-col cols="6" class="my-2">
          <div class="d-flex align-center">
            <span class="cr-option-title">상신일</span>
            <DateRangeSelector
              show-icon
              :from-date="draftFromDate"
              :to-date="draftToDate"
              class="pr-8"
              style="width:100%;"
              @update:fromDate="val => $emit('update:draftFromDate', val)"
              @update:toDate="val => $emit('update:draftToDate', val)"
            />
          </div>
        </v-col>
        <!-- 기안양식 선택 -->
        <v-col cols="6" class="my-2">
          <div class="d-flex align-center">
            <span class="cr-option-title">기안양식</span>
            <v-select
              :value="formId"
              :items="formList"
              item-text="name"
              item-value="id"
              placeholder="전체"
              clearable
              hide-details
              outlined
              dense
              style="width:100%;"
              @input="val => this.$emit('update:formId', val)"
            />
          </div>
        </v-col>
        <!-- 키워드 검색 -->
        <v-col cols="6" class="my-2">
          <div class="d-flex align-center">
            <span class="cr-option-title">검색</span>
            <div class="d-flex align-center pr-8" style="width:100%;">
              <v-select
                v-model="computedKeywordFlag"
                :items="keywordFlagList"
                hide-details
                outlined
                dense
                class="mr-1"
                style="max-width: 130px"
              />
              <ApprovalAutocomplete
                v-if="computedKeywordFlag === 'DRAFT_USER_ID'"
                v-model="computedKeyword"
                :search-types="['MEMBER']"
                key="input_draft_user"
                placeholder="사용자 검색"
              />
              <ApprovalAutocomplete
                v-else-if="computedKeywordFlag === 'DRAFT_ORGAN_ID'"
                v-model="computedKeyword"
                :search-types="['ORGAN']"
                key="input_draft_organ"
                placeholder="부서 검색"
              />
              <v-text-field
                v-else
                v-model="computedKeyword"
                persistent-hint
                clearable
                outlined
                dense
                hide-details
                key="input_keyword"
                placeholder="입력해주세요"
              />
            </div>
          </div>
        </v-col>
        <v-col cols="6" class="my-2">
          <slot name="action">
            <!-- 우측 하단 영역 슬롯 개방 -->
            <!-- EX) <template v-slot:action></template> -->
          </slot>
        </v-col>
      </v-row>
      <!-- submit 발생용 -->
      <v-btn v-show="false" type="submit"></v-btn>
    </v-form>
  </div>
</template>

<script>
import API from "@/approval/api/approval.api";
import i18n from "@/_locales";
import { mapActions } from "vuex";
import DateRangeSelector from "@/approval/views/components/selector/DateRangeSelector";
import ApprovalAutocomplete from "@/approval/views/components/common/ApprovalAutocomplete";

export default {
  components: { ApprovalAutocomplete, DateRangeSelector },
  emits: [
    "update:draftFromDate",
    "update:draftToDate",
    "update:formId",
    "update:keywordFlag",
    "update:keyword",
    "update:draftUser",
    "update:draftOrgan",
    "update:docNum",
    "update:title",
    "submit"
  ],
  props: {
    headers: {
      description: "문서 목록 테이블 헤더"
    },
    draftFromDate: {
      description: "상신일자 (시작일)"
    },
    draftToDate: {
      description: "상신일자 (종료일)"
    },
    formId: {
      description: "기안양식 아이디"
    },
    keywordFlag: {
      description: "키워드 검색 유형"
    },
    keyword: {
      type: Object,
      description: "검색 키워드"
    }
  },
  data: () => ({
    formList: []
  }),
  computed: {
    keywordFlagList() {
      return this.headers?.some(({ value }) => value === "docNum")
        ? [
            { text: "기안자", value: "DRAFT_USER_ID" },
            { text: "기안부서", value: "DRAFT_ORGAN_ID" },
            { text: "제목", value: "TITLE" },
            { text: "문서번호", value: "DOC_NUM" }
          ]
        : [
            { text: "기안자", value: "DRAFT_USER_ID" },
            { text: "기안부서", value: "DRAFT_ORGAN_ID" },
            { text: "제목", value: "TITLE" }
          ];
    },
    computedKeywordFlag: {
      get() {
        return this.keywordFlag;
      },
      set(val) {
        this.computedKeyword = undefined;
        this.$emit("update:keywordFlag", val);
      }
    },
    // 키워드는 오브젝트 형태로 전달되기 때문에 필요한 값 직접 추출
    computedKeyword: {
      get() {
        switch (this.computedKeywordFlag) {
          case "DOC_NUM":
            return this.keyword?.docNum ?? undefined;
          case "TITLE":
            return this.keyword?.title ?? undefined;
          case "DRAFT_USER_ID":
          case "DRAFT_ORGAN_ID":
          default:
            return this.keyword;
        }
      },
      set(val) {
        let keyword;
        switch (this.computedKeywordFlag) {
          case "DOC_NUM":
            keyword = val ? { docNum: val } : undefined;
            break;
          case "TITLE":
            keyword = val ? { title: val } : undefined;
            break;
          case "DRAFT_USER_ID":
            keyword = val?.userId
              ? {
                  userId: val?.userId,
                  email: val?.email,
                  name: val?.name
                }
              : undefined;
            break;
          case "DRAFT_ORGAN_ID":
            keyword = val?.organId
              ? {
                  organId: val?.organId,
                  email: val?.email,
                  name: val?.name
                }
              : undefined;
            break;
          default:
            keyword = undefined;
        }
        this.$emit("update:keyword", keyword);
      }
    }
  },
  methods: {
    ...mapActions("snackbar", ["openSnackbar"]),
    // 회사 내 가용가능한 모든 기안양식 불러옴
    async fetchAllFormList() {
      const { status, data } = await API.getAllFormList();
      if (status !== 200) {
        await this.openSnackbar({
          message: i18n.t("기안양식을 불러오는데 실패했습니다."),
          type: "ERROR"
        });
        return;
      }
      this.formList = [...data];
    }
  },
  async mounted() {
    if (!this.computedKeywordFlag) this.computedKeywordFlag = "TITLE";
    await this.fetchAllFormList();
  }
};
</script>

<style lang="scss" scoped>
.cr-search-wrap {
  .cr-option-title {
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.6);
    min-width: 80px;
  }
}
</style>
