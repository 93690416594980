<template>
  <div style="position: relative">
    <v-progress-linear v-if="loading" absolute indeterminate top />
    <!-- header -->
    <div class="d-flex align-center">
      <span class="cr-section-title">결재할 문서</span>
      <span class="cr-count ml-2">{{ totalElements }}</span>
      <v-spacer></v-spacer>
      <PagingHandle
        :page="page"
        :total-pages="totalPage"
        @loadData="loadList"
      />
    </div>
    <v-divider class="mt-2"></v-divider>
    <!-- body -->
    <v-data-table
      hide-default-footer
      fixed-header
      disable-sort
      :headers="headers"
      :items="items"
      :sort="sort"
      :dir="dir"
      :items-per-page="-1"
      :mobile-breakpoint="-1"
      class="cr-document-table"
      @click:row="routeViewPage"
    >
      <!-- 기안양식 -->
      <template v-slot:item.formName="{ item }">
        <FormCell v-bind="item" disable-context-menu />
      </template>
      <!-- 제목 -->
      <template v-slot:item.title="{ value }">
        <TitleCell :title="value" />
      </template>
      <!-- 기안자 -->
      <template v-slot:item.draftUser="{ value }">
        <ApproverCell
          disable-context-menu
          :user-id="value.id"
          :user-email="value.email"
          :user-name="value.name"
          @search:draftUser="val => $emit('search:draftUser', val)"
          @search:draftOrgan="val => $emit('search:draftOrgan', val)"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PagingHandle from "@/home/views/components/widget/commons/PagingHandle";
import { getFullDate } from "@/commons/utils/moment";
import FormCell from "@/approval/views/components/common/cell/FormCell";
import TitleCell from "@/approval/views/components/common/cell/TitleCell";
import ApproverCell from "@/approval/views/components/common/cell/ApproverCell";
export default {
  components: { ApproverCell, TitleCell, FormCell, PagingHandle },
  data: () => ({
    headers: [
      {
        text: "결재유형",
        value: "approvalType",
        align: "center"
      },
      {
        text: "기안양식",
        value: "formName",
        align: "center",
        cellClass: "cr-cell-form"
      },
      {
        text: "기안제목",
        value: "title",
        width: "100%",
        cellClass: "cr-cell-title"
      },
      {
        text: "기안자",
        value: "draftUser",
        align: "center"
      },
      {
        text: "상신일자",
        value: "draftedTimeMillis",
        align: "center"
      },
      {
        text: "결재요청일자",
        value: "approvalRequestTimeMillis",
        align: "center"
      }
    ],
    sort: "approvalRequestTimeMillis",
    dir: "DESC"
  }),
  computed: {
    ...mapState("approvalDashboard", ["toBeApproveList"]),
    loading() {
      return this.toBeApproveList?.loading;
    },
    items() {
      return (
        this.toBeApproveList?.documents?.map(document => ({
          ...document,
          draftedTimeMillis: getFullDate(document?.draftedTimeMillis),
          approvalRequestTimeMillis: getFullDate(
            document?.approvalRequestTimeMillis
          ),
          approvalType: this.getApprovalLineTypeName(document?.approvalLineType)
        })) ?? []
      );
    },
    size() {
      return this.toBeApproveList?.size ?? 5;
    },
    page() {
      return this.toBeApproveList?.page ?? 0;
    },
    totalElements() {
      return this.toBeApproveList?.totalElements ?? 0;
    },
    totalPage() {
      const { totalElements, size } = this.toBeApproveList;
      return Math.ceil(totalElements / size);
    }
  },
  methods: {
    ...mapActions("approvalDashboard", ["fetchToBeApproveList"]),
    loadList(page) {
      this.fetchToBeApproveList({ page, size: this.size });
    },
    routeViewPage(item) {
      let boxKey;
      switch (item?.approvalLineType) {
        case "APPROVAL":
        case "HELP":
          boxKey = "ab";
          break;
        case "HELP_ORGAN":
          boxKey = "oh";
          break;
        case "RECEIVE":
          boxKey = "rb";
          break;
        case "RECEIVE_ORGAN":
          boxKey = "or";
          break;
        default:
          return;
      }
      window.open(`#/approval/doc/list/${boxKey}/${item.id}`);
    },
    getApprovalLineTypeName(approvalLineType) {
      switch (approvalLineType) {
        case "APPROVAL":
          return "결재";
        case "HELP":
          return "협조";
        case "HELP_ORGAN":
          return "부서협조";
        case "RECEIVE":
          return "수신";
        case "RECEIVE_ORGAN":
          return "부서수신";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-count {
  height: 20px;
  min-width: 20px;
  line-height: 20px;
  background-color: var(--v-primary-base);
  border-radius: 5px;
  padding: 0 3px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  white-space: nowrap;
}
.cr-document-table::v-deep {
  > .v-data-table__wrapper {
    > table {
      // 헤더 및 아이템 줄바꿈 방지
      > thead > tr,
      > tbody > tr {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
      }
      > tbody {
        > tr {
          cursor: pointer;
          &:hover {
            background: rgb(210 210 210 / 15%) !important;
          }
          td {
            &.cr-cell-title {
              max-width: 0;
            }
            &.cr-cell-form {
              width: 150px;
              min-width: 150px;
              max-width: 150px;
            }
          }
        }
      }
    }
  }
}
</style>
