<template>
  <v-menu :disabled="disableContextMenu" offset-y>
    <template v-slot:activator="menu">
      <v-tooltip v-if="!isEmpty" top color="black">
        <template v-slot:activator="tooltip">
          <v-chip
            small
            label
            color="grey lighten-3"
            v-bind="{ ...menu.attrs, ...tooltip.attrs }"
            v-on="{ ...menu.on, ...tooltip.on }"
          >
            {{ isUser ? userName : organName }}
          </v-chip>
        </template>
        {{ displayText }}
      </v-tooltip>
      <span v-else>-</span>
    </template>
    <!-- 이름 클릭시 메뉴 -->
    <v-list outlined dense tile>
      <v-hover v-slot="{ hover }" v-for="(item, index) in items" :key="index">
        <v-list-item
          :disabled="item.disabled()"
          :class="hover ? 'cr-list-item--hover' : ''"
          @click="item.click"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-hover>
    </v-list>
  </v-menu>
</template>
<script>
import { mapActions } from "vuex";

export default {
  emits: ["search:draftUser", "search:draftOrgan"],
  props: {
    disableContextMenu: {
      type: Boolean,
      default: false,
      description: "컨텍스트 메뉴 비활성화"
    },
    userId: { description: "사용자 아이디" },
    userEmail: { description: "사용자 이메일" },
    userName: { description: "시영지 이름" },
    organId: { description: "조직 아이디" },
    organName: { description: "조직명" }
  },
  data() {
    return {
      items: [
        {
          // 기안자로 검색
          title: "기안자로 검색",
          disabled: () => !this.isUser,
          click: () =>
            this.$emit("search:draftUser", {
              userId: this.userId,
              userEmail: this.userEmail,
              userName: this.userName
            })
        },
        {
          // 기안부서로 검색
          title: "기안부서로 검색",
          disabled: () => !this.isOrgan,
          click: () =>
            this.$emit("search:draftOrgan", {
              organId: this.organId,
              organName: this.organName
            })
        },
        {
          // 메일 쓰기
          title: "메일 작성",
          disabled: () => !this.isUser,
          click: () => {
            this.routeMailWrite({
              openPopup: true,
              to: [this.fullEmail]
            });
          }
        }
      ]
    };
  },
  computed: {
    isEmpty() {
      return !(!!this.userId || !!this.organId);
    },
    isUser() {
      return !!this.userId;
    },
    isOrgan() {
      return !this.isUser;
    },
    displayText() {
      return `${this.isUser ? "[사용자]" : "[부서]"} ${
        this.isUser ? this.userEmail : this.organName
      }`;
    },
    // 이름 정보 존재시 "이름"<이메일> 포맷팅
    fullEmail() {
      return `"${this.userName}" <${this.userEmail}>`;
    }
  },
  methods: {
    ...mapActions("mailRoute", ["routeMailWrite"])
  }
};
</script>

<style lang="scss" scoped>
.cr-list-item--hover {
  background-color: #c5e0ff71;
  cursor: pointer;
}
</style>
